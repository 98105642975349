import {useTranslation} from "react-i18next";
import React, {useContext, useState} from "react";
import lodash from 'lodash';
import {Feedback} from "./Feedback";
import {ClaimDetailContext} from "../../context/ClaimDetailContext";
import {Button, Image, notification} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {claimRepository} from "../../repositories/ClaimRepository";
import {Provider} from "../../components/Provider";
import emptyImage from '../../resources/images/empty-2.png';
import {AxiosError} from "axios";
import {currencyFormat} from "../../utils/util";
import {Link} from "react-router-dom";

export const Info = () => {
    const {claim, provider, customerCode, onArchiveSuccess} = useContext(ClaimDetailContext)
    const {t} = useTranslation()
    const [loading, setLoading] = useState<boolean>(false)
    const [previewVisible, setPreviewVisible] = useState(false)
    const isShowBtnArchive = ["REJECT", "REFUND"].indexOf(claim.state) >= 0 && !claim.archived

    const handleArchive = () => {
        setLoading(true)
        claimRepository.archive(provider.code, customerCode, claim.code)
            .then(() => {
                notification.success({
                    message: t('tickets.archive_success')
                })

                onArchiveSuccess()
            })
            .catch((error: AxiosError) => {
                if (lodash.get(error.response, 'status') === 400) {
                    notification.error({
                        message: t(`tickets.archive_error_${lodash.get(error.response, 'data.title')}`)
                    })
                }
                else {
                    notification.error({
                        message: t('tickets.archive_failed')
                    })
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <div className={'ticket-infos-box box-shadow'}>
            <div className="white-box">
                <div className={'mgbt20 dpl-flex claim-name-heading'}>
                    <span className={'claim-name-status'}>
                        <span className="dpl-flex">
                            <span className={'txt-size-h6 robotomedium txt-color-black fsz-20 txt-capitalize'}>{t('tickets.ticket-code')} #{lodash.get(claim, 'code')}</span>
                            <span className={'mgl5 border-radius25 txt-color-white align-self-center align-items-center pdl12 pdr12 h-22 line-h-22'} style={{backgroundColor: lodash.get(claim, 'publicState.color')}}>{lodash.get(claim, 'publicState.name')}{claim.archived && ` (${t('tickets.archived')})`}</span>
                        </span>
                    </span>

                    {isShowBtnArchive && (
                        <Button
                            loading={loading}
                            disabled={loading}
                            onClick={() => handleArchive()}
                            className={'claim-btn-archive'}
                            icon={<CloseOutlined />}
                        >{t('tickets.close')}</Button>
                    )}
                </div>

                <div className={'ticket-infos'}>
                    <div className={'info-group'}>
                        <div className={'dpl-flex align-items-stretch claim-detail-order-info-box'}>
                            <div className="order-image-box">
                                <Image className={'order-image'} src={lodash.get(claim, 'thumbnail') || 'error'} alt={''} fallback={emptyImage} />
                            </div>
                            <div className={'align-self-center'}>
                                <div className="claim-detail-order-info-box claim-detail-order-info-box__codes">
                                    <div className={'txt-color-gray4'}>{t('tickets.order')}</div>
                                    <Link target={'_blank'} to={`/orders/${claim.relatedOrder}`} className={'robotomedium fsz-16 txt-color-primary'}>{lodash.get(claim, 'relatedOrder')}</Link>
                                    {claim.relatedProduct && (
                                        <span className={'txt-size-h8 robotoregular txt-color-black'}>
                                            <span className={'mgr5'}>
                                                {t('ticket-create.default_name_with_product')}
                                            </span>{lodash.get(claim,'relatedProduct')}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'info-group align-self-start'}>
                        <div className={'info-group-label'}>{t('tickets.reason')}: </div>
                        <div className={'info-group-value fsz-14'}>
                            <div className={'robotomedium txt-color-black'}>{lodash.get(claim, 'reason.name')}</div>
                            {claim.reason.code === 'not_received' && (
                                <div className={'fsz-14 robotoregular txt-color-black'}>{t('tickets.missing_quantity')}: {parseInt(claim.reasonData.notReceived) === 0 ? t('tickets.missing_quantity_all') :claim.reasonData.notReceived}</div>
                            )}
                        </div>
                    </div>
                    <div className={'info-group align-self-start'}>
                        <div className={'info-group-label'}>{t('tickets.resolve-methods')}: </div>
                        <div className={'info-group-value fsz-14 robotomedium txt-color-black'}>{lodash.get(claim, 'solution.name')}</div>
                    </div>
                    <div className={'info-group align-self-start'}>
                        <div className={'info-group-label'}>{t('tickets.moneyOffer')}: </div>
                        <div className={'info-group-value fsz-14 robotomedium txt-color-black'}>{lodash.get(claim, 'suggest') ? currencyFormat(claim.suggest) : '' || '--'}</div>
                    </div>
                    <div className={'info-group align-self-start'}>
                        <div className={'info-group-label'}>{t('tickets.refund_amount')}: </div>
                        <div className={'info-group-value fsz-14 robotomedium txt-color-black'}>{lodash.get(claim, 'estimatedRefundValue') ? currencyFormat(claim.estimatedRefundValue) : '' || '--'}</div>
                    </div>
                    <div className={'info-group align-self-start'}>
                        <div className={'info-group-label'}>{t('tickets.provider-account')}: </div>
                        <div className={'info-group-value fsz-14 claim-detail-provider-block'}>
                            <Provider
                                provider={provider}
                                customerUsername={lodash.get(claim, 'owner.username')}
                            />
                        </div>
                    </div>
                </div>

                <div className={'divider mgbt15'} />

                <div className="ticket-description">
                    <div className={'mgbt10 txt-color-gray4'}>
                        {t('tickets.description')}: <span className={'txt-color-black'}>{lodash.get(claim, 'description')}</span>
                    </div>
                    <div className={'txt-color-gray4'}>{t('tickets.attachment_image')}:</div>
                    <div className={'claim-attachment-list'}>
                        {Array.isArray(claim.attachments) && claim.attachments.map(item => {
                            if (item.type.toString().startsWith('image')) {
                                return (
                                    <div className={'mgr8 mgbt8'}>
                                        <Image
                                            fallback={emptyImage}
                                            className={'image-attachment attachment-item'}
                                            src={item.presignedUrl || 'error'}
                                            alt={claim.thumbnail}
                                            preview={{
                                                visible: false,
                                                mask: (
                                                    <div className={'image-attachment-mask'}>
                                                        <i className="fa-regular fa-eye" />
                                                    </div>
                                                )
                                            }}
                                            onClick={() => setPreviewVisible(true)}
                                        />
                                    </div>
                                )
                            }
                            else {
                               return (
                                   <a className={'attachment-item attachment-item--file mgr8 mgbt8'} href={item.presignedUrl} target={"_blank"} title={item.name}>
                                       <i className="fa-regular fa-file-arrow-down" /> {item.name}
                                   </a>
                               )
                            }
                        })}
                    </div>
                </div>

                <div className={'divider mgt5 mgbt10'} />

                <div style={{ display: 'none' }}>
                    <Image.PreviewGroup
                        preview={{
                            visible: previewVisible,
                            onVisibleChange: vis => setPreviewVisible(vis)
                        }}>
                        {Array.isArray(claim.attachments) && claim.attachments.filter(item => item.type.startsWith('image')).map(item => (
                            <Image src={item.presignedUrl} />
                        ))}
                    </Image.PreviewGroup>
                </div>

                <Feedback />
            </div>
        </div>
    )
}
