import React from "react";


function Service () {

    return(
        <>
            Service
        </>
    )
}

export default Service;
