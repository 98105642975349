import {
    Avatar,
    Button,
    Card,
    Checkbox,
    Col, DatePicker,
    Divider,
    Image, Input, List,
    Pagination,
    Radio,
    Row,
    Select,
    Skeleton,
    Switch,
    Table
} from 'antd';
import {useTranslation} from "react-i18next";
import {SearchOutlined, ReloadOutlined} from '@ant-design/icons';
import './styles.scss';
import MainLayout from "../../components/Layout";
import React, {useEffect, useState} from "react";
import empty from '../../resources/images/empty.png';
import {supplierRepository, SupplierRepository} from "../../repositories/SupplierRepository";
import M24ErrorUtils from "../../utils/M24ErrorUtils";
import M24Notification from "../../utils/M24Notification";
import lodash from "lodash";
import {formatDate, formatDateTime} from "../../core/helpers/date-time";
import {handleChangeDate, linkOrder, handleInputChange, handleInputChangeMany} from "../../utils/StringUtils";
import {formatMoneyBySuffix} from "../../core/helpers/string";
import M24Pagination from "../../components/Pagination/M24Pagination";
import moment from "moment";
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {orderRepository} from "../../repositories/OrderRepository";
import {categoryRepository} from "../../repositories/CategoryRepository";
import {FileTextOutlined} from '@ant-design/icons';
import Item from "../../components/Orders/Item";
import * as queryString from "querystring";
import OrderFilter from "../../components/Orders/OrderFilter";

function Orders() {
    const [showProgressBar, setShowProgressBar] = useState<boolean>(true);
    const {t} = useTranslation();
    let [searchParams, setSearchParams] = useSearchParams();
    const [accounts, setAccounts] = useState<any[]>([]);
    const [filter, setFilter] = useState<any>({});
    const [pagination, setPagination] = useState<any>();
    const [orders, setOrders] = useState<any[]>([]);
    const [statuses, setStatuses] = useState<any[]>([]);
    const [marketplaces, setMarketplaces] = useState<any[]>([]);
    const [providers,setProviders] = useState<any>([]);
    const [services, setServices] = useState<any>({});

    useEffect(() => {
        let statuses = searchParams.get('statuses');
        let marketplaces = searchParams.get('marketplaces');
        let providerUsername = searchParams.get('providerUsername');
        let timestampFrom = searchParams.get('timestampFrom');
        let timestampTo = searchParams.get('timestampTo');
        let code = searchParams.get('code');
        let provider = searchParams.get('provider');
        let page = searchParams.get('page');
        let size = searchParams.get('size');
        let temp: any = {};
        if (statuses)
            temp.statuses = statuses;
        if (marketplaces)
            temp.marketplaces = marketplaces;
        if (providerUsername)
            temp.providerUsername = providerUsername;
        if (timestampFrom)
            temp.timestampFrom = timestampFrom;
        if (timestampTo)
            temp.timestampTo = timestampTo;
        if (page)
            temp.page = parseInt(page);
        if (size)
            temp.size = parseInt(size);
        if(code)
        temp.code= code;
        if(provider)
            temp.provider= provider;
        setFilter(temp);
        getStatuses();
        getMarketplaces();
        getAccounts();
        getProviders();
        getData(temp);
    }, []);
    const getData = (filter?: any) => {
        setShowProgressBar(true);
        setSearchParams(filter);
        orderRepository.getOrders(filter).then(response => {
            let currentPage = lodash.get(response, 'headers.x-page-number');
            let total = lodash.get(response, 'headers.x-total-count');
            setOrders(response.data);
            setPagination({
                current: parseInt(currentPage) + 1,
                hideOnSinglePage: true,
                total: parseInt(total),
                pageSize: parseInt(lodash.get(response, 'headers.x-page-size'))
            })
        }).catch(err => {
            // M24ErrorUtils.showError(t, err);
        }).finally(() => setShowProgressBar(false));
    }
    const getStatuses = () => {
        categoryRepository.getStatuses().then(res => {
            setStatuses(res);
        }).catch(err => {

        })
    }
    const getMarketplaces = () => {
        categoryRepository.getMarketPlaces().then(res => {
            setMarketplaces(res);
        }).catch(err => {

        });
    }
    const getAccounts = () => {
        supplierRepository.getListAccount().then(res => {
            let temp = res.filter((x: any) => x.status === 'CONNECTED');
            if (temp && temp.length > 0) {
                // let item = temp.find((x: any) => x.defaultConnection);
                // if (!item)
                //     item = temp[0];
                setAccounts(temp);
            }

        }).catch(err => {
            M24ErrorUtils.showError(t, err);
        });
    }
    const getProviders = () => {
        categoryRepository.getProvider().then(response => {
            setProviders(response);
        }).catch(err=> {

        })
    }

    const onPageChange = (filter: any) => {
        setFilter(filter);
        let temp = lodash.cloneDeep(filter);
        if (filter && filter.page && filter.page > 0) {
            temp.page = temp.page - 1;
        }
        getData(temp);
    }

    const onChangeFilterMany = (isCheck: boolean, code: string) => {
        let temp = handleInputChangeMany('types', code, filter);
        setFilter(temp);
    }
    const onChange = (key: string, value: any) => {
        let temp = handleInputChange(key, value, filter);
        setFilter(temp);
    }
    const onChangeDate = (date: any, key: string) => {
        let temp = handleChangeDate(key, date, filter);
        setFilter(temp);
    }
    const resetFilter = () => {
        setFilter({});
        getData({});
    }


    return <MainLayout title={t('menu.orders')} showProgressBar={showProgressBar}>
        {/*<Card className={'border-card-header mgbt10'}>*/}
        {/*</Card>*/}
        {/*<Card className={'border-card-header mgt20'}>*/}
        {/*    <Row className={'dpl-flex align-items-center justify-content-between'}>*/}
        {/*        <span className={'txt-size-h5 txt-color-orange bold'}>{t('transactions.list')}</span>*/}
        {/*        /!*<Button icon={<PlusOutlined style={{fontSize: 15}}/>} className={''}*!/*/}
        {/*        /!*        type={'primary'}>{t('transactions.btExcel')}</Button>*!/*/}
        {/*    </Row>*/}
        {/*</Card>*/}
        <OrderFilter setFilter={setFilter} getData={getData} providers={providers} accounts={accounts} filter={filter} statuses={statuses} marketplaces={marketplaces}/>
        <Row justify={'start'} className={''}>
            {orders && orders.length > 0 &&
            <M24Pagination className={'mgt12 mgbt12'} onPageChange={onPageChange} filter={filter} pagination={pagination}/>}
        </Row>
        <div>
            <div className={'mgt5'}>
                <Skeleton active={true} loading={showProgressBar}>
                    <List
                        dataSource={orders}
                        renderItem={item => <Item statuses={statuses} item={item} />}
                    />
                </Skeleton>
            </div>
        </div>
    </MainLayout>
}

export default Orders;
