import React, {useEffect, useState} from 'react';
import MainLayout from "../../components/Layout";
import {useTranslation} from "react-i18next";
import './styles.scss';

// AntComponent
import {Button, Card, Checkbox, Col, Divider, notification, Row, Steps} from 'antd';
import ShopList from "../../components/ShoppingCart/ShopList";
import lodash, {set} from "lodash";
import {cartRepository} from "../../repositories/CartRepository";
import Loading from "../../components/Loading";
import useWindowDimensions from "../../hooks";
import M24ErrorUtils from "../../utils/M24ErrorUtils";
import M24Notification from "../../utils/M24Notification";
import ProductItem from "../../components/ShoppingCart/ProductItem";
import Step2 from "../../components/ShoppingCart/Step2";
// import Step2 from "../../components/ShoppingCart/Step2";
import {supplierRepository} from "../../repositories/SupplierRepository";
import {ArrowLeftOutlined} from '@ant-design/icons';
import Step3 from "../../components/ShoppingCart/Step3";

const {Step} = Steps;


function ShoppingCart() {
    const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDeletingSKU, setDeletingSKU] = useState<boolean>(false);
    const [isDeletingMerchant, setDeletingMerchant] = useState<boolean>(false);
    const {t} = useTranslation();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [cartItem, setCartItem] = useState<any>([]);
    const [filter, setFilter] = useState<any>({})
    const [pagination, setPagination] = useState<any>({});
    const {width} = useWindowDimensions();
    const [accountBalance, setAccountBalance] = useState<any>([]);
    const [merchantSelected, setMerchantSelected] = useState<any>({
        merchant:{},
        products:[],
    });
    useEffect(() => {
        getData(filter);
        getAccountBalance();
    }, []);

    const getData = (filter?: any) => {
        setShowProgressBar(true);
        setIsLoading(true);
        cartRepository.getProductCart(filter).then(res => {
            let currentPage = lodash.get(res, 'headers.x-page-number');
            let total = lodash.get(res, 'headers.x-total-count');
            setCartItem(res.data);
            setPagination({
                current: parseInt(currentPage) + 1,
                hideOnSinglePage: true,
                total: parseInt(total),
                pageSize: parseInt(lodash.get(res, 'headers.x-page-size'))
            })
        }).catch(err => {
        }).finally(() => {
            setShowProgressBar(false);
            setIsLoading(false);
        });
    }

    const getAccountBalance=()=>{
        supplierRepository.getAccountBalance().then(res=>{
            setAccountBalance(res);
        }).catch(err=>{

        });
    }
    const onPageChange = (filter: any) => {
        setFilter(filter);
        let temp = lodash.cloneDeep(filter);
        if (filter && filter.page && filter.page > 0) {
            temp.page = temp.page - 1;
        }
        window.scroll(0, 0);
        getData(temp);
    }

    const handleClickOrderButton = () => {
        setCurrentStep(1);

    }

    const deleteSku = (merchantId: any, productId: any, skuId: any, callback: ()=>void) => {
        setDeletingSKU(true);
        cartRepository.deleteSku(skuId).then(res => {
            M24Notification.messageSuccess(t('message.deleteSKUSuccess'));
            if(callback)
                callback();
            let temp = lodash.cloneDeep(cartItem);
            let isLastSKU = false;
            temp.map((x: any) => {
                if (x.id === merchantId) {
                    let product = x.products.find((xx: any) => xx.id == productId);
                    if (product && product.skus.length === 1) {
                        isLastSKU = true;
                    }
                }
            });
            if (isLastSKU)
                getData({...filter, page: 0});
            else getData(filter);
            if(merchantSelected&&merchantSelected.merchant.id){
                let temp = lodash.cloneDeep(merchantSelected);
                if(temp.merchant.id===merchantId){
                    temp.products.map((x: any)=>{
                        if(x.id===productId){
                           x.skus = x.skus.filter((sku: any)=> sku.id!==skuId);
                        }
                    });
                    setMerchantSelected(temp);
                }
            }

        }).catch(err => {
            M24ErrorUtils.showError(t, err);
        }).finally(() => setDeletingSKU(false));
    }
    const deleteMerchant = (merchantId: any, callback: ()=>void) => {
        setDeletingMerchant(true);
        cartRepository.deleteMerchant(merchantId).then(res => {
            M24Notification.messageSuccess(t('message.deleteMerchantSuccess'));
            getData({...filter, page: 0});
            if(merchantSelected&&merchantSelected.merchant.id){
                let temp = lodash.cloneDeep(merchantSelected);
                if(temp.merchant.id===merchantId){
                    setMerchantSelected({
                        merchant:{},
                        products:[],
                    });
                }
            }
            if(callback) callback();
        }).catch(err => {
            M24ErrorUtils.showError(t, err);
        }).finally(() => setDeletingMerchant(false));
    }
    const handleUpdateQuantitySku = (merchantId: any, quantity: number, productId: any, skuId: any, callback: ()=>void) => {

        cartRepository.updateQuantitySku(skuId, quantity).then(res => {
            M24Notification.messageSuccess(t('message.updateQuantitySuccess'));
            getData({...filter, page: filter.page>0?filter.page-1:0});
            if(merchantSelected&&merchantSelected.merchant.id){
                let temp = lodash.cloneDeep(merchantSelected);
                if(temp.merchant.id===merchantId){
                    temp.products.map((x: any)=>{
                        if(x.id===productId){
                            x.skus.map((sku: any)=>{
                                if(sku.id===skuId){
                                    sku.quantity=quantity;
                                }
                            });
                        }
                    });
                    setMerchantSelected(temp);
                }
            }

            if(callback) callback();
        }).catch(err => {
            M24ErrorUtils.showError(t, err);
        }).finally(() => setDeletingMerchant(false));
    }

    const handleUpdateEmployeeNote=(skuId: any, value:string, callback: ()=> void)=>{
        cartRepository.updateSku(skuId, {employeeNote: value}).then(res=>{
            getData({...filter, page: filter.page>0?filter.page-1:0})
        }).catch(err=>{
            M24ErrorUtils.showError(t,err);
        }).finally(()=>{
            if(callback) callback();
        });
    }
    const handleUpdatePersonalNote=(skuId: any, value:string, callback: ()=> void)=>{
        cartRepository.updateSku(skuId, {personalNote: value}).then(res=>{
            getData({...filter, page: filter.page>0?filter.page-1:0})
        }).catch(err=>{
            M24ErrorUtils.showError(t,err);
        }).finally(()=>{
            if(callback) callback();
        });
    }

    const selectMerchant=(merchant: any, isSelect: boolean)=>{
        if(!isSelect)
            setMerchantSelected({
                merchant:{},
                products:[],
            });
        else{

            let item = cartItem.find((x: any)=>x.id===merchant.id);
            if(item&&item.products&&item.products.length>0){
                // let skusId: string[]=[];
                // item.products.map((x: any)=>{
                //         x.skus.map((xx:any)=> {
                //             skusId.push(xx);
                //         });
                // })
                setMerchantSelected({
                    merchant,
                    products:item.products,
                });
            }

        }
    }
    const selectSku=(merchant: any, product: any, sku: any, isSelect: boolean)=>{
        if(merchant.id!==merchantSelected.merchant.id){
            setMerchantSelected({
                merchant,
                products:[{
                    id: product.id,
                    pricePolicies: product.pricePolicies,
                    skus:[sku]
                }]
            });
        }else{
            let temp = lodash.cloneDeep(merchantSelected);
            if(!isSelect){
                temp.products.map((x: any)=>{
                    if(x.id===product.id){
                        let skus = x.skus.filter((xx:any)=> xx.id!==sku.id);
                        x.skus=skus;
                    }
                });
                let products = temp.products.filter((x: any)=> x.skus.length>0);
                if(products&&products.length>0){
                    temp.products= products;
                    setMerchantSelected(temp);
                }else{
                    setMerchantSelected({
                        merchant:{},
                        products:[]
                    });
                }
            }
            else{
                let isExist = temp.products.find((x: any)=> x.id===product.id);
                if(!isExist){
                    temp.products.push({
                        id: product.id,
                        pricePolicies: product.pricePolicies,
                        skus:[sku]
                    });
                }else{
                    temp.products.map((x: any)=>{
                        if(x.id===product.id){
                            x.skus.push(sku);
                        }
                    });
                }
                setMerchantSelected(temp);
            }
        }

    }

    const [orderSuccessView, setOrderSuccessView] = useState<any>({})
    const handleCreateOrder = (body:any, callback: any) => {
        // setIsLoading(true);
        cartRepository.createOrder(body).then(response => {
            setOrderSuccessView(response.data);
            // console.log(response.data)
            setStep(2);

        }).catch(err => {
            // console.log(err);
            M24ErrorUtils.showError(t,err);
        }).finally(()=>{
            if(callback) callback();
        })
    }

    const initSteps = () => {
        if (currentStep === 0) {
            return <ShopList
                isDeletingMerchant={isDeletingMerchant}
                isDeletingSKU={isDeletingSKU}
                handleDeleteSku={deleteSku}
                handleDeleteMerchant={deleteMerchant}
                handleUpdateQuantitySku={handleUpdateQuantitySku}
                onPageChange={onPageChange}
                pagination={pagination}
                filter={filter}
                handleClickOrderBtn={handleClickOrderButton}
                cartItem={cartItem}
                getCartData={getData}
                handleUpdateEmployeeNote={handleUpdateEmployeeNote}
                handleUpdatePersonalNote={handleUpdatePersonalNote}
                selectMerchant={selectMerchant}
                selectSku={selectSku}
                merchantSelected={merchantSelected}
            />;
        }
        if (currentStep === 1) {
            return (
                <Step2 setStep={setStep}
                       setMerchant={setMerchantSelected}
                       merchant={merchantSelected}
                       accountBalance={accountBalance}
                       handleCreateOrder={handleCreateOrder}
                />
            );
        }
        if (currentStep === 2) {
            return (
                <Step3
                    orderSuccessView={orderSuccessView}
                />
            );
        }

    }
    const setStep=(value:number)=>{
        setCurrentStep(value);
        if(value===0){
            setMerchantSelected({
                merchant:{},
                products:[],
            })
            // notification.destroy();
        }
    }
    return (
        <MainLayout
            title={t('menu.shopping-cart')}
            showProgressBar={showProgressBar}
        >
            <div className={'steps-header'}>
                <Card className={'step-header-content mgt12'} bodyStyle={{paddingTop: 10, paddingBottom: 10, width: width - 80}}>
                    <Row className={'pdbt5 pdt5 dpl-flex justify-content-between'}>
                        <Col lg={10} sm={20}>
                            <Steps
                                current={currentStep}
                                className="shopping-cart-steps"
                                size="small"
                            >
                                <Step title={t('shopping-cart.products')}/>
                                <Step title={t('shopping-cart.services')}/>
                                <Step title={t('shopping-cart.payments')}/>
                            </Steps>
                        </Col>
                        <Col>
                            {currentStep>0&& currentStep <2 &&<Button onClick={()=> {
                                setStep(currentStep-1);
                            }} icon={<ArrowLeftOutlined />}>{t('shopping-cart.backStep')}</Button>}
                        </Col>
                    </Row>
                    {currentStep === 0 &&
                    <Row align={'middle'} className={'pdt15 mgt10 border-top-1x bd-color-gray'}>
                        <Col span={12}>
                            <span className={'txt-color-black txt-center txt-size-h7 robotomedium'}>{t('shopping-cart.products')}</span>
                        </Col>
                        <Col span={5} className='txt-center'>
                            <span  className={'txt-color-black txt-center txt-size-h7 robotomedium'}>{t('shopping-cart.quantity')}</span></Col>
                        <Col span={2} className={'txt-right txt-size-h7 robotomedium'}>
                            <span className={'txt-color-black txt-center txt-size-h7 robotomedium'}>{t('shopping-cart.unitPrice')}</span></Col>
                        <Col span={2} className='txt-right'>
                            <span className={'txt-color-black txt-center txt-size-h7 robotomedium'}>{t('shopping-cart.totalPrice')}</span></Col>
                        <Col span={3} className='txt-right'> <span
                            className={'txt-color-black txt-center txt-size-h7 robotomedium'}>{t('shopping-cart.action')}</span></Col>
                    </Row>
                    }
                </Card>
            </div>
            {currentStep === 0 ?
                <div style={{paddingTop: 114}}>
                    {showProgressBar && cartItem.length === 0 ?
                        <Loading/>
                        :
                        initSteps()}
                </div> :
                <div style={{paddingTop: 74}}>
                    {initSteps()}
                </div>

            }

        </MainLayout>
    );
}

export default ShoppingCart;
