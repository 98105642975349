import React, {useEffect, useRef, useState} from "react";
import {Avatar, Button, Checkbox, Col, Divider, Input, InputNumber, Row, Spin} from "antd";
import lodash from "lodash";
import {formatMoneyByUnit} from "../../../core/helpers/string";
import {useTranslation} from "react-i18next";
import ConfirmModal from "../../Modal/ConfirmModal";
import InlineEdit from "../../InlineEdit";

type Props = {
    product: any;
    salePrice: any;
    currency: string;
    exchangeRate: any;
    handleUpdateQuantitySku: (quantify: number, productId: any, skuId: any) => void;
}

function ProductItem(props: Props) {
    const {t} = useTranslation();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const {product, salePrice, currency, handleUpdateQuantitySku, exchangeRate} = props;
    const [quantity, setQuantity] = useState<number>(product.quantity);
    const [price, setPrice] = useState<number>(product.salePrice);
    const [errorQuantity, setErrorQuantity] = useState<string>();
    //@ts-ignore
    const refInputQuantity = useRef<Input>(null);
    useEffect(() => {
        if (product) {
            setQuantity(product.quantity);
            if(!salePrice)
                setPrice(product.salePrice);
            if (errorQuantity)
                setErrorQuantity('');
        }

    }, [product]);
    useEffect(() => {
        if (salePrice) {
            setPrice(salePrice);
        }

    }, [salePrice]);
    const handleCancel = () => {
        setShowConfirm(false);
        setQuantity(product.quantity);
    }
    const onUpdateQuantifyByBatchSize = (plus: boolean) => {
        let quantity = lodash.cloneDeep(product.quantity);
        if (plus) quantity = quantity + product.batchSize;
        else{
            quantity = quantity - product.batchSize;
        }
        if(quantity<1) {
            setShowConfirm(true);
        }else
        handleUpdateQuantitySku(quantity, product.productId, product.id);
    }
    const onChangeQuantity = (raw: any) => {
        let value = raw.replace(/[^\d]/g, "");
        setQuantity(value);
        // let temp = parseInt(value);
        // if (!value || (product.minQuantity && temp < product.minQuantity)) setErrorQuantity(t('message.errorMinQuantity', {value: product.minQuantity}));
        // else if (product.stock && temp > product.stock) setErrorQuantity(t('message.errorMaxQuantity', {value: product.stock}));
        // else if (product.batchSize && temp % product.batchSize !== 0) setErrorQuantity(t('message.errorBatchSize', {value: product.batchSize}));
        // else if (product.limitQuantity && temp > product.limitQuantity) setErrorQuantity(t('message.limitQuantity'));
        // else setErrorQuantity('');
    }
    const onChangeQuantityBlur = (e: any) => {
        let raw = e.target.value;
        raw = raw.replace(/[^\d]/g, "");
        let value = parseInt(raw);
        if (!value) {
            setShowConfirm(true);
        }
        else {
            handleUpdateQuantitySku(value, product.productId, product.id);
        }

    }
    return (
        <div className={'width100'}>
                <Row>
                    <Col span={7}>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className={''}>
                                        <Avatar shape={'square'}
                                                size={40}
                                                src={lodash.get(product, 'image', '')}/>
                                    </Col>
                                    <Col span={16} className={'mgl5'}>
                                        <Row><a className={'txt-color-black txt-size-h7 robotomedium '}
                                                target="_blank"
                                                href={lodash.get(product, 'url')}>{product.name}</a></Row>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </Col>
                    <Col span={6} className={'align-items-center'}>
                        <Row className={'dpl-flex justify-content-center align-items-center'}>
                            <Button onClick={() => onUpdateQuantifyByBatchSize(false)}
                                    disabled={product.quantity ===0} ghost
                                    icon={<span className={'txt-color-gray2'}><i
                                        className="fas fa-minus"></i></span>}></Button>
                            <Col span={8}>
                                <Input
                                    ref={refInputQuantity}
                                    style={{textAlign: 'center'}}
                                    className={'txt-color-secondary'}
                                    value={quantity}
                                    onChange={(e) => onChangeQuantity(e.target.value)}
                                    onBlur={(e) => onChangeQuantityBlur(e)}
                                    onKeyDown={e => {
                                        //detect ENTER
                                        if (e.keyCode === 13 && refInputQuantity && refInputQuantity.current) {
                                            refInputQuantity.current.blur();
                                        }
                                    }}
                                />

                            </Col>
                            <Button onClick={() => onUpdateQuantifyByBatchSize(true)} ghost
                                    icon={<span className={'txt-color-gray2'}><i
                                        className="fas fa-plus"></i></span>}></Button>
                        </Row>
                        <Row className={'dpl-flex justify-content-center'}>
                            {errorQuantity &&
                            <span className={'txt-color-orange txt-center mgt5'}>{errorQuantity}</span>}
                        </Row>
                    </Col>
                    <Col span={5}>
                        {
                            product.variantProperties.map((x: any, index: number) => {
                                return <Row className={'align-items-center'} key={x.id}><span className={'txt-size-h8 txt-color-secondary'}>{`${x.name}: ${x.value}`}
                                </span>
                                </Row>
                            })
                        }
                    </Col>
                    <Col span={3}>
                        <Row className={'dpl-flex align-items-center justify-content-end'}>
                                            <span
                                                className={'robotoregular txt-size-h7  mgt5'}>{exchangeRate&&exchangeRate.rate&&exchangeRate.exchange?formatMoneyByUnit(price*exchangeRate.rate, exchangeRate.exchange):formatMoneyByUnit(price, currency)}</span>
                        </Row>
                    </Col>
                    <Col span={3}>
                        <Row className={'dpl-flex align-items-center justify-content-end'}>
                                             <span
                                                 className={'robotoregular txt-size-h7  txt-color-orange mgt5'}>
                                                 {price != null && quantity ? exchangeRate&&exchangeRate.rate&&exchangeRate.exchange? formatMoneyByUnit(quantity * price * exchangeRate.rate, exchangeRate.exchange): formatMoneyByUnit(quantity * price, currency) : '---'}
                                             </span>

                        </Row>
                    </Col>

                </Row>
            {showConfirm && <ConfirmModal
                visible={showConfirm}
                loading={false}
                content={t('shopping-cart.confirmDeleteProduct')}
                onSubmit={()=>{
                    handleUpdateQuantitySku(0, product.productId, product.id);
                    setShowConfirm(false);
                }}
                onCancel={handleCancel}
            />}
        </div>
    );
}

export default ProductItem;
