import { Badge, Col, Divider, Image, Row, Steps, Table, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import M24Pagination from '../../components/Pagination/M24Pagination'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatNumber } from '../../core/helpers/string'
import { packageRepository } from '../../repositories/PackagesRepository'
import IconDrawFailedImg from '../../resources/images/empty-3.png'
import { getCleanFormFilter } from '../../utils/StringUtils'

const { Step } = Steps
interface ListProps {
	filter: any
	packages: any
	statuses: any
	accounts: any
	total: number
	page: number
	size: number
	idAcc: string
	showProgressBar: boolean
	handleFilter: (filter: any) => void
}
const List: React.FC<ListProps> = (props) => {
	const [searchParams] = useSearchParams()
	const { t } = useTranslation()
	const {
		packages,
		statuses,
		handleFilter,
		accounts,
		total,
		page,
		size,
		showProgressBar,
		idAcc,
		filter,
	} = props
	const [expandedRows, setExpandedRows] = useState<Array<any>>([])
	const [history, setHistory] = useState<{ [id: string]: any }>({})
	const [loading, setLoading] = useState<{ [id: string]: any }>({})
	const [logo, setLogo] = useState('')
	const [provider, setProvider] = useState('')
	const [customer, setCustomer] = useState('')

	const emptyText = '---'
	useEffect(() => {
		const indexAcc = accounts.findIndex((acc: any) => acc.id === idAcc)
		if (indexAcc > -1 && !showProgressBar) {
			const logo = lodash.get(accounts[indexAcc], 'provider.logo', '')
			const provider = lodash.get(accounts[indexAcc], 'provider.code', '')
			const customer = lodash.get(accounts[indexAcc], 'name', '')
			setLogo(logo)
			setProvider(provider)
			setCustomer(customer)
		}
	}, [accounts, idAcc, showProgressBar])
	const columns = [
		{
			title: t('packages.packageCode'),
			key: 'code',
			dataIndex: 'code',
			width: 200,
			render: (code: string) => (
				<div className='dpl-flex align-items-center gap-6'>
						{lodash.includes(expandedRows, code) ? (
						<i className='far fa-angle-down txt-color-orange2 w-8 line-h-22 cursor-pointer'></i>
					) : (
						<i className='far fa-angle-right txt-color-orange2 w-8 line-h-22 cursor-pointer'></i>
					)}
					<Paragraph
						className='robotomedium mg-0 txt-color-orange2 fsz-14 line-h-22 order-heading whitespace'
						copyable={{
							text: code,
							icon: [
								<Tooltip
									title={
										<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copy</span>
									}
									color='#FBB739'>
									<i className='far fa-copy line-h-14 w-12'></i>
								</Tooltip>,
								<Tooltip
									title={
										<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copied</span>
									}
									color='#FBB739'>
									<i className='far fa-check fsz-12 line-h-14 w-12'></i>
								</Tooltip>,
							],
							tooltips: [false, false],
						}}>
						{code}
					</Paragraph>
				</div>
			),
		},
		{
			title: t('packages.orderCode'),
			key: 'orderCode',
			dataIndex: 'orderCode',
			width: 180,
			render: (orderCode: any) => (
				<Paragraph
					className='robotomedium mg-0 txt-color-black fsz-14 line-h-24 order-heading whitespace'
					copyable={{
						text: orderCode,
						icon: [
							<Tooltip
								title={<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copy</span>}
								color='#FBB739'>
								<i className='far fa-copy line-h-14 w-12'></i>
							</Tooltip>,
							<Tooltip
								title={
									<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copied</span>
								}
								color='#FBB739'>
								<i className='far fa-check fsz-12 line-h-14 w-12'></i>
							</Tooltip>,
						],
						tooltips: [false, false],
					}}>
					{orderCode}
				</Paragraph>
			),
		},
		{
			title: t('packages.supplierAcc'),
			key: 'supplierAcc',
			width: 180,
			render: (record: any) => {
				return (
					<div className='dpl-flex gap-4 align-items-center whitespace'>
						<Image
							preview={false}
							src={logo}
							className='bd-none w-12 h-12 dpl-flex align-items-center'
						/>
						<span className={'robotoregular fsz-14 line-h-22 txt-color-black'}>{provider}</span>
						<Divider type={'vertical'} className={'bd-color-black line-h-22 mg-0'} />
						<span className={'robotoregular fsz-14 line-h-22 txt-color-black'}>{customer}</span>
					</div>
				)
			},
		},
		{
			title: t('packages.volume'),
			key: 'volumetric',
			dataIndex: 'volumetric',
			width: 180,
			align: 'right' as 'right',
			render: (volumetric: any) => (
				<span className='robotoregular fsz-14 line-h-22 whitespace'>{`${formatNumber(
					volumetric
				)}cm3`}</span>
			),
		},
		{
			title: t('packages.details'),
			key: 'details',
			width: 180,
			align: 'right' as 'right',
			render: (record: any) => {
				const length = lodash.isNull(lodash.get(record, 'length'))
					? '---'
					: lodash.get(record, 'length', emptyText)
				const width = lodash.isNull(lodash.get(record, 'width'))
					? emptyText
					: lodash.get(record, 'width', emptyText)
				const height = lodash.isNull(lodash.get(record, 'height'))
					? emptyText
					: lodash.get(record, 'height', emptyText)
				return (
					<span className='robotoregular txt-color-black fsz-14 line-h-22 whitespace'>
						{`${formatNumber(length)} x ${formatNumber(width)} x ${formatNumber(height)} (cm)`}
					</span>
				)
			},
		},
		{
			title: t('packages.weight'),
			key: 'weight',
			dataIndex: 'actualWeight',
			width: 180,
			align: 'right' as 'right',
			render: (actualWeight: string) => (
				<span className='robotoregular txt-color-black fsz-14 line-h-22 whitespace'>
					{`${formatNumber(actualWeight)}kg`}
				</span>
			),
		},

		{
			title: t('packages.status'),
			key: 'status',
			dataIndex: 'status',
			width: 180,
			render: (status: any, record: any) => {
				const statusObj = statuses.find(({ code }: any) => code === status)
				const statusName = lodash.isNull(lodash.get(statusObj, 'name'))
					? emptyText
					: lodash.get(statusObj, 'name', emptyText)
				return (
					<span
						className='robotoregular fsz-12 line-h-20 txt-color-white border-radius25 py-3 px-12 whitespace'
						style={{
							backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
								? '#09B2AA'
								: lodash.get(statusObj, 'color'),
						}}>
						{statusName}
					</span>
				)
			},
		},
	]

	const localeCustom = {
		emptyText: (
			<>
				{!showProgressBar && (
					<div className='empty fsz-14'>
						<Row justify='center' className='mg-bt-8' gutter={[0, 6]}>
							<Col lg={24}>
								<Image preview={false} src={IconDrawFailedImg} />
							</Col>
							<Col lg={24} className='text-disabled'>
								<span className='fsz-14 robotoregular txt-color-black line-h-22'>
									{t('packages.noData')}
								</span>
							</Col>
						</Row>
					</div>
				)}
			</>
		),
	}

	const handleChangePage = (filter: any) => {
		const { page, size } = filter
		handleFilter(getCleanFormFilter({
			...filter,
			page: (page - 1).toString(),
			size: size.toString(),
		}, [], ['pageSize']))
	}

	const CustomDescription = ({ code, idxHistory }: { code: string; idxHistory: string }) => {
		const historyTrackByPosition = history[idxHistory].filter(({ status }: any) => status === code)
		const classes =
			'description robotoregular dpl-flex flex-col align-items-start justify-content-start fsz-12 line-h-20'
		return (
			<span className={`${classes} gap-4`}>
				{historyTrackByPosition.length > 0 ? (
					historyTrackByPosition.map(({ timestamp, handlingTime }: any, idx: any) => {
						const timestampRendered = lodash.isNull(timestamp)
							? t('orderDetail.undefined')
							: formatDateTime(timestamp)
						const handlingTimeRendered = lodash.isNull(handlingTime)
							? t('orderDetail.undefined')
							: `${handlingTime} ngày`
						return (
							<span className={classes} key={idx}>
								<span className='txt-color-gray4'>{timestampRendered}</span>
								<span className='txt-color-gray4'>{`(${handlingTimeRendered})`}</span>
							</span>
						)
					})
				) : (
					<span className='txt-color-gray4'>{`${t('orderDetail.undefined')}`}</span>
				)}
			</span>
		)
	}

	const customDot = (dot: any, { status, index }: any) => {
		return status !== 'finish' ? (
			<i className='far fa-dot-circle fsz-10'></i>
		) : (
			<i className='far fa-dot-circle fsz-10'></i>
		)
	}

	return (
		<div className='white-box dpl-flex flex-col gap-13 pd-imp-12'>
			<div className='dpl-flex align-items-center gap-4'>
				<h3 className='robotoregular mg-0 fsz-14 line-h-22 txt-color-black'>
					{t('packages.list')}
				</h3>
				<Badge
					count={total}
					overflowCount={total}
					style={{ color: '#FAA200', backgroundColor: '#FFF6E5' }}
				/>
			</div>
			<Table
				rowKey={(record: any) => record.code}
				columns={columns}
				dataSource={packages}
				locale={localeCustom}
				expandRowByClick={true}
				pagination={false}
				scroll={{x:1200,y: 800 }}
				className='main-table table-expandable selected-table'
				loading={showProgressBar}
				expandable={{
					expandedRowRender: (record) => {
						const codes = lodash.uniq(lodash.map(history[record.code], 'status'))
						let positions: Number[] = []
						for (let index = 0; index < codes.length; index++) {
							const status = statuses.find(({ code }: any) => code === codes[index])
							positions.push(status.position)
						}
						const positionMax = lodash.max(positions)
						return (
							<div key={record.code} className='package-timeline'>
								<Steps progressDot={customDot}>
									{history[record.code] &&
										history[record.code].length > 0 &&
										statuses.map(({ name, code, id, position }: any) => {
											const indexOfStatus = history[record.code].findIndex(
												({ status }: any) => code === status
											)
											const status = indexOfStatus > -1 ? 'finish' : 'wait'
											const txtClasses = status === 'finish' ? 'txt-color-black' : 'txt-color-gray2'
											return (
												<Step
													status={status}
													key={id}
													className={
														position > positionMax!
															? 'tail-none'
															: position === positionMax
															? 'tail-none last-tail'
															: 'tail'
													}
													title={
														<span
															className={`txt-left robotomedium fsz-12 line-h-20 ${txtClasses}`}>
															{name}
														</span>
													}
													description={<CustomDescription code={code} idxHistory={record.code} />}
												/>
											)
										})}
								</Steps>
							</div>
						)
					},
					rowExpandable: (record) => record.name !== 'Not Expandable',
					expandIcon: ({ expanded, onExpand, record }) => null,
					columnWidth: 0,
					onExpand: (expanded, record) => {
						if (expanded && !history[record.code]) {
							setLoading({ ...loading, [record.code]: true })
							packageRepository
								.getPackageHistory(
									lodash.get(record, 'code'),
									searchParams.get('customer'),
									searchParams.get('provider')
								)
								.then((res) => setHistory({ ...history, [record.code]: res }))
								.catch((err) => {})
								.finally(() => setLoading({ ...loading, [record.code]: false }))
						}
					},
					onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
				}}
				rowClassName={(record) =>
					lodash.includes(expandedRows, record.code)
						? 'active-row cursor-pointer'
						: 'data-row cursor-pointer'
				}
			/>
			<Row justify={'end'} className={''}>
				{packages && packages.length > 0 && (
					<M24Pagination
						onPageChange={handleChangePage}
						filter={{...filter, page, size}}
						pagination={{
							current: page,
							pageSize: size,
							hideOnSinglePage: true,
							total,
						}}
						className='mg-0'
					/>
				)}
			</Row>
		</div>
	)
}

export default List