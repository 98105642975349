import {Spin, Timeline} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from 'moment';
import {ClaimDetailContext} from "../../context/ClaimDetailContext";
import {claimRepository} from "../../repositories/ClaimRepository";
import {ClaimMilestoneInterface} from "../../Interface/ClaimMilestoneInterface";
import {getClaimStatusByCode} from "../../utils/util";

export const Status = () => {
    const {claim, provider, customerCode, statuses} = useContext(ClaimDetailContext)
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [milestones, setMilestones] = useState<ClaimMilestoneInterface[]>([])

    useEffect(() => {
        if (provider.code && claim.code) {
            setLoading(true)
            claimRepository.getMilestones(provider.code, customerCode, claim.relatedOrder, claim.code)
                .then(items => setMilestones(items))
                .finally(() => setLoading(false))
        }
    }, [claim, provider, customerCode])

    return (
        <div className={'ticket-status-box h-fit-content box-shadow'}>
            <Spin spinning={loading}>
                <div className={'mgbt15 robotomedium txt-color-black fsz-16'}>{t('tickets.ticket-status')}</div>
                <Timeline className={`status-length-${milestones.length}`}>
                    {milestones.length && milestones.map(item => {
                        const statusItem = getClaimStatusByCode(statuses, item.state)
                        if (statusItem.name) {
                            return (
                                <Timeline.Item key={item.state} color={statusItem.color} dot={<i className="fa-regular fa-circle-dot"/>}>
                                    <div
                                        className={'txt-capitalize robotomedium txt-color-black'}>{statusItem.name}</div>
                                    <div
                                        className={'ticket-status-time'}>{item.timestamp ? moment(item.timestamp).format("HH:mm DD/MM/YYYY") : '--'}</div>
                                    <div
                                        className={'ticket-status-time'}>({moment.duration(moment().diff(moment(item.timestamp), 'seconds'), 'seconds').humanize()})
                                    </div>
                                </Timeline.Item>
                            )
                        }
                    })}

                    {!milestones.length && (
                        <Timeline.Item color={claim.publicState.color} dot={<i className="fa-regular fa-circle-dot" />}>
                            <div className={'txt-capitalize robotomedium txt-color-black'}>{claim.publicState.name}</div>
                            <div className={'ticket-status-time'}>{claim.createdAt ? moment(claim.createdAt).format("HH:mm DD/MM/YYYY") : '--'}</div>
                            <div className={'ticket-status-time'}>({moment.duration(moment().diff(moment(claim.createdAt), 'seconds'), 'seconds').humanize()})</div>
                        </Timeline.Item>
                    )}
                </Timeline>
            </Spin>
        </div>
    )
}
