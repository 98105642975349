import {RedoOutlined} from '@ant-design/icons'
import {Button, Col, DatePicker, Divider, Form, Image, Input, Row, Select} from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import {getCleanFormFilter, handleInputChangeMany} from '../../utils/StringUtils'
import './style.scss'

const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker
interface FilterProps {
	statuses: any
	accounts: any
	idAcc: any
	handleFilter: (filter: any) => void
	handleProgressBar: (val: boolean) => void
	handleAcc: (id: string) => void
}

const Filter: React.FC<FilterProps> = (props) => {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const [searchParams, setSearchParams] = useSearchParams()

	const [showFilter, setShowFilter] = useState(true)
	const { statuses, accounts, idAcc, handleFilter, handleAcc, handleProgressBar } = props
	const [typeSearch, setTypeSearch] = useState<string | null>(
		searchParams.get('typeSearch') || 'range'
	)
	const [selectedStatuses, setSelectedStatuses] = useState<{ statuses: any }>({
		statuses: searchParams.get('statuses') || '',
	})

	useEffect(() => {
		if (searchParams.get('customer') && searchParams.get('provider')) {
			const indexOfAccount = accounts.findIndex(
				(acc: any) =>
					acc.name === searchParams.get('customer') &&
					acc.provider.code === searchParams.get('provider')
			)
			if (indexOfAccount > -1) {
				handleAcc(lodash.get(accounts[indexOfAccount], 'id'))
			}
		} else {
			const index = accounts.findIndex((acc: any) => acc.id === idAcc)
			if (index > -1) {
				setSearchParams({
					customer: lodash.get(accounts[index], 'name'),
					provider: lodash.get(accounts[index], 'provider.code'),
				})
			}
		}

		form.setFieldsValue({
			typeSearch: searchParams.get('typeSearch') || 'range',
			supplier: idAcc,
			query: searchParams.get('query'),
			timeInEqual: searchParams.get('typeSearch') === 'equal' ? searchParams.get('handlingTimeTo') : '',
			fromInRange: searchParams.get('typeSearch') === 'range' ? searchParams.get('handlingTimeFrom') : '',
			toInRange: searchParams.get('typeSearch') === 'range' ? searchParams.get('handlingTimeTo') : '',
			timeOnlyFrom: searchParams.get('typeSearch') === 'from' ? searchParams.get('handlingTimeFrom') : '',
			timeOnlyTo: searchParams.get('typeSearch') === 'to' ? searchParams.get('handlingTimeTo') : '',
			createdAt: [
				searchParams.get('createdFrom') ? moment(searchParams.get('createdFrom')) : null,
				searchParams.get('createdTo') ? moment(searchParams.get('createdTo')) : null,
			],
			cutOffStatus: searchParams.get('cutOffStatus'),
		})
	}, [accounts, form, handleAcc, idAcc, searchParams, setSearchParams])



	const onChangeFilterMany = (key: string, isCheck: boolean, code: string) => {
		const temp = handleInputChangeMany(key, code, selectedStatuses)
		setSelectedStatuses({ ...temp })
	}

	const getFormState = () => {
		const supplier = form.getFieldValue('supplier')
		const account = accounts.find((x: any) => x.id === supplier)
		const customer = lodash.get(account, 'name')
		const provider = lodash.get(account, 'provider.code')
		const createdAt = form.getFieldValue('createdAt')
		const createdFrom =
			createdAt && createdAt[0] && moment(createdAt[0].startOf('day')).toISOString()
		const createdTo = createdAt && createdAt[1] && moment(createdAt[1].endOf('day')).toISOString()
		const handlingTimeFrom =
			typeSearch === 'equal'
				? form.getFieldValue('timeInEqual')
				: typeSearch === 'range'
				? form.getFieldValue('fromInRange')
				: typeSearch === 'from'
				? form.getFieldValue('timeOnlyFrom')
				: ''
		const handlingTimeTo =
			typeSearch === 'equal'
				? form.getFieldValue('timeInEqual')
				: typeSearch === 'range'
				? form.getFieldValue('toInRange')
				: typeSearch === 'to'
				? form.getFieldValue('timeOnlyTo')
				: ''
		const filter = getCleanFormFilter(
			{
				...form.getFieldsValue(true),
				statuses: selectedStatuses?.statuses,
				customer,
				provider,
				createdFrom,
				createdTo,
				handlingTimeFrom,
				handlingTimeTo,
			},
			[],
			[
				'supplier',
				'createdAt',
				'timeInEqual',
				'fromInRange',
				'toInRange',
				'timeOnlyFrom',
				'timeOnlyTo',
			]
		)
		return filter
	}
	const onFinish = () => {
		handleFilter(getFormState())
	}

	const clearFilter = () => {
		form.resetFields()
		setSelectedStatuses({ statuses: '' })
		handleFilter({ ...lodash.omit(getFormState(), 'statuses')})
	}

	const handleChangeTypeSearch = (value: string) => {
		form.setFieldsValue({...form.getFieldsValue(),
		timeInEqual: '',
		fromInRange: '',
		toInRange: '',
		timeOnlyFrom: '',
		timeOnlyTo: ''})
		setTypeSearch(value)
	}

	const handleChangeAcc = (val: string) => {
		handleAcc(val)
		handleProgressBar(true)
		onFinish()
	}

	const labelClasses = 'txt-color-black robotoregular fsz-14 line-h-22'

	return (
		<Row className='white-box mg-bt-12 pd-imp-12'>
			<Col span={12}>
				<h3 className='robotomedium fsz-16 line-h-24 txt-color-black'>{t('packages.searchHead')}</h3>
			</Col>
			<Col span={12} className='dpl-flex justify-content-end'>
				{showFilter ? (
					<i
						className='fas fa-angle-up txt-color-black fsz-14 cursor-pointer'
						onClick={() => setShowFilter(!showFilter)}></i>
				) : (
					<i
						className='fas fa-angle-down txt-color-black fsz-14 cursor-pointer'
						onClick={() => setShowFilter(!showFilter)}></i>
				)}
			</Col>
			{showFilter && (
				<>
					<Col span={24} className='dpl-flex align-items-center gap-6 mg-bt-9'>
						<span className='dpl-flex gap-6 align-items-center flex-wrap'>
							<span className='robotoregular fsz-14 line-h-22 whitespace txt-color-black'>
								{t('packages.statuses')}
							</span>
							{statuses.map(({ code, name }: any) => {
								let isSelect = false
								if (selectedStatuses) {
									let temp = selectedStatuses?.statuses?.split(',')
									if (temp && temp.includes(code)) isSelect = true
								}
								return (
									<span
										className={isSelect ? 'status-package active' : 'status-package'}
										key={code}
										onClick={() => {
											onChangeFilterMany('statuses', !isSelect, code)
										}}>
										{name}
									</span>
								)
							})}
						</span>
					</Col>
					<Col span={24}>
						<Form
							name='package-filter'
							onFinish={onFinish}
							className='packages-filter'
							form={form}
							initialValues={{
								query: '',
								supplier: idAcc,
								createdAt: [],
								cutOffStatus: undefined,
								handlingTimeFrom: '',
								handlingTimeTo: '',
								handlingTime: '',
								fromInRange: '',
								toInRange: '',
								timeInEqual: '',
								fromOnlyFrom: '',
								timeOnlyTo: '',
							}}>
							<Row align='middle' gutter={[24, 8]} className='mg-bt-12'>
								<Col span={8} className='mg-bt-0'>
									<Item
										label={
											<span className={labelClasses}>
												{t('packages.findByQuery')}
											</span>
										}
										labelAlign='left'
										className='query gap-4'
										name={'query'}>
										<Input placeholder={t('packages.placeHolderQuery')} />
									</Item>
								</Col>
								<Col span={8}>
									<Item
										label={
											<span className={labelClasses}>
												{t('packages.providerAccount')}
											</span>
										}
										className='width100pc gap-4'
										name='supplier'
										valuePropName='option'>
										<Select
											showSearch={false}
											placeholder={t('packages.placeHolderChooseAcc')}
											value={idAcc}
											onChange={(val: string) => handleChangeAcc(val)}>
											{accounts.map((acc: any) => (
												<Option key={acc.id} value={acc.id}>
													<div className='dpl-flex gap-4 align-items-center'>
														<Image
															preview={false}
															src={lodash.get(acc, 'provider.logo', '')}
															className='bd-none w-12 h-12 dpl-flex align-items-center'
														/>
														<span className='robotoregular fsz-14 line-h-22'>
															{lodash.get(acc, 'provider.code', '')}
														</span>
														<Divider
															type={'vertical'}
															className={'bd-color-black line-h-22 mg-0'}
														/>
														<span className='robotoregular fsz-14 line-h-22'>
															{lodash.get(acc, 'name', '')}
														</span>
													</div>
												</Option>
											))}
										</Select>
									</Item>
								</Col>
								<Col span={8}>
									<Item
										label={
											<span className={labelClasses}>
												{t('packages.createdAt')}
											</span>
										}
										name='createdAt'
										className='gap-4'>
										<RangePicker
											placeholder={[t('packages.fromDate'), t('packages.toDate')]}
											className='width100pc'
											format={'DD-MM-YYYY'}
											suffixIcon={<i className="far fa-calendar-alt"/>}
											//@ts-ignore
											placement='topRight'
										/>
									</Item>
								</Col>
								<Col span={8} className='mg-bt-0'>
									<Item
										label={
											<span className={labelClasses}>
												{t('packages.statusStopped')}
											</span>
										}
										labelAlign='left'
										className='status-cutoff gap-4'
										name='cutOffStatus'>
										<Select placeholder={t('packages.stoppedStatusPlaceHolder')}>
											{statuses.map((stt: any) => (
												<Option key={stt.code} value={stt.code} className={''}>
													<span className={'robotoregular fsz-14 line-h-22'}>
														{lodash.get(stt, 'name', '')}
													</span>
												</Option>
											))}
										</Select>
									</Item>
								</Col>
								<Col span={6}>
									<Item name='typeSearch' className='mg-r-4' valuePropName='option'>
										<Select
											showSearch={false}
											value={typeSearch}
											onChange={(val) => handleChangeTypeSearch(val)}>
											<Option value='range'>
												<span className={labelClasses}>{t('packages.rangeHandlingTime')}</span>
											</Option>
											<Option value='equal'>
												<span className={labelClasses}>{t('packages.equalHandlingTime')}</span>
											</Option>
											<Option value='from'>
												<span className={labelClasses}>{t('packages.fromHandlingTime')}</span>
											</Option>
											<Option value='to'>
												<span className={labelClasses}>{t('packages.toHandlingTime')}</span>
											</Option>
										</Select>
									</Item>
								</Col>
								<Col span={10} className='type-search gap-12'>
								
									{typeSearch === 'range' ? (
										<>
											<Item colon={false} name='fromInRange'>
												<Input placeholder={t('packages.placeHolderHandlingTimeFrom')} />
											</Item>
											<Item name='toInRange'>
												<Input placeholder={t('packages.placeHolderHandlingTimeTo')} />
											</Item>
											<span className='robotoregular fsz-12 line-h-20 txt-color-gray4'>
												{t('packages.date')}
											</span>
										</>
									) : typeSearch === 'equal' ? (
										<Item name={'timeInEqual'}>
											<Input placeholder={t('packages.placeHolderForHandlingTimeNotRange')} />
										</Item>
									) : typeSearch === 'from' ? (
										<Item name={'timeOnlyFrom'}>
											<Input placeholder={t('packages.placeHolderForHandlingTimeNotRange')} />
										</Item>
									) : (
										<Item name={'timeOnlyTo'}>
											<Input placeholder={t('packages.placeHolderForHandlingTimeNotRange')} />
										</Item>
									)}
								</Col>
							</Row>
							<Row className='dpl-flex justify-content-end gap-12 aling-items-center'>
								<Button
									className='clean-filter robotoregular txt-color-gray4 fsz-12 line-h-20 dpl-flex align-items-center
													flex-basis-btn-filter cursor-pointer bd-none gap-4'
									onClick={clearFilter}
									icon={<RedoOutlined />}>
									<span className='mg-0'>{t('packages.refreshFilter')}</span>
								</Button>
								<Button
									className='robotoregular txt-color-white bg-color-blue7 fsz-14 line-h-22 bd-none border-radius4
													px-12 py-3 flex-basis-btn-filter search-packages'
									form={'package-filter'}
									htmlType='submit'>
									{t('packages.search')}
								</Button>
							</Row>
						</Form>
					</Col>
				</>
			)}
		</Row>
	)
}

export default Filter
