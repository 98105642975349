import { Steps } from "antd"
import lodash from 'lodash'
import { GetComponentProps } from "rc-table/lib/interface"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { formatDateTime } from "../../../../core/helpers/date-time"
import { categoryRepository } from "../../../../repositories/CategoryRepository"
import './style.scss'

const {Step} = Steps

const TracksPackage:React.FC<GetComponentProps<any>> = (record) => {
    const {t} = useTranslation()
    const [statuses, setStatuses] = useState([])
    
    const getStatuses= useCallback(()=>{
        categoryRepository.getPackageStatuses().then(res => {
            setStatuses(res);
        }).catch(err => {})
    }, [])
    
    useEffect(() => {
        getStatuses()
    }, [getStatuses])

    const customDot = (dot: any, { status, index }: any) => {
        return  status !== 'finish' ? <i className="far fa-dot-circle fsz-10"></i> : <i className="far fa-dot-circle fsz-10"></i> 
    }
    const historyPackage = lodash.get(record, 'milestones')
	
      const CustomDescription = ({code}: {code: string}) => {
        const historyTrackByPosition = historyPackage.filter(({status}:any) => status === code )
        const classes = 'robotoregular dpl-flex flex-col align-items-start justify-content-start fsz-12 line-h-20'
        return (
            <span className={`${classes} gap-4`}>
                {historyTrackByPosition.length > 0 ? historyTrackByPosition.map(({timestamp, handlingTime}:any, idx: number) => {
                        const timestampRendered = lodash.isNull(timestamp) ? t('orderDetail.undefined') : formatDateTime(timestamp)
                        const handlingTimeRendered = lodash.isNull(handlingTime) ? t('orderDetail.undefined') : `${handlingTime} ngày`
                    return (
                        <span className={classes} key={idx}>
                            <span className='txt-color-gray4'>{timestampRendered}</span>
                            <span className="txt-color-gray4">{`(${handlingTimeRendered})`}</span>
                        </span>
                    )   
                }): <span className="txt-color-gray2">{`${t('orderDetail.undefined')}`}</span>}   
            </span>
        )
      }
      const codes = lodash.uniq(lodash.map(historyPackage, 'status'))
      let positions:Number[] = []
      for (let index = 0; index < codes.length; index++) {
          const status = statuses.find(({code}: any) => code === codes[index])
          positions.push(lodash.get(status, 'position')!)
      }
    const positionMax = lodash.max(positions)
    const packageCode = lodash.get(record, 'code')
    const lastIndex = lodash.get(record, 'total')
    const curIndex = lodash.get(record, 'index')
    return (
        <>
            <tr className={`package-data border-radius3 ${!packageCode ? 'bg-color-white' : 'bg-color-pink1'}`}>{record.children}</tr>
            <tr className="bd-bt-none package-timeline">
                <td colSpan={6} className="td-table-cell">
                <div className={`package-timeline ${curIndex === lastIndex - 1 ? 'pd-bt-0' : 'pd-bt-11'}`}>
                    <Steps  progressDot={customDot}>
                            {historyPackage && historyPackage.length > 0 && statuses.map(({name, code, id, position} : any) =>{
                                const indexOfStatus = historyPackage.findIndex(({status}: any) => code === status)
                                const status = indexOfStatus > -1 ? 'finish' : 'wait'
                                const txtClasses = status === 'finish' ? 'txt-color-black' : 'txt-color-gray2'
                                return (
                                    <Step   status={status} key={id} className={position > positionMax! ? 'tail-none' : position === positionMax ? 'tail-none last-tail' : 'tail'}
                                            title={<span className={`txt-left robotomedium fsz-12 line-h-20 ${txtClasses}`}>{name}</span>}
                                            description={<CustomDescription code={code} />} />)}
                                )
                            }
                    </Steps>
                </div>
                </td>
            </tr>
        </>
    )
}

export default TracksPackage