import React, { useRef } from 'react'
interface ItemProps {
	label: any
	content: any
	className?: string
}
const Item:React.FC<ItemProps> = (props) => {
    const {label, content, className} = props
    return (
		<div className={`robotoregular width100pc dpl-flex align-items-baseline ${className ? className : ''}`}>
			<span  className={`line-h-22 h-22 txt-nowrap txt-color-gray4 mg-r-3`}>{label}</span>
			<span  className='dpl-flex txt-size-h7 txt-color-black align-items-baseline info-item width100pc'>{content}</span>
		</div>
  	)
}

export default Item
