import {Avatar, Button, Col, Row, Tooltip} from 'antd'
import React, {useEffect, useState} from 'react'
import lodash from 'lodash'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import Paragraph from 'antd/lib/typography/Paragraph'
import './style.scss'
import LogModal from "../LogModal";
import ConfirmModal from "../../Modal/ConfirmModal";
import {getUrlPathname} from "../../../utils/util";

interface HeadingProps {
    orderDetail: any
    reOrder:()=>void
    isReOrdering:boolean
    statuses: any
    cancelOrder:(callback: ()=>void)=>void;
    isCancelOrdering:boolean;
    showBoxChat: () => void
    loadingCreateThread: boolean
}


const Heading:React.FC<HeadingProps> = (props) => {
    const{ orderDetail, statuses, reOrder, isReOrdering, cancelOrder, isCancelOrdering, loadingCreateThread} = props;
    const {t} = useTranslation()
    const [showModal, setShowModal] = useState(false)
    const codeOrder = lodash.get(orderDetail, 'code', '--')
    const productImage = lodash.get(orderDetail, 'image', '')
    const providerLink = lodash.get(orderDetail, 'provider.domain')
    const providerCustomer = lodash.get(orderDetail, 'customer', '--')
    const logo = lodash.get(orderDetail, 'provider.logo', '')
    const [isCancellable, setCancellable] = useState<boolean>(false);
    const [isConfirm, setConfirm] = useState<boolean>(false);
    const statusOj = statuses.find(({code}: any) => code === lodash.get(orderDetail, 'status'))
    const handleVisibleLogOrderModal = () => {
        setShowModal(true)
    }
    const handleCancel = () => {
        setShowModal(false)
    }
    useEffect(()=>{
        if(orderDetail){
            let status = statuses.find((x: any)=> x.code===orderDetail.status);
            setCancellable(lodash.get(status,'cancellable',false));
        }
    },[orderDetail]);
   const onCancel=()=>{
        cancelOrder(()=>{
            setConfirm(false);
        });
    }

  return (
      <>
        <Row className="pd12 bg-color-white dpl-flex justify-content-between align-items-center">
            <Col className="dpl-flex justify-content-between align-items-start gap-64">
                <div className="dpl-flex gap-8">
                    <Avatar shape="square" size={48} src={productImage} className='border-radius3 bd-none-impt'/>
                    <div className="dpl-flex flex-col gap-2">
                        <span className="dpl-flex gap-2 align-items-center robotoregular">
                            <span className="txt-color-gray4 fsz-12 line-h-20">ID: </span>
                            {codeOrder &&   <Paragraph
                                                    className='robotomedium mg-0 txt-color-primary fsz-16 robotoregular line-h-24 order-heading'
                                                    copyable={{
                                                        text: codeOrder,
                                                        icon: [
                                                            <Tooltip title={<span className="robotoregular fsz-12 txt-color-black line-h-20">Copy</span>} color='#FBB739'>
                                                                <i className="far fa-copy line-h-14 w-12"></i>
                                                            </Tooltip>,
                                                            <Tooltip title={<span className="robotoregular fsz-12 txt-color-black line-h-20">Copied</span>} color='#FBB739'>
                                                                <i className="far fa-check fsz-12 line-h-14 w-12"></i>
                                                            </Tooltip>
                                                            ]
                                                        , tooltips: [false, false]
                                                    }}>
                                                {`#${codeOrder}`}
                                            </Paragraph>}
                        </span>
                        <span className='px-12 h-25 bg-color-blue5 border-radius25 align-items-center dpl-flex justify-content-center'>
                            <span className='robotoregular txt-color-white fsz-12 line-h-20'>{lodash.get(statusOj, 'name')}</span>
                        </span>
                    </div>
                </div>
                <div className="dpl-flex flex-col align-items-start robotoregular">
                    <span className="dpl-flex align-items-center txt-size-h7 line-h-22 txt-color-black gap-4">
                        <Avatar size={12} src={logo} shape={'circle'} style={{border:"none"}} />
                        <span>{providerLink}</span>
                        <span>|</span>
                        <span className={'fsz-14 robotoregular txt-color-black'}>{providerCustomer}</span>
                    </span>
                    <span className="dpl-flex gap-4 align-items-center txt-color-blue1 txt-size-h7 cursor-pointer">
                        <i className="far fa-file-alt fsz-11 line-h-12"></i>
                        <span onClick={handleVisibleLogOrderModal} className='txt-underline line-h-22'>{t('orderDetail.log')}</span>
                    </span>
                </div>

                <div className="dpl-flex flex-col align-items-start robotoregular">
                    <Button
                        loading={loadingCreateThread}
                        disabled={loadingCreateThread}
                        onClick={() => props.showBoxChat()}
                        className="dpl-flex align-items-center border-radius4 btn-create-thread-chat-by-order txt-size-h7 mg-r-9 mg-t-10"
                        icon={<i className="fa-regular fa-comment" />}>
                        <span>{t('button.createThreadChat')}</span>
                    </Button>
                </div>
            </Col>
            <Col className="dpl-flex align-items-center robotoregular">
                <Button className="dpl-flex align-items-center btn-report-order btn-create-claim-by-order btn-outlined-orange txt-size-h7 mg-r-9" icon={<i className="far fa-plus mg-r-9"></i>}>
                    <Link to={`/claims/create?orderCode=${codeOrder}&providerCode=${lodash.get(orderDetail, 'provider.code')}&customerCode=${lodash.get(orderDetail, 'providerUsername')}&back=${getUrlPathname(window.location)}`}>{t('orderDetail.btCreateTicket')}</Link>
                </Button>
                <i className="far fa-ellipsis-v mg-r-9 fsz-14 line-h-14 txt-color-gray2"></i>
                <Button  loading={isReOrdering} disabled={isReOrdering} onClick={reOrder}  className='dpl-flex align-items-center btn-re-order txt-size-h7 mg-r-6'>{t('orderDetail.reOrder')}</Button>
                {isCancellable&&<Button disabled={isCancelOrdering} onClick={()=>setConfirm(true)}  className="dpl-flex align-items-center btn-remove-order txt-size-h7">{t('orderDetail.cancel')}</Button>}
            </Col>
            {isConfirm&&<ConfirmModal onCancel={()=> setConfirm(false)} visible={isConfirm} onSubmit={onCancel} loading={isCancelOrdering} content={t('orderDetail.confirmCancel')}/>}
            {showModal&& <LogModal orderCurrency={lodash.get(orderDetail,'currency','')} visible={showModal} onCancel={handleCancel}></LogModal>}
        </Row>
      </>

  )
}

export default Heading
