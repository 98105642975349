import {Repository} from '../core/repositories/Repository';
import {url} from '../core/helpers/string';
import {httpConfig} from "../core/config/http";
import {API_BASE_URL} from "../core/config";
import {AxiosResponse} from "axios";
import {ClaimCreateInterface} from "../Interface/ClaimCreateInterface";
import {ClaimSolutionInterface} from "../Interface/ClaimSolutionInterface";
import {ClaimReasonInterface} from "../Interface/ClaimReasonInterface";
import {ClaimReasonCriteria} from "../Interface/ClaimReasonCriteria";

export class ClaimRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.setBaseURL(url(API_BASE_URL, ''));
    }

    getClaimDetail = (providerCode: string, customerCode: string, orderCode: string, claimCode: string) => {
        return this.http.get(`providers/${providerCode}/accounts/${customerCode}/orders/${orderCode}/claims/${claimCode}`)
    }

    archive = (providerCode: string, customerCode: string,  ticketCode: string) => {
        return this.http.put(`providers/${providerCode}/accounts/${customerCode}/claims/${ticketCode}/archive`)
    }

    rating = (providerCode: string, customerCode: string, ticketCode: string, data: {rating: number, comment: string}) => {
        return this.http.put(`providers/${providerCode}/accounts/${customerCode}/claims/${ticketCode}/rating`, data)
    }

    getClaimStatuses = (providerCode: string): Promise<any> => {
        return this.http.get(`/providers/${providerCode}/claim-states`, {
            params:{offset:0, limit:100}
        }).then((response:AxiosResponse) => {
            return response.data;
        });
    }

    getSolutions = (providerCode: string): Promise<Array<ClaimSolutionInterface>> => {
        return this.http.get(`/providers/${providerCode}/claim-solutions`,
            {params:{offset:0,limit:100,}}).then((response:AxiosResponse) => {
            return response.data;
        })
    }

    getReasons = (providerCode: string, filter?: ClaimReasonCriteria): Promise<Array<ClaimReasonInterface>> => {
        filter = {
            offset: 0,
            limit: 100,
            ...filter
        }
        return this.http.get(`/providers/${providerCode}/claim-reasons`,
            {
                params: {...filter}
            })
            .then((response:AxiosResponse) => {
                return response.data;
            })
    }

    getClaims = (providersCode: string, customerCode: string, filter: any):Promise<any> => {
        return this.http.get(`/providers/${providersCode}/accounts/${customerCode}/claims`,
            {params:{...filter}}
        ).then((response:AxiosResponse) => {
            return response;
        }) 
    }

    create = (data: ClaimCreateInterface) => {
        return this.http.post(`/claims`, data)
    }

    getClaimsByOrder = (
        providerCode: string | undefined, 
        accountCode: string | undefined, 
        orderCode: string | undefined, 
        filter: any
    ): Promise<any> => {
        const { page, size: limit, sort, order } = filter
        const offset = limit ? (page - 1) * limit : 0
        const params = { offset, limit, sort: sort || "createdAt", order: order || "DESC" }
        return this.http
            .get(
                `/providers/${providerCode}/accounts/${accountCode}/claims?orderCode=${orderCode}`,
                { params }
            )
            .then((response: AxiosResponse<any>) => {return response});
    }

    getMilestones = (providerCode: string, accountCode: string, orderCode: string, claimCode: string) => {
        return this.http.get(`/providers/${providerCode}/accounts/${accountCode}/orders/${orderCode}/claims/${claimCode}/milestones`)
            .then((response:AxiosResponse) => {
                return response.data
            })
    }
}

export const claimRepository: ClaimRepository = new ClaimRepository();
