import React, { useEffect, useState } from 'react';
import { Avatar, Checkbox, Table } from 'antd';
import lodash from 'lodash';
import Supplier from './Supplier';
import { formatMoneyByUnit, formatNumber } from '../../../core/helpers/string';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../core/helpers/date-time';
interface DeliveryConnectionProps {
	data: any;
	statuses: any;
	position: number;
	onSelectConnection: (index: number, checked: boolean) => void;
	onSelectOrder: (connectionIndex: number, orderCode: string, checked: boolean) => void;
	onSelectPackage: (
		connectionIndex: number,
		orderCode: string,
		packageCode: string,
		checked: boolean
	) => void;
	showBalance: boolean
}
function DeliveryConnection(props: DeliveryConnectionProps) {
	const { data, statuses, onSelectConnection, position, onSelectOrder, onSelectPackage, showBalance } = props;
	const { t } = useTranslation();
	const [currency, setCurrency] = useState<string>();
	const [expandedRows, setExpandedRows] = useState<Array<any>>([]);

	const classesRe = 'robotoregular fsz-14 line-h-22 txt-color-black whitespace';
	const classesMd = 'robotomedium fsz-14 line-h-22 txt-color-black whitespace';
	useEffect(() => {
		let currency = lodash.get(data, 'account.connection.provider.currency', '');
		if (currency) {
			let temp = JSON.parse(currency);
			setCurrency(lodash.get(temp, 'code', ''));
		}
	}, []);
	const rowSelectionOrder = {
		selectedRowKeys: lodash.get(data, 'selectedOrders', []),
		hideSelectAll: true,
		onSelect: (record: any, selected: boolean, selectedRows: any) => {
			onSelectOrder(position, record.code, selected);
		},
		getCheckboxProps: (record: any) => {
			return {
				indeterminate:
					!!record.selectedPackages.length &&
					record.selectedPackages.length < record.packages.length,
			};
		},
	};
	const columns = [
		{
			title: t('delivery.orders'),
			key: 'orderCode',
			dataIndex: 'code',
			width: 242,
			render: (code: any, record: any) => (
				<div className='dpl-flex align-items-center gap-5'>
					<Avatar
						shape='square'
						size={40}
						className='border-radius4 bd-none'
						src={lodash.get(record, 'orderImage', '')}
					/>
					<span className={`${classesMd} txt-color-orange2`}>{code}</span>
				</div>
			),
		},
		{
			title: t('availableOrders.totalPackages'),
			key: 'availablePackageCount',
			dataIndex: 'availablePackageCount',
			align: 'right' as 'right',
			width: 108,
			render: (availablePackageCount: string) => (
				<span className={classesMd}>{availablePackageCount}</span>
			),
		},
		{
			title: t('availableOrders.totalWeight'),
			align: 'right' as 'right',
			width: 135,
			render: (record: any) => {
				let weight = 0;
				record.packages.map((x: any) => {
					weight += x.actualWeight;
				});
				return <span className={classesRe}>{formatNumber(weight)}kg</span>;
			},
		},
		{
			title: t('availableOrders.needForPay'),
			key: 'needForPay',
			width: 168,
			align: 'right' as 'right',
			dataIndex: 'totalUnpaid',
			render: ( totalUnpaid: any) => {
				return (
					<span className={`${classesRe} ${totalUnpaid ? 'txt-color-red2' : ''}`}>
						{formatMoneyByUnit(totalUnpaid, currency)}
					</span>
				);
			},
		},
		{
			title: t('availableOrders.address'),
			key: 'display',
			width:526,
			ellipsis: true,
			render: (record: any) => {
				const address = lodash.get(record, 'address.location.display', '---');
				const detail = lodash.get(record, 'address.detail', '---');
				const fullname = lodash.get(record, 'address.fullname', '---');
				return <span className={classesRe}>{`${detail}, ${fullname}, ${address}`}</span>;
			},
		},
	];
	const columnsPackage = [
		{
			title: (
				<span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>
					{t('requestDeliveryPackage.code')}
				</span>
			),
			key: 'code',
			dataIndex: 'code',
			width: 250,
			render: (code: any) => (
				<span className='robotoregular fsz-14 line-h-22 txt-color-black'>{code}</span>
			),
		},
		{
			title: (
				<span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>
					{t('requestDeliveryPackage.createdAt')}
				</span>
			),
			key: 'createdAt',
			dataIndex: 'createdAt',
			width: 200,
			render: (createdAt: any) => (
				<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
					{formatDateTime(createdAt)}
				</span>
			),
		},
		{
			title: (
				<span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>
					{t('requestDeliveryPackage.volumetric')}
				</span>
			),
			key: 'volumetric',
			dataIndex: 'volumetric',
			width: 250,
			align: 'right' as 'right',
			render: (volumetric: any) => (
				<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
					{volumetric === 0
						? `${t('requestDeliveryPackage.undefined')}`
						: `${formatNumber(volumetric)}cm3`}
				</span>
			),
		},
		{
			title: (
				<span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>
					{t('requestDeliveryPackage.weight')}
				</span>
			),
			key: 'weight',
			dataIndex: 'actualWeight',
			align: 'right' as 'right',
			width: 250,
			render: (actualWeight: any) => (
				<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
					{`${formatNumber(actualWeight)}kg`}
				</span>
			),
		},
		{
			title: <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('packages.status')}</span>,
			key: 'status',
			dataIndex: 'status',
			render: (status: any) => {
				const statusObj = statuses.find(({ code }: any) => code === status);
				const statusName = lodash.isNull(lodash.get(statusObj, 'name'))
					? '---'
					: lodash.get(statusObj, 'name', '---');
				return (
					<span
						className='robotoregular fsz-12 line-h-20 txt-color-white border-radius25 py-3 px-12 whitespace'
						style={{
							backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
								? '#09B2AA'
								: lodash.get(statusObj, 'color'),
						}}>
						{statusName}
					</span>
				);
			},
		},
	];
	return (
		<div className='white-box dpl-flex flex-col gap-12' key={data.account.connection.id}>
			<div className='dpl-flex gap-12 align-items-center'>
				<Checkbox
					onChange={(e) => onSelectConnection(position, e.target.checked)}
					indeterminate={
						!!data.selectedOrders.length && data.selectedOrders.length < data.orders.length
					}
					checked={data.checked}
				/>
				<Supplier acc={data.account.connection} />
				{showBalance && <span
					className={`${
						data.account.balance < 0
							? 'bg-color-orange txt-color-pink4'
							: 'bg-color-blue6 txt-color-green'
					} robotoregular fsz-12 line-h-20 border-radius20 px-8`}>{`${
					data.account.balance > 0 ? '+' : ''
				}${formatMoneyByUnit(data.account.balance, currency)}`}</span>}
			</div>
			<Table
				dataSource={data.orders}
				rowKey={(record: any) => record.code}
				rowSelection={rowSelectionOrder}
				pagination={false}
				columns={columns}
				className={'main-table table-expandable selected-table'}
				expandable={{
					expandedRowRender: (record) => {
						return (
							<Table
								dataSource={record.packages}
								columns={columnsPackage}
								pagination={false}
								rowSelection={{
									selectedRowKeys: lodash.get(record, 'selectedPackages', []),
									hideSelectAll: true,
									onSelect: (item: any, selected: boolean, selectedRows: any) => {
										onSelectPackage(position, record.code, item.code, selected);
									},
								}}
								className='main-table'
								rowKey={(record: any) => record.code}
							/>
						);
					},
					expandIcon: ({ expanded, onExpand, record }) =>
						expanded ? (
							<i
								className='far fa-angle-down txt-color-orange2 w-8 line-h-22 cursor-pointer'
								onClick={(e) => onExpand(record, e)}></i>
						) : (
							<i
								className='far fa-angle-right txt-color-orange2 w-8 line-h-22 cursor-pointer'
								onClick={(e) => onExpand(record, e)}></i>
						),
						onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
				}}
				rowClassName={(record) =>
					lodash.includes(expandedRows, record.code)
						? 'active-row cursor-pointer'
						: 'data-row cursor-pointer'
				}
			/>
		</div>
	);
}
export default DeliveryConnection;