import {Spin} from "antd";
import React, {useContext, useState} from "react";
import {MessageList} from "./MessageList";
import {MessageInputBox} from "./MessageInputBox";
import {useTranslation} from "react-i18next";
import {BoxChatContext} from "../../context/BoxChatContext";

export const Message = () => {
    const [showAlert, setShowAlert] = useState(true)
    const {t} = useTranslation()
    let {messageLoading} = useContext(BoxChatContext)

    return (
        <Spin className={'box-chat-conversions-loading'} spinning={messageLoading}>
            <div className="box-chat-conversations">
                <div className={'box-chat-list-container'}>
                    {showAlert && (
                        <div className={'alert'}>
                            <div className="alert__inner">
                                <span className={'txt-size-h8 txt-color-primary mgr5'}><i className="far fa-info-circle" /></span>
                                <span className={'txt-color-black robotoregular fsz-12 mgr5'}>{t('chatBox.alertMessageFixed').replace('%s', '4-6h')}</span>
                                <span
                                    onClick={()=> setShowAlert(false)}
                                    className={'cursor-pointer txt-size-h8 txt-color-black'}
                                >
                                    <i className="fas fa-times" />
                                </span>
                            </div>
                        </div>
                    )}

                    <MessageList />

                    <MessageInputBox />
                </div>
            </div>
        </Spin>
    )
}
