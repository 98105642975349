import { Col, Image, Row, Table } from 'antd'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../../../core/helpers/date-time'
import { formatNumber } from '../../../../core/helpers/string'
import { categoryRepository } from '../../../../repositories/CategoryRepository'
import IconDrawFailedImg from '../../../../resources/images/empty-3.png'
import TracksPackage from './TracksPackage'
interface OrderItemsProps {
	packages: any
	loading: boolean
	orderDetail: any
}

const Packages: React.FC<OrderItemsProps> = (props) => {
	const [statuses, setStatuses] = useState([])
	const { packages, loading } = props
	const { t } = useTranslation()

	useEffect(() => {
		getStatuses()
	}, [])

	const getStatuses = () => {
		categoryRepository
			.getPackageStatuses()
			.then((res) => {
				setStatuses(res)
			})
			.catch((err) => {})
	}

	const packageInfoText = 'robotomedium txt-color-black fsz-12 line-h-20 whitespace'
	const columns = [
		{
			title: '#',
			key: 'index',
			width: '1%',
			render: (text: string, record: any, index: number) => (
				<span className={packageInfoText}>{++index}</span>
			),
		},
		{
			title: t('package.code'),
			key: 'code',
			dataIndex: 'code',
			width: '14%',
			render: (code: string) => <span className={packageInfoText}>{code}</span>,
		},
		{
			title: (
				<>
					<span className={`mg-r-4 ${packageInfoText}`}>{t('package.weight')}</span>
					<span className={`txt-color-gray4 robotoregular fsz-12 line-h-20 whitespace`}>{t('package.noteForWeight')}</span>
				</>
			),
			key: 'info',
			width: '28%',
			render: (record: any) => {
				const emptyTxt = '---'
				const actualWeight = lodash.isNull(lodash.get(record, 'actualWeight'))
					? emptyTxt
					: lodash.get(record, 'actualWeight', emptyTxt)
				const netWeight = lodash.isNull(lodash.get(record, 'netWeight'))
					? emptyTxt
					: lodash.get(record, 'netWeight', emptyTxt)
				const packagingWeight = lodash.isNull(lodash.get(record, 'packagingWeight'))
					? emptyTxt
					: lodash.get(record, 'packagingWeight', emptyTxt)
				const dimensionalWeight = lodash.isNull(lodash.get(record, 'dimensionalWeight'))
					? emptyTxt
					: lodash.get(record, 'dimensionalWeight', emptyTxt)
				return (
					<span className={packageInfoText}>{`${formatNumber(actualWeight)}kg / ${formatNumber(
						netWeight
					)}kg / ${formatNumber(packagingWeight)} kg / ${formatNumber(dimensionalWeight)}kg`}</span>
				)
			},
		},
		{
			title: (
				<>
					<span className='mg-r-4'>{t('package.details')}</span>
					<span className='txt-color-gray4'>{t('package.noteForDetail')}</span>
				</>
			),
			key: 'details',
			width: '12%',
			render: (record: any) => {
				const emptyTxt = '---'
				const length = lodash.isNull(lodash.get(record, 'length'))
					? emptyTxt
					: lodash.get(record, 'length', emptyTxt)
				const width = lodash.isNull(lodash.get(record, 'width'))
					? emptyTxt
					: lodash.get(record, 'width', emptyTxt)
				const height = lodash.isNull(lodash.get(record, 'height'))
					? emptyTxt
					: lodash.get(record, 'height', emptyTxt)
				return (
					<span className={packageInfoText}>{`${formatNumber(length)} x ${formatNumber(
						width
					)} x ${formatNumber(height)} (cm)`}</span>
				)
			},
		},
		{
			title: t('package.status'),
			key: 'notice',
			dataIndex: 'status',
			width: '14%',
			render: (status: string) => {
				const statusObj = statuses.find(({ code }) => code === status)
				const statusName = lodash.get(statusObj, 'name', '---')

				return (
					<span
						className={`robotoregular fsz-12 line-h-20 whitespace txt-color-white border-radius25 py-3 px-12`}
						style={{
							backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
								? '#09B2AA'
								: lodash.get(statusObj, 'color'),
						}}>
						{statusName}
					</span>
				)
			},
		},
		{
			title: t('package.update'),
			key: 'notice',
			dataIndex: 'lastStatusTime',
			render: (lastStatusTime: any) => (
				<span className={packageInfoText}>
					{lodash.isNull(lastStatusTime)
						? t('orderDetail.undefined')
						: formatDateTime(lastStatusTime)}
				</span>
			),
		},
	]

	const localeCustom = {
		emptyText: (
			<>
				{!props.loading ? (
					<Row justify='center' className='mg-bt-8 mg-t-7' gutter={[0, 6]}>
						<Col lg={24} className='dpl-flex justify-content-center'>
							<Image preview={false} src={IconDrawFailedImg} />
						</Col>
						<Col lg={24} className='text-disabled dpl-flex justify-content-center'>
							<span className='fsz-14 robotoregular txt-color-black'>
								{t('orderDetail.packagesEmpty')}
							</span>
						</Col>
					</Row>
				) : null}
			</>
		),
	}

	const components = { body: { row: TracksPackage } }
	const total = packages.length
	return (
		<Table
			components={components}
			rowKey={(record: any) => record.id}
			columns={columns}
			dataSource={packages}
			locale={localeCustom}
			loading={loading}
			className={`package-list`}
			pagination={false}
			size='small'
			onRow={(record, index) => {
				return { ...record, total, index }
			}}
		/>
	)
}
export default Packages