import {Col, Tabs} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {TAB_PRODUCT, TAB_TICKET, TAB_TRANSACTION, TAB_TRANSPORT} from '../../../core/config'
import {orderRepository} from '../../../repositories/OrderRepository'
import Packages from './Packages'
import Products from './Products'
import Tickets from './Tickets'
import Transactions from './Transactions'

const { TabPane } = Tabs
interface TabProps {
	items: any
	exchangeRate: number
	currency: string
	loading: boolean
	orderDetail: any
	finalcials: any
	confirmProductChange: (code: string, id: string, confirm: boolean, successInfo: string) => void
	loadingNotConfirm: { [id: string]: boolean } | undefined
	loadingConfirm: { [id: string]: boolean } | undefined
	handleConfirm: (val: boolean) => void,
	claim: any,
    onClaimPageChange: (filter: any) => void,
	providerCode: string | undefined,
	accountCode: string | undefined
}
interface PropsProductTab {
	label: string
	total?: number
	isActive: boolean
}
const CustomerTabTitle: React.FC<PropsProductTab> = ({ label, total, isActive }) => {
	return (
		<span className='px-12 pd-bt-6 dpl-flex align-items-center'>
			<span className={`mgr4 fsz-16 line-h-24 ${isActive ? 'robotomedium' : 'robotoregular'}`}>
				{label}
			</span>
			<span
				className={`dpl-flex align-items-center justify-content-center robotoregular line-h-20 txt-color-black px-8 pd-t-1 w-23 mg-bt-2 border-radius20 ${
					isActive ? 'bg-color-pink1' : 'bg-color-gray-tab'
				}`}>
				{total}
			</span>
		</span>
	)
}

const TabLayout: React.FC<TabProps> = (props) => {
	const [packages, setPackages] = useState([])
	const [loadingPackages, setLoadingPackages] = useState(false)
	const { t } = useTranslation()
	let { code } = useParams()
	const {orderDetail,items,exchangeRate,currency,loading,finalcials,confirmProductChange,loadingNotConfirm,loadingConfirm} = props
	const { handleConfirm, claim, onClaimPageChange, providerCode, accountCode } = props
	const [currentTabKey, setCurrentTabKey] = useState('TAB_PRODUCT')
	const handleChangeTab = (activeKey: string) => {
		setCurrentTabKey(activeKey)
	}

	const getPackages = useCallback(() => {
		setLoadingPackages(true)
		orderRepository
			.getPackages(code)
			.then((res) => {
				setPackages(res)
			})
			.catch((err) => {})
			.finally(() => setLoadingPackages(false))
	}, [code])

	useEffect(() => {
		getPackages()
	}, [getPackages])

	return (
		<Col
			span={24}
			className='pd-12 bg-color-white box-order gap-8 mgt12 txt-color-gray fsz-16 robotoregular border-radius4'>
			<Tabs defaultActiveKey={code ? code : TAB_PRODUCT} onChange={handleChangeTab}>
				<TabPane
					tab={
						<CustomerTabTitle
							label={t('orderDetail.tabProduct')}
							total={items.length}
							isActive={currentTabKey === 'TAB_PRODUCT'}
						/>
					}
					key={TAB_PRODUCT}>
					<Products
						loadingNotConfirm={loadingNotConfirm}
						loadingConfirm={loadingConfirm}
						confirmProductChange={confirmProductChange}
						handleConfirm={handleConfirm}
						items={items}
						exchangeRate={exchangeRate}
						currency={currency}
						loading={loading}
					/>
				</TabPane>
				<TabPane
					tab={
						<CustomerTabTitle
							label={t('orderDetail.tabPackages')}
							total={packages.length}
							isActive={currentTabKey === 'TAB_TRANSPORT'}
						/>
					}
					key={TAB_TRANSPORT}>
					<Packages packages={packages} loading={loadingPackages} orderDetail={orderDetail} />
				</TabPane>
				<TabPane
					tab={
						<CustomerTabTitle
							label={t('orderDetail.tabTicket')}
							total={ claim.total || 0 }
							isActive={currentTabKey === 'TAB_TICKET'}
						/>
					}
					key={TAB_TICKET}>
					<Tickets
                        {...claim}
                        onPageChange={ onClaimPageChange }
						providerCode={ providerCode }
						accountCode={ accountCode }
						orderCode={ orderDetail?.code }
                    />
				</TabPane>
				<TabPane
					tab={
						<CustomerTabTitle
							label={t('orderDetail.tabTransaction')}
							total={finalcials.length}
							isActive={currentTabKey === 'TAB_TRANSACTION'}
						/>
					}
					key={TAB_TRANSACTION}>
					<Transactions dataSource={finalcials} />
				</TabPane>
			</Tabs>
		</Col>
	)
}

export default TabLayout
