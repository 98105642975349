import {PlusOutlined} from '@ant-design/icons';
import {Avatar, Button, Card, Image, Modal, Radio, Row, Switch, Table} from 'antd';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ModalAddSupplier from "../../components/ConnectSupplier/ModalAddSupplier";
import MainLayout from "../../components/Layout";
import {TENANT_KEY} from "../../core/config";
import {categoryRepository} from "../../repositories/CategoryRepository";
import {supplierRepository} from "../../repositories/SupplierRepository";
import empty from '../../resources/images/empty-blue.png';
import {localStorageRead} from "../../utils/LocalStorageUtils";
import M24ErrorUtils from "../../utils/M24ErrorUtils";
import M24Notification from "../../utils/M24Notification";
import {newLineToBr} from '../../utils/StringUtils';
import './styles.scss';
import EmptyData from '../../components/EmptyData';
import lodash from "lodash";
import {formatDateTime} from "../../core/helpers/date-time";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import {formatMoneyBySuffix} from "../../core/helpers/string";
import ConnectFromM1DialogConfirm from "../../components/ConnectSupplier/ConnectFromM1Confirm";


function ConnectSupplier() {
	const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isShowModalAdd, setShowModalAdd] = useState<boolean>(false);
	const [domain, setDomain] = useState<any>(null);
	const { t } = useTranslation();
	const [accounts, setAccounts] = useState<any[]>([]);
	const [statues, setStatues] = useState<any[]>([]);
	const [showConfirm, setShowConfirm] = useState<boolean>(false);
	const [typeConfirm, setTypeConfirm] = useState<string>('');
	const [itemSelected, setSelected] = useState<any>({});
	const [isReconnect, setIsReconnect] = useState<any>(false);

	const [isConnectingFromM1, setIsConnectingFromM1] = useState<boolean>(false);
	const [connectionParams, setConnectionParams] = useState<any>({});
	const [currentConfigShowBalance, setCurrentConfigShowBalance] = useState(lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', false));
    const [showFaq, setShowFaq] = useState(false);
	const [faqs, setFaqs] = useState('');
	const [usernameUseInFaqs, setUsernameUseInFaqs] = useState('');
    const [providerCode, setProviderCode] = useState('');

    useEffect(() => {
        getStatuses();
        getData();

        const hash = window.location.hash
        let params = new URLSearchParams(hash.substring(hash.indexOf('?')));
        let data = {
            token: params.get('token'),
            username: params.get('username'),
            tenant: params.get('tenant'),
        }

        if(data.token) {
            setConnectionParams(data);
            setIsConnectingFromM1(true);
        }
    }, [])

	const getData = async() => {
		setShowProgressBar(true);
		const providers = await categoryRepository.getProvider();
		supplierRepository
			.getListAccount()
			.then((res) => {
				let items = lodash
					.chain(res)
					// Group the elements of Array based on `color` property
					.groupBy('provider.domain')
					// `key` is group's name (color), `value` is the array of objects
					.map((value, key) => {
						let group = value[0].provider;
						return { ...group, accounts: value };
					})
					.value();
				const providerCode = lodash.map(providers, 'code');
				const providerCodeFromItems = lodash.map(items, 'code');
				const providerAccountsEmpty = lodash.difference(
					providerCode,
					providerCodeFromItems
				);
				for (let i = 0; i < providerAccountsEmpty.length; i++) {
					const group = providers.find(
						(x: any) => x.code === providerAccountsEmpty[i]
					);
					items = [...items, { ...group, accounts: [] }];
				}
				setAccounts(items);
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err);
			})
			.finally(() => setShowProgressBar(false));
	};
	const getStatuses = () => {
		categoryRepository
			.getConnectionsStatus()
			.then((res) => {
				setStatues(res);
			})
			.catch((err) => {});
	};
	const onShowModalAdd = () => {
		setShowModalAdd(!isShowModalAdd);
		// if (isShowModalAdd) setDomain('');
	};
	const handleAddAccount = (body: any) => {
		setLoading(true);
		supplierRepository
			.addAccount(body)
			.then((res) => {
				setShowModalAdd(false);
				M24Notification.messageSuccess(t('message.success'));
				getData();
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err);
			})
			.finally(() => setLoading(false));
	};

	const onCancelConfirm = () => {
		setShowModalAdd(false);
		if (isShowModalAdd) setDomain('');
		setSelected({});
		setTypeConfirm('');
		setShowConfirm(false);
		setIsReconnect(false);
	};

	const onCancelConnectFromM1 = () => {
		setIsConnectingFromM1(false);
	};
	const onSubmitConnectFromM1 = () => {
		setLoading(true);
		let body: any = { tokenFromM1: connectionParams.token };
		supplierRepository
			.verifyConnectionFromM1(
				connectionParams.username,
				connectionParams.tenant,
				body
			)
			.then((response) => {
				M24Notification.messageSuccess(t('message.success'));
				getData();
			})
			.catch((error) => {
				M24ErrorUtils.showError(t, error);
			})
			.finally(() => {
				setLoading(false);
				onCancelConnectFromM1();
			});
	};

	const getBalance = (domain: string, connectionId: any) => {
		supplierRepository
			.getBalance(connectionId)
			.then((res) => {
				let items = lodash.cloneDeep(accounts);
				items.map((xx) => {
					if (xx.domain === domain) {
						xx.accounts.map((x: any) => {
							if (x.id === connectionId) x.balance = res.balance;
						});
					}
				});
				setAccounts(items);
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err);
			});
	};

	const onSubmitConfirm = () => {
		setLoading(true);
		let body: any = {};
		if (typeConfirm === 'DEFAULT') {
			body.defaultConnection = true;
		} else if (typeConfirm === 'CONNECT') {
			body.status = 'CONNECTED';
		} else if (typeConfirm === 'DISCONNECT') {
			body.status = 'DISCONNECTED';
		} else if (typeConfirm === 'DELETE') {
		}
		supplierRepository
			.updateAccount(itemSelected.id, body)
			.then((res) => {
				M24Notification.messageSuccess(t('message.success'));
				getData();
				onCancelConfirm();
				// getListAccountAll();
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err);
			})
			.finally(() => setLoading(false));
	};

	const onReconnectSubmit = (body: any) => {
		setLoading(true);
		let obj: any = { password: body.password, secret: body.secret };
		supplierRepository
			.reconnectSupplier(itemSelected.id, obj)
			.then((res) => {
				M24Notification.messageSuccess(t('message.success'));
				onCancelConfirm();
				getData();
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err);
			})
			.finally(() => {
				onCancelConfirm();
				setLoading(false);
			});
	};

    const handleShowFaqsChargeCash = (provider: string, username: string) => {
		setShowFaq(true)
		setUsernameUseInFaqs(username)
		getFaqs(provider)
	}

    const getFaqs = async (provider: string) => {
		try {
			const res = await categoryRepository.getFaqs(provider);
			setFaqs(lodash.get(res, 'content', ''));
		} catch (err) {
			M24ErrorUtils.showError(t, err);
		}
	};


	const onSubmitAccountNotExist =  (body: any, provider: string) => {
			setLoading(true);
	 		supplierRepository.registerAccount(body, provider).then(res => {
				setShowModalAdd(false);
				M24Notification.messageSuccess(t('message.success'));
				getData();
			})
			.catch((err) =>
				M24Notification.notifyError(
					t('message.titleFailed'),
					t(`message.${lodash.get(err, 'response.data.title')}`),
					'_notify-error',
					5
				)
			)
			.finally(() => {
				setLoading(false);
			});
	};

	const renderItem = (item: any) => {
		let currencyItem = JSON.parse(item.currency);
		const columns = [
			{
				title: t('supplier.username'),
				dataIndex: 'name',
				key: 'name',
				width: 200,
				render: (text: any, record: any) => (
					<span className={'txt-size-h7 txt-color-black robotoregular'}>
						{text}
					</span>
				),
			},
			{
				title: t('supplier.createdAt'),
				dataIndex: 'createdAt',
				key: 'createdAt',
				render: (text: any, record: any) => (
					<span className={'txt-size-h7 txt-color-black robotoregular'}>
						{formatDateTime(text)}
					</span>
				),
			},
			{
				title: t('supplier.balance'),
				dataIndex: 'balance',
				className: 'txt-right',
				key: 'balance',
				width: 200,
				render: (value: any, record: any) => (
					<Row className={'align-items-center justify-content-end'}>
						<span className={'txt-size-h7 txt-color-black robotoregular mgr5'}>
							{record.balance != null && record.balance != undefined
								? formatMoneyBySuffix(
										record.balance,
										lodash.get(currencyItem, 'prefix', ''),
										lodash.get(currencyItem, 'suffix', '')
								  )
								: '********'}
						</span>
						<span
							onClick={() => {
								if (
									record.status === 'CONNECTED' &&
									(record.balance == null || record.balance == undefined)
								)
									getBalance(item.domain, record.id);
							}}
							className={`txt-size-h3 ${
								record.status === 'CONNECTED' &&
								(record.balance == null || record.balance == undefined)
									? 'txt-color-orange cursor-pointer'
									: 'txt-color-gray cursor-nodrop'
							}`}>
							<i className='fas fa-eye'></i>
						</span>
					</Row>
				),
			},
			{
				title: t('supplier.status'),
				dataIndex: 'status',
				key: 'status',
				width: 250,
				render: (text: any, record: any) => {
					let status = statues.find((x) => x.code === record.status);
					if (record.status !== 'INVALID')
						return (
							<Row className={'align-items-center'}>
								<Switch
									onChange={(checked) => {
										setSelected(record);
										setTypeConfirm(checked ? 'CONNECT' : 'DISCONNECT');
										setShowConfirm(true);
									}}
									checked={record.status === 'CONNECTED'}
								/>
								<span
									className={'txt-size-h7 txt-color-black robotoregular mgl10'}>
									{lodash.get(status, 'name', text)}
								</span>
							</Row>
						);
					else
						return (
							<Row className={''}>
								<span className={'txt-size-h7 txt-color-black robotoregular'}>
									<i className='fal fa-exclamation-triangle mgr5 txt-color-red' />
									{t('supplier.invalid-connection-error')}
								</span>
								<a
									className={
										'txt-size-h7 txt-color-black robotoregular mgl10 align-item-center'
									}
									title={t('supplier.reconnect')}
									onClick={() => {
										setSelected(record);
										setIsReconnect(true);
										setShowModalAdd(true);
									}}>
									<i className='fad fa-sync-alt mgr5' />
								</a>
							</Row>
						);
				},
			},
			{
				title: t('supplier.actionDefault'),
				dataIndex: 'defaultConnection',
				key: 'defaultConnection',
				render: (text: any, record: any) => (
					<Row className={'align-items-center'}>
						<Radio
							onChange={(e) => {
								setSelected(record);
								setTypeConfirm('DEFAULT');
								setShowConfirm(true);
							}}
							disabled={record.status !== 'CONNECTED'}
							checked={record.defaultConnection}>
							{t('supplier.accountDefault')}
						</Radio>
					</Row>
				),
			},
			{
				title: t('supplier.action'),
				dataIndex: 'action',
				key: 'action',
				render: (text: any, record: any) => (
					<Row className={'align-items-center'}>
						<Row className={'cursor-pointer'}>
							<span
								className={
									'txt-size-h7 txt-color-black robotoregular mgl10 btn-remove'
								}
								onClick={() => {
									setSelected(record);
									setTypeConfirm('DELETE');
									setShowConfirm(true);
								}}>
								<i className='fal fa-trash mgr5' />
								{t('supplier.actionDelete')}
							</span>
						</Row>
					</Row>
				),
			},
		];
		return (
			<Card
				key={item.code}
				headStyle={{ alignItems: 'center', paddingTop: 0 }}
				className={'border-card-header mgt12 supplier-card'}
				title={
					<Row className={'justify-content-between align-items-center'}>
						<Row className={'mgbt16mb'}>
							<Avatar src={item.logo} className='bd-none-impt'/>
							<span className={'txt-size-h5 robotomedium mgl3 mgt5'}>
								{item.name}
							</span>
						</Row>
						<Row align='middle'>
							{item.accounts.length > 0 && <Button
								className='robotoregular txt-color-black btn-df btn-df-tertiary mg-r-12 fsz-14 line-h-22 py-4 px-12'
								onClick={() =>
									handleShowFaqsChargeCash(
										lodash.get(item.accounts[0], 'provider.code', ''),
										lodash.get(item.accounts[0], 'name', '')
									)
								}>
								{t('supplier.faq_charge_cash')}
							</Button>}
							<Button
								onClick={() => {
									setDomain(item.domain);
									onShowModalAdd();
									setProviderCode(item.code)
								}}
								icon={<i className='far fa-plus mg-r-10' />}
								className={'btn-df btn-df-secondary-bg-white fsz-14 line-h-22 py-5 px-16'}
								ghost>
								{t('supplier.btAddAccount')}
							</Button>
						</Row>
					</Row>
				}>
				<Table
					rowKey={(record: any) => record.id}
					dataSource={item.accounts}
					columns={
						!currentConfigShowBalance
							? columns.filter(({ key }) => key !== 'balance')
							: columns
					}
					pagination={false}
					className='main-table'
					locale={{
						emptyText: (
							<EmptyData canAddRecord={true}>
								<div className='dpl-flex flex-col align-items-center gap-6'>
									<Image preview={false} className={'icon-empty'} src={empty} />
									<span>{t('supplier.empty')}</span>
									<Button
										onClick={() => {
											setDomain(item.domain);
											onShowModalAdd();
											setProviderCode(item.code);
										}}
										icon={<i className='far fa-plus mg-r-10' />}
										className={
											'btn-df btn-df-secondary-bg-white fsz-14 line-h-22 py-5 px-16'
										}
										ghost>
										{t('supplier.btAddAccount')}
									</Button>
								</div>
							</EmptyData>
						),
					}}
				/>
			</Card>
		);
	};

	const formatTemplateFaqs = () => {
		const re = /“(.*?)”/g;
		const reStep = /\d\.(.*?)\n/gm;
        let result = []
        let resultForStep = []
		let current;
        let txtRendered = ''
		while ((current = re.exec(faqs))) {
			result.push(current.pop());
		}
		while ((current = reStep.exec(faqs))) {
			resultForStep.push(current.shift());
		}
		const boldTextFind: any = result.length > 0 ? result : [];
        const stepNeedBold: any = resultForStep.length > 0 ?  resultForStep : []
        txtRendered = faqs.split(boldTextFind[0]).join(`<span class='robotomedium'>${boldTextFind[0]}</span>`)
        for(let i = 0; i < stepNeedBold.length; i++) {
            txtRendered = txtRendered.split(stepNeedBold[i]).join(`<span class='robotomedium'>${stepNeedBold[i]}</span>`)
        }
        return `<span class='robotoregular txt-color-black fsz-14 line-h-22'>${newLineToBr(txtRendered.split('{username}').join(usernameUseInFaqs))}</span>`
	};

	return (
		<MainLayout
			title={t('menu.connectSupplier')}
			showProgressBar={showProgressBar}>
			<Card className={'border-card-header supplier-card-head'}>
				<span
					className={
						'title-body txt-color-black robotomedium fsz-16 line-h-22'
					}>
					{t('supplier.list')}
				</span>
				<Row>
					<span className={'txt-size-h7 txt-color-gray4'}>
						{t('dashboard.welcome')}
					</span>
				</Row>
				{/* <Button onClick={onShowModalAdd} icon={<PlusOutlined style={{fontSize: 15}}/>} className={'mgt20 pdt5'}
                    type={'primary'}>{t('supplier.btAddAccount')}</Button> */}
			</Card>
			{accounts?.length > 0 ? (
				<div>
					{accounts.map((x: any) => {
						return renderItem(x);
					})}
				</div>
			) : !showProgressBar ? (
				<div style={{ marginTop: 200 }}>
					<Row className={'justify-content-center'}>
						<Image preview={false} className={'icon-empty'} src={empty} />
					</Row>
					<Row className={'mgt10 justify-content-center'}>
						<span>{t('supplier.empty')}</span>
					</Row>
					<Row className={'justify-content-center'}>
						<Button
							onClick={onShowModalAdd}
							icon={<PlusOutlined style={{ fontSize: 15 }} />}
							className={'mgt20'}
							type={'primary'}
							ghost>
							{t('supplier.btAddAccount')}
						</Button>
					</Row>
				</div>
			) : null}

			{isShowModalAdd && (
				<ModalAddSupplier
					onSubmitAccountNotExist={onSubmitAccountNotExist}
					domain={domain}
					isLoading={isLoading}
					onSubmit={handleAddAccount}
					isVisible={isShowModalAdd}
					selectedSupplier={itemSelected}
					isReconnect={isReconnect}
					onReconnectSubmit={onReconnectSubmit}
					onCancel={onCancelConfirm}
					provider={providerCode}
				/>
			)}
			{showConfirm && (
				<ConfirmModal
					visible={showConfirm}
					loading={isLoading}
					content={
						typeConfirm === 'DELETE'
							? t('supplier.confirmDelete')
							: typeConfirm === 'DEFAULT'
							? t('supplier.confirmDefault')
							: typeConfirm === 'CONNECT'
							? t('supplier.confirmConnect')
							: t('supplier.confirmDisConnect')
					}
					onSubmit={onSubmitConfirm}
					onCancel={onCancelConfirm}
				/>
			)}

			{isConnectingFromM1 && (
				<ConnectFromM1DialogConfirm
					isVisible={isConnectingFromM1}
					isLoading={isLoading}
					onCancel={onCancelConnectFromM1}
					onSubmit={onSubmitConnectFromM1}
					connectionParams={connectionParams}
				/>
			)}
			<Modal
				title={
					<span className='robotoregular txt-color-black fsz-16 line-h-24'>
						{t('supplier.faqsCashCharged')}
					</span>
				}
                onCancel={() => setShowFaq(false)}
				visible={showFaq}
				className='pd-12 faq-modal'
				closeIcon={
					<i className='far fa-times' onClick={() => setShowFaq(false)} />
				}
                  bodyStyle={{ overflowY: 'scroll' }}
				footer={[
					<Button
						key='submit'
						type='primary'
						onClick={() => setShowFaq(false)}
						className='btn-df btn-df-secondary-bg-white px-12 py-5 robotoregular  fsz-14 line-h-22'>
						{t('supplier.closeFaqs')}
					</Button>,
				]}>
				<span
					className='white-space-pre-ln'
					dangerouslySetInnerHTML={{
						__html: formatTemplateFaqs(),
					}}></span>
			</Modal>
		</MainLayout>
	);
}

export default ConnectSupplier;

