import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, notification,} from "antd";
import MainLayout from "../../components/Layout";
import {useNavigate, useSearchParams} from "react-router-dom";
import ClaimFilter from "../../components/Claim/ClaimFilter";
import ClaimList from "../../components/Claim/ClaimList";
import M24Pagination from "../../components/Pagination/M24Pagination";
import {supplierRepository} from "../../repositories/SupplierRepository";
import {claimRepository} from "../../repositories/ClaimRepository";
import lodash from "lodash";
import {DEFAULT_PAGE_SIZE} from "../../core/config";

function Claims() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [ticketList, setTicketList] = React.useState<any[]>([]);
    const [pagination, setPagination] = React.useState<any>({});
    const [selectedAccount, setSelectedAccount] = React.useState<any>({});
    const [providerAccounts, setProviderAccounts] = React.useState<any[]>([])
    const [ticketStatuses, setTicketStatuses] = React.useState<any[]>([]);
    const [ticketSolutions, setTicketSolutions] = React.useState<any[]>([])
    const [total, setTotal] = React.useState<number>();
    const [showProgressBar, setShowProgressBar] = React.useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams();

    const openCreateTicketForm = () => {
        navigate(`/claims/create`)
    }

    const handleChangePage = (filter: any) => {
        const newFilter = {
            ...filter,
            offset: (filter.page - 1) * filter.pageSize,
            limit: filter.pageSize
        }
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            ...newFilter
        })
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    React.useEffect(() => {
        const getProviderAccounts = () => {
            supplierRepository.getListAccount().then(response => {
                setProviderAccounts(response);
                let temp = response.filter((x: any) => x.status === 'CONNECTED');
                if (temp && temp.length > 0) {
                    let defaultAcc = response.find((x: any) => x.defaultConnection);
                    if (defaultAcc) setSelectedAccount(defaultAcc)
                    else setSelectedAccount(temp[0]);
                }
            })
        }
        getProviderAccounts();
    }, [])

    React.useEffect(() => {
        if (selectedAccount.id) {
            const getTicketStatuses = () => {
                claimRepository.getClaimStatuses(selectedAccount?.provider.code).then((response) => {
                    setTicketStatuses(response);
                })
            }
            getTicketStatuses();
            const getTicketSolutions = () => {
                claimRepository.getSolutions(selectedAccount?.provider.code).then((response) => {
                    setTicketSolutions(response);
                })
            }
            getTicketSolutions();
        }
    }, [selectedAccount])


    React.useEffect(() => {
        if (selectedAccount.id) {
            const accountCode = searchParams.get('accountCode') || selectedAccount.name
            const providerCode = searchParams.get('providerCode') || selectedAccount?.provider?.code
            const getTickets = (filter?: any) => {
                setShowProgressBar(true);
                setLoading(true)
                filter = {
                    ...filter,
                    sort: 'createdAt',
                    order: 'DESC'
                }
                claimRepository.getClaims(providerCode, accountCode , filter).then((response) => {
                    let currentPage = lodash.get(response, 'headers.x-page-number');
                    let total = lodash.get(response, 'headers.x-total-count');
                    setTotal(total);
                    setTicketList(response.data);
                    setPagination({
                        current: parseInt(currentPage) + 1,
                        hideOnSinglePage: true,
                        total: parseInt(total),
                        pageSize: parseInt(lodash.get(response, 'headers.x-page-size'))
                    })
                })
                    .catch(() => {
                        notification.error({
                            message: t('tickets.error_load_list')
                        })
                        setTotal(0)
                        setTicketList([])
                        setPagination({
                            current: 1,
                            hideOnSinglePage: true,
                            total: 0,
                            pageSize: 25
                        })
                    })
                    .finally(() => {
                    setShowProgressBar(false)
                    setLoading(false)
                })
            }
            getTickets(Object.fromEntries(searchParams.entries()))
        }
    }, [searchParams, selectedAccount])

    return (
        <MainLayout title={t('menu.tickets')} showProgressBar={showProgressBar}>
            <div className={'ticket-container'}>
                <ClaimFilter
                    providerAccounts={providerAccounts}
                    selectedAccount={selectedAccount}
                    ticketStatuses={ticketStatuses}
                    ticketSolutions={ticketSolutions}
                />
                <div className={'border-card-header border-radius4 pd12'}>
                    <div className={'dpl-flex align-item-center justify-content-between'}>
                    <span className={'dpl-flex'}>
                        <span
                            className={'fsz-16 robotomedium txt-color-black self-center'}>{t('menu.tickets')}</span>
                        <span className={!total ? 'c-badge-no-data' : 'c-badge'}>
                            <span className={!total ? 'c-badge-num-no-data' : 'c-badge-num'}>{total || 0}</span>
                        </span>
                    </span>
                        <Button className={'create-ticket-btn'} onClick={openCreateTicketForm}>
                            <i className="far fa-plus pdr5"/>
                            <span
                                className={'txt-size-h7 robotomedium txt-capitalize'}>{t('tickets.createTickets')}</span>
                        </Button>
                    </div>
                    <ClaimList
                        loading={loading}
                        data={ticketList}
                        ticketStatuses={ticketStatuses}
                        providerAccounts={providerAccounts}
                    />
                    <M24Pagination
                        filter={{
                            ...Object.fromEntries(searchParams.entries()),
                            size: searchParams.has('size') ? parseInt(searchParams.get('size') || DEFAULT_PAGE_SIZE.toString()) : DEFAULT_PAGE_SIZE
                        }}
                        pagination={pagination}
                        onPageChange={handleChangePage}
                        className={'mgt10'}
                    />
                </div>
            </div>

        </MainLayout>
    );
}

export default Claims;
