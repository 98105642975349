import {Button, Col, Grid, Image, Row, Table, Typography} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import IconDrawFailedImg from '../../../resources/images/empty-3.png'
import {formatMoneyByUnit} from '../../../core/helpers/string'
import {formatDateTime} from '../../../core/helpers/date-time'
import moment from 'moment'
import M24Pagination from "../../Pagination/M24Pagination";
import {ClaimInterface} from '../../../Interface/ClaimInterface'
import {getUrlPathname} from "../../../utils/util";

const { Text } = Typography
const { useBreakpoint } = Grid

// VNK3BG00JPP: ma nay nhieu khieu nai

interface IProps {
    isLoading: boolean,
    claimList: ClaimInterface[],
    total: null | number,
    pageSize: null | number,
    currentPage: number,
    onPageChange: (filter: any) => void,
    providerCode: string | undefined,
    accountCode: string | undefined
    orderCode: string
}

const getClaimDetailLink = (
    providerCode: string | undefined, 
    accountCode: string | undefined,
    orderCode: string,
    claimCode: string
): string => {
    if(providerCode && accountCode) {
        return `/claims/${providerCode}/${accountCode}/${orderCode}/${claimCode}`
    }
    return ''
}

const Tickets: React.FC<IProps> = (props) => {

    const classes = {
        txtBlue: 'txt-color-blue1',
        tHeadFont: 'robotomedium txt-size-h7',
        statusDot: 'status-dot mg-bt-1',
        regularFontSize: 'txt-size-h7 robotoregular',
        paginationSpacing: 'mgt0 mgbt0',
        outlinedBtn: 'btn-outlined-orange txt-capitalize border-radius4',
        txtBlack: 'txt-color-black',
        txtOrange: 'txt-color-orange2',
        createdClaimBtn: 'txt-size-h7 robotoregular btn-outlined-orange btn-create-claim-by-order txt-capitalize border-radius4 px-16 py-5'
    }

    const screen = useBreakpoint()
    const isGreaterThanSm = screen.md || screen.lg || screen.xl || screen.xxl

    const {t} = useTranslation();
    let {code} = useParams();
    const localeCustom = {
        emptyText: (
            <>
                {
                    !props.isLoading &&
                        <Row justify='center' className='mg-bt-8 mg-t-7' gutter={[0, 6]}>
                            <Col lg={24} className='dpl-flex justify-content-center'>
                                <Image preview={false} src={IconDrawFailedImg} />
                            </Col>
                            <Col lg={24} className='text-disabled dpl-flex justify-content-center'>
                                <span className='fsz-14 robotomedium txt-color-black'>
                                    {t('orderDetail.emptyClaims')}
                                </span>
                            </Col>
                        </Row>
                }
            </>
		),
	}

    const columns = [
        {
            title: <Text className={ classes.tHeadFont }>{ t('orderDetail.claimCode')}</Text>,
            dataIndex: "code",
            key: "code",
            render: (_: string, record: ClaimInterface) => (
                <Text className={`${classes.regularFontSize} ${classes.txtOrange}`}>
                    #{ record.code }
                </Text>
            )
        }, {
            title: <Text className={ classes.tHeadFont }>{ t('orderDetail.claimName') }</Text>,
            dataIndex: "name",
            key: "name",
            width: isGreaterThanSm ? `${180 / 924 * 100}%` : "auto",
            render: (_: string, record: ClaimInterface) => (
                <Text className={`${classes.txtBlack} ${classes.regularFontSize}`}>
                    { record.name }
                </Text>
            )
        }, {
            title: <Text className={ classes.tHeadFont }>{ t('orderDetail.claimCreatedAt') }</Text>,
            dataIndex: "createdAt",
            key: "createdAt",
            render: (_: string, record: ClaimInterface) => (
                <Text className={ `${classes.txtBlack} ${classes.regularFontSize}`}>
                    { formatDateTime(moment(record.createdAt)) }
                </Text>
            )
        }, {
            title: <Text className={ classes.tHeadFont }>{ t('orderDetail.claimStatus') }</Text>,
            dataIndex: "status",
            key: "status",
            render: (_: string, record: ClaimInterface) => (
                <>
                    <span 
                        className={ classes.statusDot }
                        style={{ backgroundColor: record.publicState.color }}
                    /> &nbsp; &nbsp;
                    <Text className={ `${classes.txtBlack} ${classes.regularFontSize}`}>
                        { record.publicState.name }
                        { record.archived && ` (${t('tickets.archived')})` }
                    </Text>
                </>
            )
        }, {
            title: <Text className={ classes.tHeadFont }>{ t('tickets.resolvesSuggestion') }</Text>,
            dataIndex: "solution",
            key: "solution",
            render: (_: string, record: ClaimInterface) => (
                <Text className={`${classes.txtBlack} ${classes.regularFontSize}`}>{ record.solution?.name }</Text>
            )
        }, {
            title: <Text className={ classes.tHeadFont }>{ t('orderDetail.totalRefund') }</Text>,
            dataIndex: "totalRefund",
            key: "totalRefund",
            width: isGreaterThanSm ? `${235 / 924 * 100}%` : "auto",
            render: (_: string, record: ClaimInterface) => (
                <Row 
                    justify="space-between"
                    align="middle"
                >
                    <Col>
                        <Text className={`${classes.regularFontSize } ${classes.txtBlack}`} >
                            { formatMoneyByUnit(record.estimatedRefundValue) }
                        </Text>
                    </Col>
                    <Col>
                        <Link 
                            to={ getClaimDetailLink(props.providerCode, props.accountCode, props.orderCode, record.code) }
                            className={`${classes.regularFontSize} ${classes.txtBlue}`}
                        >
                            { t('orderDetail.claimDetail') }
                        </Link>
                    </Col>
                </Row>
            )
        }
    ]

    const dataSource = props.claimList.map(claim => ({...claim, key: `${code}_${claim.code}`}))

    return (
        <Row gutter={[12, 12]}>
            <Col xs={ 24 }>
                <Table
                    className="order-claims-list"
                    locale={localeCustom}
                    loading={ props.isLoading }
                    columns={ columns }
                    dataSource={ dataSource }
                    pagination={ false }
                />
            </Col>
            {
                props.claimList.length > 0 &&
                    <Col xs={ 24 }>
                        <M24Pagination
                            className={ classes.paginationSpacing }
                            filter={{
                                size: props.pageSize,
                                page: props.currentPage
                            }}
                            pagination={{
                                total: props.total,
                                current: props.currentPage,
                                pageSize: props.pageSize,
                                hideOnSinglePage: true
                            }}
                            onPageChange={ props.onPageChange }
                        />
                    </Col>
            }
            <Col xs={ 24 }>
                <Row justify="center">
                    <Button className={ classes.createdClaimBtn }>
                    <i className="fa-solid fa-plus"></i> &nbsp; &nbsp;
                        <Link to={`/claims/create?orderCode=${props.orderCode}&providerCode=${props.providerCode}&customerCode=${props.accountCode}&back=${getUrlPathname(window.location)}`}>{ t('tickets.createTickets') }</Link>
                     </Button>
                </Row>
            </Col>
        </Row>
    )
}
export default Tickets;
