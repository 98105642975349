import { Avatar, Button, Card, Checkbox, Col, Divider, List, Row, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import lodash from "lodash";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { formatMoneyByUnit } from "../../../core/helpers/string";
import ConfirmModal from "../../Modal/ConfirmModal";
import M24Pagination from "../../Pagination/M24Pagination";
import ProductItem from "../ProductItem";
import './styles.scss';


type Props = {
    handleClickOrderBtn: () => void;
    cartItem: any;
    filter: any;
    pagination: any;
    getCartData: (temp: any) => void;
    onPageChange: (filter: any) => void;
    handleDeleteSku: (merchantId: any, productId: any, skuId: any, callback: () => void) => void;
    handleDeleteMerchant: (merchantId: any, callback: () => void) => void;
    handleUpdateQuantitySku: (merchantId: any, quantify: number, productId: any, skuId: any, callback: () => void) => void;
    isDeletingMerchant: boolean;
    isDeletingSKU: boolean;
    handleUpdateEmployeeNote: (skuId: any, value: string, callback: () => void) => void;
    handleUpdatePersonalNote: (skuId: any, value: string, callback: () => void) => void;
    selectMerchant:(merchant: any, isSelect: boolean)=>void;
    selectSku:(merchant: any, product: any, sku: any, isSelect: boolean)=>void;
    merchantSelected: any
}


function MerchantList(props: Props) {
    const {t} = useTranslation();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [merchantIdSelected, setMerchantId] = useState<string>();
    const {
        handleDeleteSku,
        cartItem,
        isDeletingMerchant,
        isDeletingSKU,
        handleDeleteMerchant,
        handleUpdateQuantitySku,
        handleUpdateEmployeeNote,
        handleUpdatePersonalNote,
        pagination,
        filter,
        merchantSelected,
        selectMerchant,
        selectSku
    } = props;

    const [totalSKU, setTotalSKU]= useState<number>(0);
    const [totalLink, setTotalLink]= useState<number>(0);
    useEffect(()=>{
        if(merchantSelected&&merchantSelected.products&&merchantSelected.products.length>0){
            let total=0;
            let totalLink=0;
            merchantSelected.products.map((x: any)=>{
                totalLink = totalLink+ x.skus.length;
                x.skus.map((sku:any)=>{
                    total = total + sku.quantity;
                });

            });
            setTotalSKU(total);
            setTotalLink(totalLink);
        }else {
            setTotalSKU(0);
            setTotalLink(0);
        }
    },[merchantSelected]);
    const onSubmitConfirm = () => {
        handleDeleteMerchant(merchantIdSelected, () => {
            setShowConfirm(false);
            setMerchantId('');
        });
    }
    const onCancelConfirm = () => {
        setShowConfirm(false);
    }
    const renderMerchants = () => {
        return (
            <div>
                <List
                    pagination={false}
                    dataSource={cartItem}
                    renderItem={(item: any) => {
                        if (item.skus && item.skus.length == 0) return;
                        let totalQuantity: number = 0;
                        let totalPrice: number = 0;
                        let merchantId = lodash.get(item,'id','');
                        let totalSku=0;
                        let totalLink=0;
                        item.products.map((product: any) => {
                            let total: number =0;
                            let price: any = 0;
                            totalSku = totalSku+ product.skus.length;
                            totalLink = totalLink+ product.skus.length;
                            product.skus.map((xx: any)=> {
                                total = total+ xx.quantity;
                            });
                            if(product.pricePolicies&&product.pricePolicies.length>0){
                                let pricePolicies = lodash.sortBy(product.pricePolicies,['minQuantity']);
                                if(total<pricePolicies[0].minQuantity){
                                    price= pricePolicies[0].salePrice;
                                }else if(total>pricePolicies[pricePolicies.length-1].maxQuantity){
                                    price=pricePolicies[pricePolicies.length-1].salePrice;
                                }else{
                                    pricePolicies.map((x: any)=>{
                                        if(total>=x.minQuantity&&total<=x.maxQuantity){
                                            price= x.salePrice;
                                        }
                                    });
                                }
                                totalPrice += total* price;
                            }else{
                                product.skus.map((xx: any)=> {
                                    totalPrice += xx.salePrice * xx.quantity;
                                });
                            }
                            totalQuantity = totalQuantity+ total;

                        });
                        let currency = lodash.get(item, 'marketplace.defaultCurrency', '');
                        let isSelected= false;
                        if(merchantSelected.merchant.id&&merchantId===merchantSelected.merchant.id){
                            isSelected=true;
                        }
                        let indeterminate =false;
                        if(isSelected){
                            indeterminate =true;
                            let totalSkuSelected=0;
                            merchantSelected.products.map((x: any)=>{
                                totalSkuSelected=totalSkuSelected+x.skus.length;
                                // x.skus.map((xx: any)=>{
                                //     totalSkuSelected=totalSkuSelected+xx.quantity;
                                // });
                            });
                            if(totalSkuSelected===totalSku)
                                indeterminate=false;
                        }
                        let merchant={
                            id: merchantId,
                            name: lodash.get(item, 'name',''),
                            icon: lodash.get(item, 'marketplace.icon','')
                        }
                        return (
                            <Spin spinning={false}><Card
                                bodyStyle={{paddingTop: 5, paddingBottom: 5}}
                                className={'merchant-card-custom'}
                                title={<Row align={'middle'}>
                                    <Col span={20}>
                                        <Checkbox indeterminate={indeterminate} onChange={e=> selectMerchant(merchant, e.target.checked)} checked={isSelected}/>
                                        <Avatar className={'mgl20 bd-none-impt'} shape={"square"} size={20}
                                                src={lodash.get(item, 'marketplace.icon')} />
                                        <span
                                            className={'mgl10 mgr5 txt-size-h8 robotoregular txt-color-gray4'}>{t('shopping-cart.seller')}: </span>
                                        <a
                                            href={item.url}
                                            target={'_blank'}
                                          >
                                            <span className={'robotoregular txt-color-black fsz-12 line-h-20 txt-color-black'}>{lodash.get(item, 'name','')}</span>
                                        </a>
                                        <Divider type={"vertical"}/>
                                        <span className={'robotomedium txt-size-h8 txt-color-black'}>
                                        {`${totalQuantity} ${t('shopping-cart.products')}/${totalLink} link`}
                                        </span>
                                        <Divider type={"vertical"}/>
                                        <span className={'robotomedium txt-size-h8 txt-color-black'}>{t('shopping-cart.totalPaid')}:
                                             <span className={'mgl5'}>
                                                 {formatMoneyByUnit(totalPrice, currency)}
                                             </span>
                                        </span>
                                    </Col>
                                    <Col span={4}>
                                        <Row justify={'end'}>
                                            <Button disabled={!merchantId} type={'text'}
                                                    style={{paddingRight:0}}
                                                    onClick={() => {
                                                        setShowConfirm(true);
                                                        setMerchantId(merchantId);
                                                    }}
                                                    className={'txt-size-h8'}>
                                                <i className="fad fa-trash"/>
                                                <span className={'mgl5'}>{t('shopping-cart.deleteMerchant')}</span>
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>}
                            >
                                {
                                    item.products.map((product: any,index: number)=>{
                                        if(product.skus&&product.skus.length>0){
                                            let total: number =0;
                                            let price: any = null;
                                            product.skus.map((xx: any)=> {
                                                total = total+ xx.quantity;
                                            });
                                            let productsSelected = merchantSelected.products.find((x: any)=> x.id===product.id);
                                            if(product.pricePolicies&&product.pricePolicies.length>0){
                                                let pricePolicies = lodash.sortBy(product.pricePolicies,['minQuantity']);
                                                if(total<pricePolicies[0].minQuantity){
                                                    price= pricePolicies[0].salePrice;
                                                }else if(total>pricePolicies[pricePolicies.length-1].maxQuantity){
                                                    price=pricePolicies[pricePolicies.length-1].salePrice;
                                                }else{
                                                    pricePolicies.map((x: any)=>{
                                                        if(total>=x.minQuantity&&total<=x.maxQuantity){
                                                            price= x.salePrice;
                                                        }
                                                    });
                                                }
                                            }
                                            return <List
                                                key={index}
                                                itemLayout="horizontal"
                                                dataSource={product.skus}
                                                renderItem={(sku: any) =>{
                                                    let isSkuSelected = false;
                                                    if(productsSelected){
                                                        isSkuSelected = productsSelected.skus.find((x: any)=> x.id===sku.id);
                                                    }
                                                return <List.Item>
                                                    <ProductItem
                                                        handleUpdateEmployeeNote={handleUpdateEmployeeNote}
                                                        handleUpdatePersonalNote={handleUpdatePersonalNote}
                                                        handleDeleteSku={(productId, skuId, callback) => handleDeleteSku(item.id, productId, skuId, callback)}
                                                        handleUpdateQuantitySku={(quantity, productId, skuId, callback) => handleUpdateQuantitySku(item.id, quantity, productId, skuId, callback)}
                                                        product={sku}
                                                        salePrice={price}
                                                        isDeleting={isDeletingSKU}
                                                        currency={currency}
                                                        isSelected={isSkuSelected}
                                                        selectSku={(isSelect => {
                                                            selectSku(merchant, product, sku, isSelect);
                                                        })}/>
                                                    </List.Item>}
                                                }
                                            />
                                        }

                                    })
                                }
                            </Card></Spin>);
                    }}
                ></List>
                <div className={'align-right'} style={{marginBottom: 100}}>
                    <M24Pagination filter={filter} pagination={pagination} onPageChange={props.onPageChange}/>
                </div>
            </div>
        );
    }
    return (
        <>
            <Content className={'mgbt20'}>
                {renderMerchants()}
            </Content>
            <Row className={'merchant-card-bottom'}>
                {/*<Col span={2} className={'align-items-center pdt20 border-right-1x'}>*/}
                {/*    <Checkbox/>*/}
                {/*    <span className={'mgl20 txt-size-h6 robotomedium'}>{t('shopping-cart.selectAll')}</span>*/}
                {/*</Col>*/}
                <Col span={12} className={'align-items-center'}>
                    <Row className={'mgl40 txt-size-h8 txt-color-secondary'}>{t('shopping-cart.orderInformation')}</Row>
                    <Row className={'mgl40 mgt5 txt-size-h6 robotobold'}>{`${merchantSelected?.merchant?.id?'1':'---'} Shop /  ${totalSKU?totalSKU:'---'} ${t('shopping-cart.products')} / ${totalLink?totalLink:'---'} Link`}</Row>
                </Col>
                {/*<Col span={4} className={'align-item-center pdt10 pdl30'}>*/}
                {/*    <Button size={'large'}*/}
                {/*            className={'_btn-delete-all button-delete-all'}*/}
                {/*    >*/}
                {/*        <i className="fas fa-trash"/> &nbsp;*/}
                {/*        {t('shopping-cart.deleteAll')}*/}
                {/*    </Button>*/}
                {/*</Col>*/}
                <Col span={6} className={'align-item-center'}>
                    <Button type={'primary'} ghost disabled={!merchantSelected.merchant?.id} size={'large'} className={'flr'}
                            onClick={props.handleClickOrderBtn}>
                        <i className="fal fa-check"/> &nbsp;
                        {t('shopping-cart.orderAction')}
                    </Button>
                </Col>
            </Row>

            {showConfirm && <ConfirmModal
                visible={showConfirm}
                loading={isDeletingMerchant}
                content={t('shopping-cart.confirmDeleteMerchant')}
                onSubmit={onSubmitConfirm}
                onCancel={onCancelConfirm}/>}
        </>
    );
}

export default MerchantList;
