import SizeChanger from "./SizeChanger";
import lodash from "lodash";
import {DEFAULT_PAGE_SIZE} from "../../core/config";
import {Pagination, Row} from "antd";
import React from "react";
import './styles.scss'

export interface M24PaginationProps{
    filter:any;
    pagination: any;
    onPageChange:(filter: any)=>void;
    className?: string;
}
const M24Pagination=(props: M24PaginationProps)=>{
    const {filter, pagination, onPageChange, className} = props;
    
    return <Row className={`dpl-flex justify-content-end txt-center ${className?className:'mgbt45 mgt30 '}`}>
        {pagination&&pagination.total?<SizeChanger
            size={lodash.get(filter, 'size', DEFAULT_PAGE_SIZE)}
            onChange={(size) => {
                // this.pushQueryParameters({...filter, size});
                onPageChange({...filter, size, page: 1, pageSize: size});
            }}
        />:null} &nbsp;&nbsp;{pagination&&pagination.total>pagination.pageSize?<Pagination
        className={"_m24-pagination"}
        {...pagination}
        defaultCurrent={6}
        showSizeChanger={false}
        total={pagination.total || 0}
        onChange={(page, pageSize) => {
            window.scrollTo(0, 0);
            // this.pushQueryParameters({...filter, page: page})
            onPageChange({...filter, page, pageSize});
        }}
    />:null}
    </Row>;
}
export default M24Pagination;
