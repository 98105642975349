import {TenantInterface} from "../Interface/TenantInterface";
import React from "react";

export interface AppContextInterface {
    tenant: TenantInterface | null
}

export const AppContext = React.createContext<AppContextInterface>({
    tenant: null
})
