import {Repository} from '../core/repositories/Repository';
import {url} from '../core/helpers/string';
import {httpConfig} from "../core/config/http";
import {API_BASE_URL} from "../core/config";
import {DeviceTokenRegisterInterface} from "../Interface/DeviceTokenRegisterInterface";


export class DeviceTokenRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.setBaseURL(url(API_BASE_URL, ''));
    }

    public register = (data: DeviceTokenRegisterInterface) => {
        return this.http
            .post(`/tokens`, data)
    }
}

export const deviceRepository: DeviceTokenRepository = new DeviceTokenRepository();
