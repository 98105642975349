import { Modal, Button, Row, Col, Input, Radio, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import './style.scss';
import { supplierRepository } from '../../repositories/SupplierRepository';
import { localStorageRead } from '../../utils/LocalStorageUtils';
import { PROFILE_KEY } from '../../core/config';
const { Group } = Radio;
const { Item } = Form;
export interface ModalAddSupplierProps {
	isVisible: boolean;
	domain?: any;
	isLoading: boolean;
	onCancel: () => void;
	onSubmit: (body: any) => void;
	selectedSupplier?: any;
	isReconnect?: any;
	onReconnectSubmit: (body: any) => void;
	onSubmitAccountNotExist: (body: any, provider: string ) => void;
	provider: string;
}

const ModalAddSupplier = (props: ModalAddSupplierProps) => {
	const {isVisible,isLoading,onCancel,onSubmit,domain,selectedSupplier,isReconnect,onReconnectSubmit,provider,onSubmitAccountNotExist
	} = props;
	const { t } = useTranslation();
	// const [visible, setVisible] = useState<boolean>(isVisible);
	const [body, setBody] = useState<any>({ domain: domain ? domain : '' });
	const [phone, setPhone] = useState(lodash.get(localStorageRead(PROFILE_KEY), 'mobile'));
	const [phoneValid, setPhoneValid] = useState(false);
    const [requiredPhoneMess, setRequiredPhoneMess] = useState('');
    
	const [typeAddAccount, setTypeAddAccount] = useState('ACCOUNT_EXIST');

	useEffect(() => {
		if (selectedSupplier && isReconnect)
			setBody({
				domain: lodash.get(selectedSupplier, 'domain'),
				name: lodash.get(selectedSupplier, 'name'),
				// ...body,
			});
	}, []);
	/**
	 * @param key
	 * @param value
	 */
	const onChange = (key: string, value: any) => {
		if (key === 'domain' || key === 'username' || key === 'password') {
			setBody({ ...body, [key]: value });
		} else {
			setBody({ ...body, [key]: value.replace(/[^\d]/g, '') });
		}
	};
	/**
	 * validate dữ liệu hợp lệ
	 */
	const validate = () => {
		if (!body.domain || !body.username || !body.password || !body.secret)
			return false;
		return true;
	};

	const phoneValidation = (str: string) => {
				// from Reg101
        const regex = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/g
        return !(!str || regex.test(str) === false);
	};

	const handleChangeTypeAddAccount = (e: any) => {
		setTypeAddAccount(e.target.value);
	};

	return (
		<Modal
			visible={isVisible}
			title={
				isReconnect
					? t('supplier.reconnectAccountTitle')
					: t('supplier.addAccountTitle')
			}
			onCancel={onCancel}
			footer={[
				<Button
					icon={<CloseOutlined />}
					key='back'
					onClick={onCancel}
					className={'btn_cancel'}>
					{t('button.cancel')}
				</Button>,
				<Button
					icon={<CheckOutlined />}
					key='submit'
					type='primary'
					ghost
					disabled={
						typeAddAccount === 'ACCOUNT_EXIST'
							? isLoading || (!isReconnect && !validate())
							: isLoading || (!isReconnect && !phoneValidation(phone))
					}
					loading={isLoading}
					onClick={() => {
						if (typeAddAccount === 'ACCOUNT_EXIST') {
							if (isReconnect) onReconnectSubmit(body);
							else onSubmit(body);
						} else {
							onSubmitAccountNotExist({phone}, provider);
						}
					}}
					className={'btn_check'}>
					{t('button.submit')}
				</Button>,
			]}
			className='add-supplier'>
			<div>
				{!domain && (
					<Row className={'align-items-center'}>
						{!isReconnect && (
							<>
								<Col lg={8}>
									<span>
										{t('supplier.domain')}
										<span className={'mgl3 txt-color-red'}>*</span>
									</span>
								</Col>
								<Col lg={16}>
									<Input
										value={lodash.get(body, 'domain')}
										autoFocus={true}
										onChange={(e) => onChange('domain', e.target.value)}
										disabled={isReconnect}
									/>
								</Col>
							</>
						)}
					</Row>
				)}
				<Row>
					<span className='robotomedium txt-color-black fsz-14 line-h-22 mg-bt-16'>
						{t('supplier.btAddAccount')}
					</span>
				</Row>
				<Row className='mg-bt-16'>
					<Group onChange={handleChangeTypeAddAccount} value={typeAddAccount}>
						<Radio value='ACCOUNT_EXIST'>{t('supplier.existedAccount')}</Radio>
						<Radio value='ACCOUNT_NOT_EXIST'>
							{t('supplier.not_exist_account')}
						</Radio>
					</Group>
				</Row>
				{typeAddAccount === 'ACCOUNT_EXIST' ? (
					<>
						<Row className={'align-items-center mgt15'}>
							<Col lg={8}>
								<span>
									{t('supplier.username')}
									<span className={'mgl3 txt-color-red'}>*</span>
								</span>
							</Col>
							<Col lg={16}>
								<Input
									value={lodash.get(body, 'name')}
									onChange={(e) => onChange('username', e.target.value)}
									disabled={isReconnect}
									className='border-radius4'
								/>
							</Col>
						</Row>
						<Row className={'align-items-center mgt15'}>
							<Col lg={8}>
								<span>
									{t('supplier.password')}
									<span className={'mgl3 txt-color-red'}>*</span>
								</span>
							</Col>
							<Col lg={16}>
								<Input.Password
									value={lodash.get(body, 'password')}
									onChange={(e) => onChange('password', e.target.value)}
									className='border-radius4'
								/>
							</Col>
						</Row>
						<Row className={'align-items-center mgt15'}>
							<Col lg={8}>
								<span>
									{t('supplier.pin')}
									<span className={'mgl3 txt-color-red'}>*</span>
								</span>
							</Col>
							<Col lg={16}>
								<Input.Password
									value={lodash.get(body, 'secret')}
									onChange={(e) => onChange('secret', e.target.value)}
									className='border-radius4'
								/>
							</Col>
						</Row>
					</>
				) : (
					<Row>
						<Col lg={8} className=''>
							<span>
								{`${t('supplier.phone')}:`}
								<span className={'mgl3 txt-color-red'}>*</span>
							</span>
						</Col>
						<Col lg={16}>
							<Input
								value={phone}
								onChange={(e) => {
                                    setPhone(e.target.value)
                                    const isPhoneValid = phoneValidation(e.target.value);
                                    setPhoneValid(isPhoneValid)
                                    setRequiredPhoneMess(isPhoneValid
                                        ? ""
                                        : t('supplier.requirePhoneMess'))
                                }}
								className='border-radius4'
							/>
								{!phoneValid && <span className='txt-color-red robotoregular fsz-12 line-h-20'>
									{requiredPhoneMess}
								</span>}
						</Col>
					</Row>
				)}
			</div>
		</Modal>
	);
};
export default ModalAddSupplier;

