import React, {useContext, useEffect, useRef, useState} from "react";
import './style.scss';
import {Image, notification, Row} from "antd";
import iconChat from '../../resources/images/icon-chat.png';
import {useTranslation} from "react-i18next";
import {Thread} from "./Thread";
import {Message} from "./Message";
import {BoxChatContext} from "../../context/BoxChatContext";
import {ThreadItemInterface} from "../../Interface/ThreadItemInterface";
import {ThreadItemModel} from "../../model/ThreadItemModel";
import {threadRepository} from "../../repositories/ThreadRepository";
import {ThreadMessageModel} from "../../model/ThreadMessageInterface";
import {ThreadMessageInterface} from "../../Interface/ThreadMessageInterface";
import {ThreadFilterInterface} from "../../Interface/ThreadFilterInterface";
import lodash from "lodash";
import {ThreadCommentFilterInterface} from "../../Interface/ThreadCommentFilterInterface";

interface CommentProps {
    referenceCode: string
    type: string
}

function BoxChat(props: CommentProps){
    const {t} = useTranslation();
    const [isExpand, setExpand] = useState<boolean>(false);
    const boxChatContext = useContext(BoxChatContext)
    const [thread, setThread] = useState<ThreadItemInterface>(ThreadItemModel)
    const [, setLastMessage] = useState<ThreadMessageInterface>(ThreadMessageModel)
    const [threads, setThreads] = useState<ThreadItemInterface[]>([])
    const [messages, setMessages] = useState<ThreadMessageInterface[]>([])
    const [messageLoading, setMessageLoading] = useState(false)
    const [threadLoading, setThreadLoading] = useState(false)
    const [threadTotal, setThreadTotal] = useState(0)
    const [threadCurrentPage, setThreadCurrentPage] = useState(0)
    const [threadPageSize, setThreadPageSize] = useState(25)
    const [messageCurrentPage, setMessageCurrentPage] = useState(0)
    const [messageTotal, setMessageTotal] = useState(0)
    const messagePageSize = 10

    useEffect(() => {
        getThreads({})
    }, [])

    const getThreads = (filter: ThreadFilterInterface, append = true) => {
        setThreadLoading(true)
        return threadRepository.getThreads({offset: 0, limit: 25, sort: 'timestamp:desc', ...filter})
            .then(response => {
                if (append) {
                    setThreads(prevState => {
                        const newThreads = [...prevState, ...response.data]
                        return lodash.uniqBy([...newThreads], 'id')
                    })
                }
                else {
                    setThreads([...response.data])
                }

                setThreadCurrentPage(Number(lodash.get(response, 'headers.x-page-number')))
                setThreadTotal(Number(lodash.get(response, 'headers.x-total-count')))
                setThreadPageSize(Number(lodash.get(response, 'headers.x-page-size')))
            })
            .catch(() => setThreads([]))
            .finally(() => {
                setThreadLoading(false)
            })
    }

    const getThreadMessages = (threadId: string, params: ThreadCommentFilterInterface) => {
        setMessageLoading(true)
        threadRepository.getThreadComments(threadId, {offset: 0, limit: messagePageSize, sort: 'timestamp:desc', ...params})
            .then(response => {
                setMessages(prevMessages => {
                    const newMessages = lodash.uniqBy([...prevMessages, ...response.data], 'id')
                    return [...lodash.sortBy([...newMessages], item => item.timestamp)]
                })

                setMessageCurrentPage(Number(lodash.get(response, 'headers.x-page-number')))
                setMessageTotal(Number(lodash.get(response, 'headers.x-total-count')))
            })
            .catch(() => {
                setMessages([])
                notification.error({
                    message: t('chatBox.errorLoadMessageFail')
                })
            })
            .finally(() => setMessageLoading(false))
    }

    const scrollChatListToBottom = () => {
        if (boxChatContext.chatListRef && boxChatContext.chatListRef.current) {
            setTimeout(() => {
                boxChatContext.chatListRef.current.scrollTo({
                    top: boxChatContext.chatListRef.current.scrollHeight + 10000,
                    behavior: 'smooth'
                });
            }, 1000)
        }
    }

    boxChatContext.onSelectThread = (thread) => {
        setThread(thread)
        setMessages([])
        getThreadMessages(thread.id, {})
        scrollChatListToBottom()
    }

    boxChatContext.onFilterThreads = (params) => {
        const newParams = {...params}
        if (newParams.isRead === 'all') {
            lodash.unset(newParams, 'isRead')
        }
        return getThreads(newParams, false)
    }

    boxChatContext.onPostMessage = (message) => {
        boxChatContext.lastMessage = message
        setLastMessage(message)
        getThreadMessages(thread.id, {})

        if (boxChatContext.chatListRef) {
            setTimeout(() => {
                boxChatContext.chatListRef.current.scrollTop = boxChatContext.chatListRef.current.scrollHeight + 10000;
            }, 1000)
        }
    }

    boxChatContext.onMessageChangePage = (page) => {

    }

    boxChatContext.onListChatScroll = (e) => {
        if (e.target && e.target.scrollTop === 0 && messages.length < messageTotal) {
            getThreadMessages(thread.id, {offset: (messageCurrentPage + 1) * messagePageSize, limit: messagePageSize})

            setTimeout(() => {
                boxChatContext.chatListRef.current.scrollTo({
                    top: e.target.scrollHeight - (e.target.scrollHeight * 0.88),
                    behavior: 'smooth'
                });
            }, 1000)
        }
    }

    boxChatContext.onThreadScroll = (e) => {
        const element = e.target
        if (Math.round(element.scrollHeight - element.scrollTop) === Math.round(element.clientHeight) && threads.length < threadTotal) {
            getThreads({offset: (threadCurrentPage + 1) * threadPageSize, limit: threadPageSize, sort: 'timestamp:desc'})
        }
    }

    boxChatContext.toggle = () => {
        onExpand()
    }

    boxChatContext.onShow = (cb) => {
        if (boxChatContext.thread.id) {
            setTimeout(() => {
                scrollChatListToBottom()
            }, 300)
        }

        if (cb) cb()
    }

    boxChatContext.chatListRef = useRef<any>()
    boxChatContext.thread = thread
    boxChatContext.threadLoading = threadLoading
    boxChatContext.threadTotal = threadTotal
    boxChatContext.threads = threads
    boxChatContext.messages = messages
    boxChatContext.messageLoading = messageLoading
    boxChatContext.visible = isExpand

    const onExpand=()=>{
        setExpand(prevState => {
            const newState = !prevState
            boxChatContext.visible = newState
            if (newState) {
                boxChatContext.onShow()
            }
            return newState
        });
    }

    return (
        <div className={`box-chat`}>
            <div className={`box-chat-float-button ${isExpand?'expand':'collapse'}`}>
                <Row className={'justify-content-end'}>
                    <Image onClick={onExpand}  preview={false} src={iconChat} width={40} height={40} />
                </Row>

                <div onClick={onExpand} className={'box-chat-header cursor-pointer'}>
                    <span className={'txt-color-white txt-size-h7 box-chat-header__top-bar'}><span className={'txt-capitalize robotomedium'}>{t('chatBox.boxHeading')}</span> <i className={'d-none box-chat-header__badge fsz-12 robotomedium'}>{boxChatContext.threadTotal}</i></span>
                    <span className={'txt-color-white txt-size-h7'}>
                        <i className={`mgl10 fas ${isExpand?'fa-angle-down':'fa-angle-up'}`} />
                    </span>
                </div>

                {isExpand && (
                    <div className="box-chat-body">
                        <Thread />
                        <Message />
                    </div>
                )}
            </div>
        </div>
    );
}
export default BoxChat;
