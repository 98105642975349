import React, {useEffect, useState} from "react";
import {Button, Modal, Row, Table, Tag, Col, Radio} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {cartRepository} from "../../../repositories/CartRepository";
import M24ErrorUtils from "../../../utils/M24ErrorUtils";
import M24Notification from "../../../utils/M24Notification";
import ConfirmModal from "../../Modal/ConfirmModal";

import './styles.scss'

export type Props = {
    isVisible: any;
    onCancel: () => void;
    addressList: any;
    getAddressList: (isReset?: boolean) => void;
    openAddressCreate: any;
    makeDeliveryAddress: (record: any) => void;
    deliveryAddress: any;
    updateAddress: (item: any)=> void;
}


function Address(props: Props) {
    const {t} = useTranslation();
    const {
        isVisible, onCancel,
        addressList, getAddressList,
        openAddressCreate, makeDeliveryAddress,
        deliveryAddress, updateAddress
    } = props;
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(deliveryAddress);
    const [deleteItem, setDeleteItem] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(()=>{
        setSelectedItem(deliveryAddress);
    },[deliveryAddress]);
    const onCancelDelete = () => {
        setDeleteItem(null);
        setIsDelete(false)
    }

    const deleteAddress = () => {
        setIsLoading(true);
        cartRepository.deleteAddress(deleteItem.id).then(res => {
            M24Notification.messageSuccess(t('message.success'));
            getAddressList(deliveryAddress.id===deleteItem.id);
            setDeleteItem(null);
            setIsDelete(false);
        }).catch(err => {
            M24ErrorUtils.showError(t, err)
        })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const columns = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            // width:10,
            render: (value: any, record: any) => {
                return (<span className={'txt-size-h7 robotomedium'}>
                    <Radio
                        checked={selectedItem?.id === value}
                        onChange={(e) => {
                            setSelectedItem(record)
                        }}
                    />
                </span>)
            }
        },
        {
            title: <span className={'txt-centermb'}>{t('address.customer-name')}</span>,
            dataIndex: 'receiver',
            key: 'receiver',
            width: 160,
            render: (value: any, record: any) => {
                return (<span className={'txt-size-h7 robotomedium'}>
                   {record.receiver}
                </span>)
            }
        },
        {
            title: t('address.customer-phoneNumber'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: 130,
            render: (value: any, record: any) => {
                return (<span className={'txt-size-h7 robotomedium'}>
                   {record.phoneNumber}
                </span>)
            }
        },
        {
            title: t('address.address-name'),
            dataIndex: 'name',
            key: 'name',
            width: 120,
            render: (value: any, record: any) => {
                return (<span className={'txt-size-h7 robotomedium'}>
                   {record.name}
                </span>)
            }
        },
        {
            title: t('address.address-descriptions'),
            dataIndex: 'detail',
            key: 'detail',
            width: 450,
            render: (value: any, record: any) => {
                return (
                    <Row justify={'start'} align={'middle'}>
                        <Col span={16}>
                            <Row><span className={'txt-size-h7 robotoregular'}>{record.address1}</span></Row>
                            <Row><span className={'txt-size-h7 robotoregular'}>{record.display}</span></Row>
                        </Col>
                        <Col span={2}>
                            {record.isDefault ? <Tag
                                className={'border-radius20 mgl40'}
                                style={{backgroundColor: "orange"}}
                                color={'orange'}>
                                <span
                                    className={'txt-size-h7 robotoregular txt-color-white'}>{t('address.status-isDefault')}</span>
                            </Tag> : null}
                        </Col>
                    </Row>
                );
            }
        },
        {
            title: <span className={'flr txt-size-h7 pdr0'}>{t('address.address-actions')}</span>,
            dataIndex: 'action',
            key: 'action',
            width: 130,
            render: (value: any, record: any) => {
                return (
                    <span className={'flr'}>
                        <Button
                            type={'link'}
                            className={'_btn-edit-address'}
                            onClick={()=> {
                                updateAddress(record);
                                // setSelectedItem(record);
                                // setOpenModal(true)
                            }}
                        >
                            <span className={'txt-size-h7 robotoregular'}>{t('address.edit-action')}</span>
                        </Button>
                        <Button type={'text'}
                                disabled={record.isDefault}
                                style={{width: 30}}
                                onClick={()=> {
                                    setDeleteItem(record);
                                    setIsDelete(true)
                                }}
                                className={'_btn-delete-address address-btn-custom'}

                        >
                            <span className={'txt-size-h7 robotoregular'}>{t('address.delete-action')}</span>
                        </Button>
                    </span>
                )
            }
        }
    ]
    return (
        <div>
            <Modal
                className={'_address-table-custom'}
                visible={isVisible}
                title={<div className={'txt-size-h5'}>
                    {t('address.address-list')}
                    <div className={'_address_count mgl5 txt-size-h8'}>{addressList.length}</div>
                </div>}
                onCancel={onCancel}
                footer={
                    <Row justify={'space-between'} align={'middle'}>
                        {/*<Col span={18}>*/}
                            <Button className={'_btn-add-address'}
                                // style={{textAlign:"left"}}
                                    onClick={() => {
                                        openAddressCreate(true)
                                    }}
                            >
                                <i className="fal fa-plus"/>
                                <span
                                    className={'mgl5 txt-size-h7 robotoregular'}> {t('address.create-address-btn')}</span>
                            </Button>
                        {/*</Col>*/}
                        {/*<Col span={6}>*/}
                            <Row justify={'end'}>
                                <span>
                                     <Button icon={<CloseOutlined/>} key="back"
                                             onClick={onCancel}
                                             className={'btn_cancel'}>
                                    {t('address.button_cancel')}
                                    </Button>
                                    <Button icon={<CheckOutlined/>} key="submit"
                                            type={'primary'}
                                            ghost
                                            loading={isLoading}
                                            onClick={() => {
                                                makeDeliveryAddress(selectedItem)
                                                onCancel();
                                            }}
                                            className={'btn_check border-radius6'}>
                                    {t('button.submit')}
                                    </Button>
                                </span>
                            </Row>
                        {/*</Col>*/}
                    </Row>
                }
                width={1100}
                bodyStyle={{
                    height:600,
                    // overflowY: 'scroll',
                }}
            >
                <Table
                    className={'_address-create-modal ant-modal-custom'}
                    dataSource={addressList}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 1000, y: 520 }}
                    onRow={(record:any,rowIndex:any)=> {
                        return{
                            onClick:event => {setSelectedItem(record)}
                        }
                    }}
                />
            </Modal>
            {isDelete &&
            <ConfirmModal content={t('address.delete-confirm-message')}
                          visible={isDelete}
                          loading={isLoading}
                          onCancel={onCancelDelete}
                          onSubmit={deleteAddress}
            />
            }
        </div>
    );
}

export default Address
