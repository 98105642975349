import React, {useContext, useEffect} from 'react'
import {Spin} from "antd";
import lodash from 'lodash';
import {localStorageSave} from "../../utils/LocalStorageUtils";
import {TENANT_KEY} from "../../core/config";
import {AppContext} from "../../context/AppContext";
import {tenantRepository} from "../../repositories/TenantRepository";

export const Login = () => {
    const appContext = useContext(AppContext)
    useEffect(() => {
        const tenant = appContext.tenant
        if (tenant) {
            setTimeout(() => {
                let authUrl = process.env.REACT_APP_LOGIN_URL + '/' + tenant.code + '?redirect_uri=' + window.location.origin + '/authentication'
                if (lodash.get(tenant, 'config.oauthConfig.redirectLogInEndpoint')) {
                    authUrl = tenant.config.oauthConfig.redirectLogInEndpoint
                }
                // @ts-ignore
                window.location.href = authUrl;
            }, 1000);
        }
        else {
            tenantRepository.getCurrent()
                .then(response => {
                    localStorageSave(TENANT_KEY, response)
                    let authUrl = process.env.REACT_APP_LOGIN_URL + '/' + response.code + '?redirect_uri=' + window.location.origin + '/authentication'
                    if (lodash.get(response, 'config.oauthConfig.redirectLogInEndpoint')) {
                        authUrl = lodash.get(response, 'config.oauthConfig.redirectLogInEndpoint')
                    }
                    window.location.href = authUrl;
                })
        }
    }, [])

    return (
        <div className={'pd20'}>Đang chuyển trang, vui lòng đợi một chút.... <Spin /></div>
    )
}

