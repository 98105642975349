import { Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyData from '../../../components/EmptyData'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatNumber } from '../../../core/helpers/string'

interface NestedData {
  loading: any
  items: any
  record: any
}

const NestedTable:React.FC<NestedData> = ({loading, items, record}) => {
  const {t} = useTranslation()
	const columns = [
    {
      title: <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.code')}</span>,
      key: 'code',
      dataIndex: 'code',
      width: '20%',
      render: (code: any) => (
        <span className='robotoregular fsz-14 line-h-22 txt-color-black'>{code}</span>
      ),
    },
    {
      title: <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.orderCode')}</span>,
      key: 'orderCode',
      dataIndex: 'orderCode',
      width: '20%',
      render: (orderCode: any) => (
        <span className='robotoregular fsz-14 line-h-22 txt-color-black'>
          {orderCode}
        </span>
      ),
    },
    {
      title:  <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.createdAt')}</span>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: '20%',
      render: (createdAt: any) => (
        <span className='robotoregular fsz-14 line-h-22 txt-color-black'>
          {formatDateTime(createdAt)}
        </span>
      ),
    },
    {
      title: <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.volumetric')}</span>,
      key: 'volumetric',
      dataIndex: 'volumetric',
      width: '20%',
      render: (volumetric: any) => (
        <span className='robotoregular fsz-14 line-h-22 txt-color-black'>
          {volumetric === 0 ? `${t('requestDeliveryPackage.undefined')}`: `${formatNumber(volumetric)}cm3`}
        </span>
      ),
    },
    {
      title:  <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.weight')}</span>,
      key: 'weight',
      dataIndex: 'actualWeight',
      width: '20%',
      render: (actualWeight: any) => (
        <span className='robotoregular fsz-14 line-h-22 txt-color-black'>
          {`${formatNumber(actualWeight)}kg`}
        </span>
      ),
    },
    
  ]
 
  const data =
    items[record.code] && items[record.code].length > 0 ? items[record.code] : []
  return (
    <Table
      locale={{emptyText: <EmptyData text={t('packages.noData')}/>}}
      loading={loading[record.code]}
      rowKey={(record: any) => record.code}
      dataSource={data}
      columns={columns}
      pagination={false}
      className='main-table nested'
    />
  )
}

export default NestedTable