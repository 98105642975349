import { Divider, Spin, Tooltip } from 'antd'
import React, { useState } from 'react'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import Item from './Item'
import TruncateText from '../../TruncateText'
import { formatMoneyByUnit } from '../../../core/helpers/string'
interface OrderDetailProps {
	orderDetail: any
}

export const textFromData = (obj: any, key: string, empty: any) => {
	return lodash.isNil(lodash.get(obj, key, empty)) || lodash.isEmpty(lodash.get(obj, key, empty))
		? empty
		: lodash.get(obj, key, empty)
}

const Information: React.FC<OrderDetailProps> = ({ orderDetail }) => {
	const { t } = useTranslation()
	const emptyTxt = '---'
	const orderNote = textFromData(orderDetail, 'employeeNote', emptyTxt)
	const personalNote = textFromData(orderDetail, 'personalNote', emptyTxt)
	const displayAddress = textFromData(orderDetail, 'address.display', emptyTxt)
	const [currency] = useState<string>(textFromData(orderDetail, 'currency', emptyTxt))
	const listServices = textFromData(orderDetail, 'services', [])
	const exchangeRateStr = `${formatMoneyByUnit(1, lodash.get(orderDetail, 'currency', emptyTxt))}
                          = ${formatMoneyByUnit(
														lodash.get(orderDetail, 'exchangeRate', ''),
														currency
													)}`
	const merchantNameStr = textFromData(orderDetail, 'merchantName', emptyTxt)
	const depositRateStr = `${
		lodash.isNull(lodash.get(orderDetail, 'emdPercent'))
			? emptyTxt
			: lodash.get(orderDetail, 'emdPercent', emptyTxt)
	}%`
	const netWeight = lodash.isNil(lodash.get(orderDetail, 'netWeight'))
		? emptyTxt
		: `${lodash.get(orderDetail, 'netWeight')} kg`
	const packingWeight = lodash.isNil(lodash.get(orderDetail, 'packagingWeight'))
		? emptyTxt
		: `${lodash.get(orderDetail, 'packagingWeight')} kg`

	const dimensionalWeight = lodash.isNil(lodash.get(orderDetail, 'dimensionalWeight'))
		? emptyTxt
		: `${lodash.get(orderDetail, 'dimensionalWeight')} kg`
	const chargedWeight = lodash.isNil(lodash.get(orderDetail, 'actualWeight'))
		? emptyTxt
		: `${lodash.get(orderDetail, 'actualWeight')} kg`

	const classes = 'flex-basis-gap-120 flex-shrink-0 mgr60 mgbt8'
	const classesWeight = 'flex-basis-gap-120 flex-shrink-0 mgr60 mgbt8'

	return (
		<div className='dpl-flex flex-col pd-12 bg-color-white box-order gap-8 flex-basis-half h-fit-content flex-grow-0'>
			<h5 className='robotomedium txt-size-h5 line-h-24 mg-0 txt-color-black'>
				{t('orderDetail.info')}
			</h5>
				<div className='dpl-flex flex-col'>
					<div className='dpl-flex gap-8 flex-col mg-bt-7'>
						<Item
							label={`${t('orderDetail.receiver')}:`}
							content={
								<span className='robotomedium'>
									{lodash.get(orderDetail, 'address.fullname', '--')}
								</span>
							}
						/>
						<Item
							label={`${t('orderDetail.phone')}:`}
							content={lodash.get(orderDetail, 'address.phone', '--')}
						/>
						<span className={`robotoregular width100pc line-h-20  txt-color-gray4 mg-r-3`}>
							{`${t('orderDetail.address')}:`}
							<span className='mg-l-3 txt-size-h7 txt-color-black'>
								{`${lodash.get(orderDetail, 'address.detail', '--')}, ${displayAddress}`}
							</span>
						</span>
					</div>
					<Divider className='mg-0 mg-bt-7 divider-bd-color' />
					{/* <SellerInfo orderDetail={orderDetail} loading={loading} /> */}
					<div className='dpl-flex flex-wrap mg-bt-7'>
						<span
							className={`robotoregular width100pc align-items-baseline ${classes} line-h-20 txt-color-gray4`}>
							{`${t('orderDetail.seller')}:`}
							<span className='mg-l-3 txt-size-h7 txt-color-black info-item width100pc'>
								{merchantNameStr}
							</span>
						</span>
						<Item
							label={`${t('orderDetail.depositRate')}:`}
							content={depositRateStr}
							className={classes}
						/>
						<Item
							label={`${t('orderDetail.exchangeRate')}:`}
							content={exchangeRateStr}
							className={classes}
						/>
						<span
							className={
								'flex-basis-100pc dpl-flex gap-6 align-items-center flex-wrap robotoregular'
							}>
							<span className='txt-color-gray4 fsz-12 line-h-20 whitespace'>{`${t(
								'orderDetail.service'
							)}:`}</span>
							{listServices.length > 0
								? listServices.map((item: any, idx: number) => {
									let colorService = 'service-normal';
									if (item.needApprove) {
										if (item.approved === null || item.approved === undefined) {
											colorService = "service-pending"
										} else if (item.approved === false) {
											colorService = "service-reject"
										}
									}
										return <span
											className='dpl-flex flex-wrap align-items-center txt-color-black line-h-22 fsz-14'
											key={idx}>
											<span className={`mg-r-4 ${colorService}`}>{`${item.name}`}</span>
											{idx !== listServices.length - 1 && <span className=''>|</span>}
										</span>
								})
								: '---'}
						</span>
					</div>
					<Divider className='mg-0 mg-bt-7 divider-bd-color' />
					{/* <Weight orderDetail={orderDetail} loading={loading} /> */}
					<div className='dpl-flex flex-wrap'>
						<Item
							label={`${t('orderDetail.chargedWeight')}:`}
							content={<span className='whitespace'>{chargedWeight}</span>}
							className={classesWeight}
						/>
						<Item
							label={`${t('orderDetail.netWeight')}:`}
							content={<span className='whitespace'>{netWeight}</span>}
							className={classesWeight}
						/>
						<Item
							label={`${t('orderDetail.weightConversion')}:`}
							content={<span className='whitespace'>{dimensionalWeight}</span>}
							className={classesWeight}
						/>
						<Item
							label={`${t('orderDetail.packingWeight')}:`}
							content={<span className='whitespace'>{packingWeight}</span>}
							className={classesWeight}
						/>
					</div>
					<Divider className='mg-0 mg-bt-7 divider-bd-color' />
					<Item
						label={<span className='mg-r-3'>{`${t('orderDetail.orderNote')}:`}</span>}
						content={<TruncateText defaultValue={orderNote} editable={false} />}
					/>
					<Item
						label={
							<span className='mg-r-3'>
								<span>{`${t('orderDetail.personalNote')}: `}</span>
								<Tooltip
									title={
										<span className='robotoregular fsz-12 txt-color-black line-h-20'>
											{t('orderDetail.noticePersonal')}
										</span>
									}
									color='#FBB739'>
									<i className='far fa-info-circle txt-color-gray4 txt-size-h8 cursor-pointer'></i>
								</Tooltip>
							</span>
						}
						content={<TruncateText defaultValue={personalNote} editable={true} isOrder={true} />}
					/>
				</div>
		</div>
	)
}

export default Information
