import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import SecurityService from "../../utils/SecurityService";

export const AuthenticateRoute = (props: any) => {

    const navigate = useNavigate()

    useEffect(() => {
        if (!SecurityService.isLogged()) {
            return navigate(`/login`)
        }
    }, [])

    return props.children
}
