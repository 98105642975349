import { Avatar, Col, Divider, Row, Tooltip } from 'antd'
import lodash from 'lodash'
import { Link } from 'react-router-dom'
import { formatDateTime } from '../../core/helpers/date-time'
import { FileTextOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatMoneyByUnit } from '../../core/helpers/string'
import Clipboard from '../Clipboard'
import './styles.scss'

export interface ItemProps {
	statuses: any
	item: any
}

function Item(props: ItemProps) {
	const { t } = useTranslation()
	const { statuses, item } = props
	const [status] = useState<any>(statuses.find((x: any) => x.code === item.status))
	const [currency, setCurrency] = useState<string>()
	useEffect(() => {
		let temp = lodash.get(item, 'provider.curency', '')
		if (temp) {
			temp = JSON.parse(temp)
			setCurrency(temp.code)
		}
	}, [])
	return (
		<div className={'_order-item'}>
			<Col span={24}>
				<Row
					key={item.id}
					className={
						'align-items-center justify-content-between pd12 bg-color-white mgbt10 border-card-header'
					}>
					<Avatar
						shape={'square'}
						size={48}
						src={lodash.get(item, 'image', '')}
						className={''}
						style={{ border: 'none' }}></Avatar>
					<Col flex={'auto'} className={'mgl8'}>
						<Row className={''} align={'middle'}>
							<Col span={20}>
								<Row align={'middle'}>
									<span className={'align-items-center txt-color-primary'}>
										<Link to={`/orders/${item.code}`}>
											<span className={'txt-size-h7 robotomedium '}>
												#{lodash.get(item, 'code', '--')}
											</span>
										</Link>
										{item.code && <Clipboard value={item.code}></Clipboard>}
									</span>
									<Divider
										type={'vertical'}
										className={'mgl12 mgr12 txt-color-secondary bd-color-gray '}
									/>
									<span className={'txt-size-h8 robotoregular txt-color-black'}>
										{formatDateTime(lodash.get(item, 'createdAt', '--'))}
										<Tooltip
											className={'mgl4 txt-size-h9'}
											title={
												<span className={'txt-color-black txt-size-h8 robotoregular'}>
													{t('orders.tooltipCreatedAt')}
												</span>
											}
											color={'#F9A400'}>
											<i className='far fa-info-circle txt-color-secondary' />
										</Tooltip>
									</span>
									<Divider
										type={'vertical'}
										className={'mgl12 mgr12 txt-color-secondary bd-color-gray '}
									/>
									<span className={'txt-size-h8 robotoregular txt-color-black'}>
										{`${lodash.get(item, 'orderedQuantity', '--')}/${
											item.purchasedQuantity !== null ? item.purchasedQuantity : '--'
										}/${item.receivedQuantity != null ? item.receivedQuantity : '--'}`}
										<Tooltip
											className={'mgl4 txt-size-h9'}
											title={
												<span className={'txt-color-black txt-size-h8 robotoregular'}>
													{t('orders.tooltipQuantity')}
												</span>
											}
											color={'#F9A400'}>
											<i className='far fa-info-circle txt-color-secondary' />
										</Tooltip>
									</span>
									<Divider
										type={'vertical'}
										className={'mgl12 mgr12 txt-color-secondary bd-color-gray '}
									/>
									<span className={'align-items-center '}>
										<Avatar
											size={16}
											src={lodash.get(item, 'provider.logo', '')}
											shape={'circle'}
											style={{ border: 'none' }}></Avatar>
										<span className={'mgl5 txt-size-h8 robotoregular txt-color-black'}>
											{lodash.get(item, 'provider.domain', '')}
										</span>
										{/*<Divider type={"vertical"}/>*/}
										<span className={'txt-color-black mgl5 mgr5'}>|</span>
										<span className={'txt-size-h8 robotoregular txt-color-black'}>
											{lodash.get(item, 'customer', '')}
										</span>
									</span>
								</Row>
							</Col>
							<Col span={4}>
								<Row className={'justify-content-end'}>
									<div
										style={{ backgroundColor: lodash.get(status, 'color', '#09B8AF') }}
										className={'bt-status item-align-center'}>
										<span className={'txt-color-white txt-size-h8'}>
											{lodash.get(status, 'name', '--')}
										</span>
									</div>
								</Row>
							</Col>
						</Row>
						<Row className={'mgt5'} align={'middle'}>
							<Row className={'dpl-flex align-items-center'} align={'middle'}>
								<Tooltip
									title={
										<span className={'robotoregular txt-size-h8 txt-color-black'}>
											{t('orders.note')}
										</span>
									}
									className={'txt-color-black txt-size-h7'}
									color={'#F9A400'}>
									<i className='fal fa-file-alt txt-size-h8 txt-color-secondary mgr3' />
								</Tooltip>
								{item.personalNote && item.personalNote.length > 30 ? (
									<Row className={'dpl-flex align-items-center'}>
										<span className={'txt-size-h8 txt-color-black personal-note'}>
											{item.personalNote ? item.personalNote : '--'}
										</span>
										<Tooltip
											title={
												<span className={'robotoregular txt-size-h8 txt-color-black'}>
													{item.personalNote}
												</span>
											}
											className={'txt-color-black txt-size-h9 robotoregular'}
											color={'#F9A400'}>
											<span>
												<i className='txt-size-h9 far fa-info-circle txt-color-secondary'></i>
											</span>
										</Tooltip>
									</Row>
								) : (
									<span className={'txt-size-h8 txt-color-black personal-note'}>
										{item.personalNote ? item.personalNote : '--'}
									</span>
								)}
							</Row>
							<span className={'txt-size-h8 txt-color-secondary robotoregular mgl20'}>
								{t('orders.finance')}{' '}
								<Tooltip
									className={'mgl2 txt-size-h9'}
									color={'#F9A400'}
									title={
										<span className={'txt-color-black txt-size-h8 robotoregular'}>
											{t('orders.tooltipFinance')}
										</span>
									}>
									<i className='far fa-info-circle txt-color-secondary' />
								</Tooltip>
								:
								<span className={'txt-color-black mgl3'}>{`${formatMoneyByUnit(
									lodash.get(item, 'provisionalAmount', ''),
									currency
								)}/${formatMoneyByUnit(lodash.get(item, 'totalUnpaid', ''), currency)}`}</span>
							</span>
							<span className={'txt-size-h8 txt-color-secondary mgl20 mgr3'}>
								{t('orders.services')}:
							</span>
							<Row className={'whitespace dpl-il'}>
								{item.services &&
									item.services.map((x: any, index: number) => {
										let colorService = 'service-normal'
										if (x.needApprove) {
											if (x.approved === null || x.approved === undefined) {
												colorService = 'service-pending'
											} else if (x.approved === false) {
												colorService = 'service-reject'
											}
										}
										return (
											<span className={`txt-size-h8 robotoregular ${colorService}`}>
												{lodash.get(x, 'name', '')}
												{index < item.services.length - 1 && (
													<span className={'txt-color-black mgl5 mgr5'}>|</span>
												)}
											</span>
										)
									})}
							</Row>
						</Row>
					</Col>
				</Row>
			</Col>
		</div>
	)
}

export default Item
