import {useEffect, useState} from "react";
import {localStorageSave} from "../../utils/LocalStorageUtils";
import {PROFILE_KEY, TOKEN_KEY} from "../../core/config";
import {Spin} from "antd";
import {useTranslation} from "react-i18next";
import axios from "axios";

export const Authentication = () => {
    const {t} = useTranslation()
    const [token, setToken] = useState<any>(null)

    useEffect(() => {
        const hash = window.location.hash;
        let tokenFromUrl;
        if(hash.indexOf('access_token=') >= 0){
            tokenFromUrl = hash.substring(hash.indexOf('=')+1, hash.indexOf('&'));
            if (tokenFromUrl) {
                setToken(tokenFromUrl)
                localStorageSave(TOKEN_KEY, tokenFromUrl);
                axios({
                    url: process.env.REACT_APP_API_URL + '/profile',
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${tokenFromUrl}`
                    }
                })
                    .then(res => {
                        localStorageSave(PROFILE_KEY, res.data);
                        window.location.href = '/';
                    })
                    .catch(() => {
                        setToken('')
                        localStorageSave(TOKEN_KEY,'');
                    })
            }
        }
    }, [])

    return (
        <>
            {token ? (
                <div className={'pd20'}><Spin spinning={true} /> {t('authentication.authenticating')}</div>
            ) : (
                <div>
                    <div className="txt-center">
                        <p className="splash-text txt-color-black2 mgt20">
                            {t('authentication.authentication_fail')}
                        </p>

                        <div className={'mgt50'}>
                            <a href="/" className="splash-text txt-color-blue2 mg-r-20">
                                <i className="fas fa-undo-alt mgr5"/> <span>{t('authentication.retry')}</span>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
