import React from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Button, DatePicker, Divider, Form, Input, Spin, Upload} from "antd";
import {UserProfileInterface} from "../../Interface/UserProfileInterface";
import {userRepository} from "../../repositories/UserRepository";
import moment from "moment";
import M24Notification from "../../utils/M24Notification";
import {RcFile} from "antd/lib/upload"
import M24ErrorUtils from "../../utils/M24ErrorUtils";
import {localStorageSave} from "../../utils/LocalStorageUtils";
import {PROFILE_KEY} from "../../core/config";



function UserInformation() {
    const {t} = useTranslation();
    const [userInfo] = Form.useForm();
    const [disabledSave, setDisabledSave] = React.useState<boolean>(true);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [image, setImage] = React.useState<string | null>('');
    const [profileUpdated, setProfileUpdated] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsLoading(true)
        userRepository.getProfile().then((response: UserProfileInterface) => {
            localStorageSave(PROFILE_KEY, response);
            setImage(response.avatar)
            userInfo.setFieldsValue({
                email: response.email,
                username: response.username,
                name: response.name,
                dob: moment(response.dob || new Date()),
                mobile: response.mobile,
                avatar: response.avatar,
            })
        }).finally(() => setIsLoading(false));
    }, [userInfo, profileUpdated])


    const handleFormChange = () => {
        const hasErrors = userInfo.getFieldsError().some(({errors}) => errors.length);
        setDisabledSave(hasErrors);
    }

    const handleSave = async () => {
        setIsLoading(true)
        userInfo.validateFields()
            .then((values) => {
                return userRepository.saveProfile({
                    ...values,
                    avatar: image,
                })
            })
            .then(() => {
                M24Notification.messageSuccess(t('profile.success-message'))
            })
            .catch(() => {
                M24Notification.messageError(t('profile.error-message'))
            })
            .finally(() => {
                setIsLoading(false);
                setDisabledSave(true);
                setProfileUpdated(!profileUpdated)
            })
    }

    const beforeUpload = (file: RcFile) => {
        if (!file.type.startsWith("image")) {
            M24Notification.messageError(t('profile.upload-avatar-error'),)
            return false
        }
        setIsLoading(true)
        userRepository.uploadAvatar(file)
            .then((response) => {
                setImage(response.data);
                setDisabledSave(false);
                userInfo.validateFields().then((values) => {
                    return userRepository.saveProfile({
                        ...values,
                        avatar: response.data,
                    })
                })
            })
            .catch((error) => {
                M24ErrorUtils.showError(t, error)
            })
            .finally(() => {
                setIsLoading(false);
            })

        return false
    }

    return (
        <div>
            <Divider orientation="left" plain className={'profile-title'}>
                <span className={'txt-size-h3 txt-color-black robotomedium'}>{t('profile.user-info')}</span>
            </Divider>
            <div className={'pdt15'}>
                <Spin spinning={isLoading}>
                    <Form
                        className={'user-info'}
                        form={userInfo}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 14}}
                        onFieldsChange={handleFormChange}
                    >
                        <Form.Item
                            label={t('profile.avatar')}
                            // name="avatar"
                        >
                            <Avatar size={64} src={image} className={'bd-none-impt'}/>
                            <Upload className={'mgl5 txt-size-h7 robotoregular cursor-pointer upload-btn'}
                                    accept={"image/*"}
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                            >
                                {t('profile.change-avatar')}
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            name='email'
                            label={t('profile.email')}
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            name='username'
                            label={t('profile.username')}
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            name='name'
                            label={t('profile.fullName')}
                            rules={[
                                // eslint-disable-next-line no-template-curly-in-string
                                {required: true, message: t('profile.require-message', {value: '${label}'})},
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name='dob'
                            label={t('profile.dob')}
                        >
                            <DatePicker className={'width100pc'}
                                        format={"DD/MM/YYYY"}
                                        allowClear={false}
                            />
                        </Form.Item>
                        <Form.Item
                            name='mobile'
                            label={t('profile.mobile')}
                            rules={[
                                {pattern: new RegExp('^[0-9]*$'), message: t('profile.not-a-number')}
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{offset: 4, span: 18}}>
                            <Button className={'btn-save-info flr'}
                                    disabled={disabledSave}
                                    onClick={handleSave}
                            >
                                {t('profile.save-info')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        </div>
    );
}

export default UserInformation;
