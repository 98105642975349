import {ConnectionInterface} from "../Interface/ConnectionInterface";
import {ProviderInterface} from "../Interface/ProviderInterface";
import lodash from "lodash";
import numeral from "numeral";
import {ConnectionModel} from "../model/ConnectionModel";
import {ClaimStateInterface} from "../Interface/ClaimStateInterface";
import {ClaimStateModel} from "../model/ClaimStateModel";

export const getProviderFromConnections = (connections: Array<ConnectionInterface>, providerCode: string): ProviderInterface | undefined => {
    const connection = connections.find((item: ConnectionInterface) => item.provider.code === providerCode)
    return lodash.get(connection, 'provider')
}

export const getConnectionFromConnections = (connections: Array<ConnectionInterface>, customerCode: string, providerCode: string): ConnectionInterface | undefined => {
    return connections.find((item: ConnectionInterface) => item.provider.code === providerCode && item.name === customerCode)
}

export const getConnectionById = (connections: ConnectionInterface[], id: string) => {
    return connections.find((item: ConnectionInterface) => item.id === id) || ConnectionModel
}

export const getConnectionByNameAndProviderCode = (connections: ConnectionInterface[], name: string, providerCode: string) => {
    return connections.find((item: ConnectionInterface) => item.name === name && item.provider.code === providerCode) || ConnectionModel
}

export const currencyFormat = (amount: any, format = "0,0") => {
    return numeral(amount).format(format);
};

export const currencyFormatByCurrency = (amount: any, currency: any) => {
    let format = currency === 'VND' ? "0,0" : "0,0[.]00";
    return currencyFormat(amount, format);
};

export const getClaimStatusByCode = (statues: ClaimStateInterface[], code: string) => {
    return statues.find(item => item.code === code) || ClaimStateModel
}

export const getUrlPathname = (location: Location) => {
    if (location.hash) {
        return location.hash.slice(1)
    }
    return location.pathname
}
