import {Repository} from '../core/repositories/Repository';
import {url} from '../core/helpers/string';
import {httpConfig} from "../core/config/http";
import {API_BASE_URL} from "../core/config";
import {AttachmentCreateInterface} from "../Interface/AttachmentCreateInterface";

export class AttachmentRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.setBaseURL(url(API_BASE_URL, ''));
    }

    create = (data: AttachmentCreateInterface) => {
        const formData = new FormData()
        formData.set('provider', data.provider)
        for (let file of data.attachments) {
            formData.append('attachments', file)
        }
        return this.http.post(`/attachments`, formData)
    }
}

export const attachmentRepository: AttachmentRepository = new AttachmentRepository();
