import {MessageItem} from "./MessageItem";
import React, {useContext} from "react";
import {BoxChatContext} from "../../context/BoxChatContext";

export const MessageList = () => {
    const boxChatContext = useContext(BoxChatContext)
    const {messages, onListChatScroll} = boxChatContext

    const handleScroll = (e: any) => {
        onListChatScroll(e)
    }

    return (
        <div ref={boxChatContext.chatListRef} className={'list-chat box-chat-messages'} onScroll={handleScroll}>
            {messages.map((item) => (
                <MessageItem key={item.id} message={item} />
            ))}
        </div>
    )
}
