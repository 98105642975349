import { PlusOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import React from 'react'
import InfoItem from '../Information/Item'
import FeeBox from './FeeBox'
import Heading from './Heading'

interface FinanceProps{
    orderDetail: any;
}
const Finance = (props: FinanceProps) => {
    const {orderDetail} = props;
  return (
    <div className="dpl-flex flex-col pd-12 bg-color-white box-order gap-8 flex-basis-half robotoregular h-fit-content">
        <Heading />
        <FeeBox orderDetail={orderDetail}/>
    </div>
  )
}

export default Finance
