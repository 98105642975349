import { PlusOutlined } from '@ant-design/icons'
import React from 'react'
import { useTranslation } from 'react-i18next';

const Heading = () => {
  const {t} = useTranslation();
  return (
    <div className="dpl-flex align-items-center justify-content-between">
        <h5 className="robotomedium txt-size-h5 mg-0 line-h-24">{t('orderDetail.finance')}</h5>
        {/*<span className="dpl-flex gap-3 align-items-center robotomedium txt-color-order-detail">*/}
        {/*     <PlusOutlined className='fsz-12'/>*/}
        {/*     <span>{t('orderDetail.addPayCode')}</span>*/}
        {/*</span>*/}
    </div>
  )
}

export default Heading
