import { Divider, Image } from 'antd'
import React from 'react'
import lodash from 'lodash'

interface SupplierProps {
  acc: any
}

const Supplier:React.FC<SupplierProps> = ({acc}) => {
	return (
		<span className='dpl-flex gap-4 align-items-center txt-color-black fsz-14 robotoregular'>
			<Image
				preview={false}
				src={lodash.get(acc, 'provider.logo', '')}
				className='bd-none w-24 h-24 dpl-flex align-items-center'
			/>
			<span className='robotomedium fsz-14 line-h-22'>{lodash.get(acc, 'provider.code', '')}</span>
			<Divider type={'vertical'} className={'bd-color-black line-h-22 mg-0'} />
			<span className='robotomedium fsz-14 line-h-22'>{lodash.get(acc, 'name', '')}</span>
		</span>
	)
}

export default Supplier
