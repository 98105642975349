import {Input, notification, Spin, Tooltip, Upload} from "antd";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {BoxChatContext} from "../../context/BoxChatContext";
import {UploadChangeParam, UploadFile} from "antd/lib/upload/interface";
import {threadRepository} from "../../repositories/ThreadRepository";
import lodash from "lodash";
import {ThreadCommentCreateInterface} from "../../Interface/ThreadCommentCreateInterface";
import {MAX_UPLOAD_SIZE} from "../../core/config";
import {AxiosError} from "axios";

const isImage = (file: UploadFile) => {
    if (file.type?.startsWith('image')) {
        return true;
    }
    return false
}

const isVideo = (file: UploadFile) => {
    if (file.type?.startsWith('video')) {
        return true
    }
    return false
}

const MAX_UPLOAD_FILE = 10;

export const MessageInputBox = () => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const boxChatContext = useContext(BoxChatContext)
    const {thread, user, onPostMessage} = boxChatContext
    const [fileList, setFileList] = useState<any[]>([])
    const [comment, setComment] = useState<any>(undefined)

    const handleChangeFile = (info: UploadChangeParam<any>) => {
        if (info.fileList.length > MAX_UPLOAD_FILE) {
            notification.error({
                message: t('chatBox.errorMaxUploadFile').replace('%s', MAX_UPLOAD_FILE.toString()),
                key: "upload_error"
            })
            return;
        }

        if (info.file.size > MAX_UPLOAD_SIZE * 1024 * 1024) {
            notification.error({
                message: t('chatBox.errorMaxUploadFileSize'),
                key: 'upload_error'
            })
            return false
        }

        setFileList([
            ...info.fileList.filter((f: any) => f.size <= MAX_UPLOAD_SIZE * 1024 * 1024).map(f => {
                // @ts-ignore
                f.thumbUrl = URL.createObjectURL(f.originFileObj)
                return f
            })
        ])
    }

    const handleRemoveFile = (removedFile: UploadFile) => {
        setFileList(fileList.filter(f => f.uid !== removedFile.uid))
    }

    const handleSubmit = () => {
        if (!fileList.length && !comment) {
            notification.error({
                message: t('chatBox.errorCommentOrFileEmpty')
            })
            return
        }

        setLoading(true)
        setComment(undefined)
        const postComment = lodash.clone(comment)
        const postData: ThreadCommentCreateInterface = {
            comment: {
                author: user.username,
                content: ""
            }
        }

        if (fileList.length) {
            postData.attachments = fileList.map(f => f.originFileObj)
        }

        if (postComment) {
            postData.comment = {
                ...postData.comment,
                content: postComment
            }
        }

        threadRepository.createComment(thread.id, postData)
            .then((response) => {
                setComment(undefined)
                setFileList([])

                onPostMessage(response.data)
            })
            .catch((error: AxiosError) => {
                if (lodash.get(error.response, 'status') === 400) {
                    if (lodash.get(error.response, 'data.title') === 'content_can_not_be_null') {
                        notification.error({
                            message: t('chatBox.errorCommentBlank')
                        })
                    }
                    else {
                        notification.error({
                            message: t('chatBox.errorCommentCreateFail')
                        })
                    }
                }
                else {
                    notification.error({
                        message: t('chatBox.errorCommentCreateFail')
                    })
                }

            })
            .finally(() => setLoading(false))
    }

    return (
        <Spin spinning={loading}>
            <div className={'chat-input-box'}>
                <div className="chat-input-box__attachments">
                    {fileList.map((item) => (
                        <>
                            {isImage(item) && (
                                <span
                                    className={'chat-input-box__attachments__item chat-input-box__attachments__item--image'}>
                                        <img src={item.thumbUrl} alt={item.name}/>
                                        <i onClick={() => handleRemoveFile(item)} className="chat-attachment-remove-icon fa-regular fa-circle-xmark"/>
                                        <span className="attachment-item-backdrop" />
                                </span>
                            )}

                            {!isImage(item) && (
                                <Tooltip
                                    title={item.name}
                                >
                                    <span
                                        className={'chat-input-box__attachments__item chat-input-box__attachments__item--file'}>
                                        {isVideo(item) ? <i className="fa-solid fa-circle-play file-icon" /> : <i className="fa-solid fa-memo file-icon" />} <span className={'att-file-name'}>{item.name}</span>
                                        <i onClick={() => handleRemoveFile(item)} className="chat-attachment-remove-icon fa-regular fa-circle-xmark"/>
                                        <span className="attachment-item-backdrop" />
                                    </span>
                                </Tooltip>
                            )}
                        </>
                    ))}
                </div>

                {thread.id && (
                    <div className="chat-input-box__input-container">
                        <Input.TextArea
                            autoFocus={true}
                            className={'chat-input-box__input'}
                            placeholder={t('chatBox.inputPlaceHolder')}
                            bordered={false}
                            onKeyDown={e => {
                                if (e.code === 'Enter' && !e.shiftKey) {
                                    handleSubmit()
                                }
                            }}
                            value={comment}
                            readOnly={loading}
                            onChange={e => setComment(e.target.value)}
                        />
                        <span className={'chat-input-box__actions'}>
                        <i onClick={() => handleSubmit()} className="chat-input-box__actions__send fas fa-paper-plane"/>
                        <Upload
                            disabled={loading}
                            fileList={fileList}
                            beforeUpload={() => false}
                            showUploadList={false}
                            multiple={true}
                            onChange={handleChangeFile}
                        >
                            <span
                                className={'chat-input-box__actions__send-file mgl5'}>
                                <i className="far fa-paperclip"/>
                            </span>
                        </Upload>
                    </span>
                    </div>
                )}
            </div>
        </Spin>
    )
}
