import React from "react";
import {message, Tooltip} from 'antd';

export interface ClipboardProps{
    value: any;
}
function Clipboard(props: ClipboardProps){
    const {value} = props;
    return(
        <Tooltip title={<span className={'txt-size-h8 robotoregular txt-color-black'}>Copy</span>} overlayInnerStyle={{backgroundColor:'#F9A400'}} placement={'top'}>
            <i className={'fa-regular cursor-pointer fa-copy mgl5 mgr5 _btCopy'} onClick={()=>{
                const textField = document.createElement('textarea');
                textField.innerText = value;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
                message.success('Copied');
            }}/>
        </Tooltip>

    );
}
export default Clipboard;
