import { RedoOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Avatar,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Divider,
	Input,
	Row,
	Select,
	Skeleton,
	Table,
} from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import MainLayout from '../../components/Layout';
import M24Pagination from '../../components/Pagination/M24Pagination';
import { formatDateTime } from '../../core/helpers/date-time';
import { formatMoneyBySuffix } from '../../core/helpers/string';
import { supplierRepository } from '../../repositories/SupplierRepository';
import M24ErrorUtils from '../../utils/M24ErrorUtils';
import {
	handleChangeDate,
	handleInputChange,
	handleInputChangeMany,
	linkOrder,
} from '../../utils/StringUtils';
import './styles.scss';

function Transactions() {
	const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
	const { t } = useTranslation();
	let [searchParams, setSearchParams] = useSearchParams();
	const [accounts, setAccounts] = useState<any[]>([]);
	const [transactionTypes, setTransactionTypes] = useState<any[]>([]);
	const [accountSelected, setAccountSelected] = useState<any>();
	const [currency, setCurrency] = useState<any>();
	const [supplierConfig, setSupplierConfig] = useState<any>();
	const [filter, setFilter] = useState<any>({});
	const [pagination, setPagination] = useState<any>({});
	const [transactions, setTransactions] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	
	useEffect(() => {
		getAccounts();
	}, []);
	const getData = (connectionId: any, filter?: any) => {
		setShowProgressBar(true);
		setSearchParams(filter);
		supplierRepository
			.getTransactionsByAccount(connectionId, filter)
			.then((response) => {
				let currentPage = lodash.get(response, 'headers.x-page-number');
				let total = lodash.get(response, 'headers.x-total-count');
				setTotal(Number(total))
				let items: any = [];
				response.data.map((item: any) => {
					if (!item.purchaseUnits || (item.purchaseUnits && item.purchaseUnits.length === 0))
						items.push({ ...item, rowSpan: 1, txid: item.id });
					else {
						item.purchaseUnits.map((x: any, index: number) => {
							let temp = lodash.cloneDeep(x);
							delete temp.purchaseUnits;
							if (index === 0) temp.rowSpan = item.purchaseUnits.length;
							else temp.rowSpan = 0;
							temp.id = item.id;
							temp.memo = x.memo;
							temp.amount = x.amount;
							temp.balanceAfter = item.balanceAfter;
							temp.transactionType = x.customType;
							items.push(temp);
						});
					}
				});
				setTransactions(items);
				setPagination({
					current: parseInt(currentPage) + 1,
					hideOnSinglePage: true,
					total: parseInt(total),
					pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
				});
			})
			.catch((err) => {
				// M24ErrorUtils.showError(t, err);
			})
			.finally(() => setShowProgressBar(false));
	};
	const getAccounts = () => {
		setShowProgressBar(true);
		supplierRepository
			.getListAccount()
			.then((res) => {
				let temp = res.filter((x: any) => x.status === 'CONNECTED');
				if (temp && temp.length > 0) {
					let item = temp.find((x: any) => x.defaultConnection);
					if (!item) item = temp[0];
					setAccounts(temp);
					setAccountSelected(item);
					let currencyItem = JSON.parse(item.provider.currency);
					let redirectUrl = JSON.parse(item.provider.redirectUrl);
					setCurrency(currencyItem);
					setSupplierConfig(redirectUrl);
					if (item) {
						let types = searchParams.get('types');
						let query = searchParams.get('query');
						let timestampFrom = searchParams.get('timestampFrom');
						let timestampTo = searchParams.get('timestampTo');
						let page = searchParams.get('page');
						let size = searchParams.get('size');
						let temp: any = {};
						if (types) temp.types = types;
						if (query) temp.query = query;
						if (timestampFrom) temp.timestampFrom = timestampFrom;
						if (timestampTo) temp.timestampTo = timestampTo;
						if (page) temp.page = parseInt(page);
						if (size) temp.size = parseInt(size);
						setFilter(temp);
						getData(item.id, temp);
						getTransactionTypes(item.id);
					}
				}
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err);
			})
			.finally(() => setShowProgressBar(false));
	};
	const getTransactionTypes = (id: any) => {
		supplierRepository
			.getTransactionTypes(id)
			.then((res) => {
				setTransactionTypes(res);
			})
			.catch((err) => {});
	};
	/**
	 *
	 * @param amount
	 * @returns {*}
	 */
	const formatAmount = (amount: any, showPlus?: boolean) => {
		let color = amount < 0 ? 'txt-color-red' : 'txt-color-green';
		return (
			<span className={color}>{`${showPlus && amount > 0 ? '+' : ''}${formatMoneyBySuffix(
				amount,
				lodash.get(currency, 'prefix', ''),
				lodash.get(currency, 'suffix', '')
			)}`}</span>
		);
	};
	const handleChangeAccount = (value: any) => {
		let temp = accounts.find((x) => x.id == value);
		setAccountSelected(temp);
		let currencyItem = JSON.parse(temp.provider.currency);
		setCurrency(currencyItem);
		let redirectUrl = JSON.parse(temp.provider.redirectUrl);
		setSupplierConfig(redirectUrl);
		setFilter({});
		// getData(value);
		getTransactionTypes(value);
	};
	const onPageChange = (filter: any) => {
		setFilter(filter);
		let temp = lodash.cloneDeep(filter);
		if (filter && filter.page && filter.page > 0) {
			temp.page = temp.page - 1;
		}
		getData(accountSelected.id, temp);
	};
	// const onChangeFilterMany = (isCheck: boolean, code: string) => {
	// 	let temp = handleInputChangeMany('types', code, filter);
	// 	setFilter(temp);
	// };

	const onChangeFilterMany = (key: string, isCheck: boolean, code: string) => {
		console.log(key, isCheck);
		const temp = handleInputChangeMany(key, code, filter);
		setFilter(temp);
	};
	const onChange = (key: string, value: any) => {
		let temp = handleInputChange(key, value, filter);
		setFilter(temp);
	};
	const onChangeDate = (date: any, key: string) => {
		let temp = handleChangeDate(key, date, filter);
		setFilter(temp);
	};
	const resetFilter = () => {
		setFilter({});
		getData(accountSelected.id, {});
	};
	const columns = [
		{
			title: t('transactions.createdAt'),
			dataIndex: 'timestamp',
			key: 'timestamp',
			width: 150,
			render: (date: any, record: any) => {
				const obj = {
					children: (
						<Col>
							<Row>
								<span className='_package-created-at'>{formatDateTime(date)}</span>
							</Row>
							<Row>
								<span className='_id'>{lodash.get(record, 'txid', '---')}</span>
							</Row>
						</Col>
					),
					props: {
						rowSpan: 0,
					},
				};
				obj.props.rowSpan = record.rowSpan;
				return obj;
			},
		},
		{
			title: t('transactions.type'),
			dataIndex: 'transactionType',
			key: 'transactionType',
			width: 150,
			render: (value: any, record: any) => {
				let type = transactionTypes.find((x) => x.code === value.toLowerCase());
				return <span>{lodash.get(type, 'name', value)}</span>;
			},
		},
		{
			title: t('transactions.memo'),
			dataIndex: 'memo',
			className: '_transaction-memo whitespace-initial',
			width: 300,
			key: 'memo',
			render: (type: any, record: any) => (
				<Row className={`pdt5 pdbt5 pdl15`}>
					<span
						dangerouslySetInnerHTML={{
							__html: linkOrder(lodash.get(record, 'memo', '---'), supplierConfig),
						}}></span>
				</Row>
			),
		},
		{
			title: <span className={'pdr15'}>{t('transactions.amount')}</span>,
			className: '_sub-transaction-amount txt-right',
			dataIndex: 'amount',
			key: 'amount',
			width: 150,
			render: (value: any, record: any) => (
				<Row className={`dpl-flex justify-content-end pdt5 pdbt5 pdr15`}>
					<span>{formatAmount(record.amount, true)}</span>
				</Row>
			),
		},
		{
			title: t('transactions.balanceAfter'),
			dataIndex: 'balanceAfter',
			className: '_transaction-balance txt-right',
			key: 'balanceAfter',
			width: 150,
			render: (amount: any, record: any) => {
				const obj = {
					children: <span className={'flr'}>{formatAmount(amount)}</span>,
					props: { rowSpan: 0 },
				};
				obj.props.rowSpan = record.rowSpan;
				return obj;
			},
		},
	];

	return (
		<MainLayout title={t('menu.transactions')} showProgressBar={showProgressBar}>
			<Card className={'border-card-header pd-12 filter-card mg-bt-12'}>
				<span className={'fsz-16 txt-color-black robotomedium mg-bt-12'}>
					{t('transactions.filterTitle')}
				</span>
				<Row align='middle' gutter={[16, 8]} className='mg-bt-12 form-filter'>
					<Col span={24} className='dpl-flex align-items-center gap-6 mg-bt-9'>
						<span className='dpl-flex gap-6 align-items-center flex-wrap'>
							<span className='robotoregular fsz-14 line-h-22 whitespace txt-color-black'>
								{t('transactions.type')}:
							</span>
							{transactionTypes.map(({ code, name }: any) => {
								let isSelect = false;
								if (filter?.types?.indexOf(code) >= 0) isSelect = true;
								return (
									<span
										className={isSelect ? 'status-package active' : 'status-package'}
										key={code}
										onClick={() => {
											onChangeFilterMany('types', !isSelect, code);
										}}>
										{name}
									</span>
								);
							})}
						</span>
					</Col>
					<Col span={8} className='dpl-flex gap-4 align-items-center'>
						<span className={'txt-size-h7 txt-color-black whitespace robotoregular'}>
							{t('transactions.account')}:
						</span>
						<Select
							placeholder={t('transactions.selectAccount')}
							value={lodash.get(accountSelected, 'id', undefined)}
							className={'width100pc border-radius4'}
							filterOption={(input, option: any) => {
								return (
									JSON.stringify(option?.props?.children)
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								);
							}}
							onChange={handleChangeAccount}>
							{accounts.map((x) => {
								return (
									<Select.Option value={x.id}>
										<Row className={'dpl-flex align-items-center line-h-20'}>
											<Avatar
												size={12}
												className='bd-none'
												src={lodash.get(x, 'provider.logo')}></Avatar>
											<span className={'mgl5 txt-color-primary fsz-12 robotoregular'}>
												{lodash.get(x, 'provider.name')}
											</span>
											<Divider type={'vertical'}></Divider>
											<span className={'txt-color-primary fsz-12 robotoregular'}>
												{lodash.get(x, 'name')}
											</span>
										</Row>
									</Select.Option>
								);
							})}
						</Select>
					</Col>
					<Col span={8} className='dpl-flex gap-4 align-items-center'>
						<span className={'txt-size-h7 txt-color-black whitespace robotoregular'}>
							{t('transactions.code')}:
						</span>
						<Input
							className={'width100pc py-3 border-radius4'}
							value={lodash.get(filter, 'query', '')}
							onChange={(e) => onChange('query', e.target.value)}></Input>
					</Col>
					<Col span={8} className='dpl-flex gap-4 align-items-center'>
						<span className={'txt-size-h7 txt-color-black whitespace robotoregular'}>
							{t('transactions.createdAt')}:
						</span>
						<DatePicker
							showTime={{
								format: 'HH:mm',
								defaultValue: moment('00:00:00', 'HH:mm'),
							}}
							value={filter.timestampFrom ? moment(filter.timestampFrom) : null}
							onChange={(date) => onChangeDate(date, 'timestampFrom')}
							format={'HH:mm DD-MM-YYYY'}
							placeholder={t('transactions.from')}
							suffixIcon={<i className='far fa-calendar-alt'></i>}
							className={'width100pc py-3 border-radius4'}
						/>
						<DatePicker
							showTime={{
								format: 'HH:mm',
								defaultValue: moment('23:59:59', 'HH:mm'),
							}}
							value={filter.timestampTo ? moment(filter.timestampTo) : null}
							onChange={(date) => onChangeDate(date, 'timestampTo')}
							format={'HH:mm DD-MM-YYYY'}
							placeholder={t('transactions.to')}
							suffixIcon={<i className='far fa-calendar-alt'></i>}
							className={'width100pc py-3 border-radius4'}
						/>
					</Col>
				</Row>
				{/* <Row>
					{' '}
					<span className={'txt-size-h7 txt-color-black mgr5 mgt10'}>
						{t('transactions.type')}:
					</span>
				</Row> */}
				{/* <Col span={24}>
					<Row className={''}>
						{transactionTypes.map((x) => {
							let isSelect = false;
							if (filter?.types?.indexOf(x.code) >= 0) isSelect = true;
							return (
								<Checkbox
									className={'mgt10'}
									checked={isSelect}
									onChange={(e) => onChangeFilterMany('types',!isSelect, x.code)}>
									{x.name}
								</Checkbox>
							);
						})}
					</Row>
				</Col> */}

				<Row className={'dpl-flex justify-content-end align-items-center'}>
					{!lodash.isEmpty(filter) && (
						<Button
							className='clean-filter robotoregular txt-color-gray4 fsz-12 line-h-20 dpl-flex align-items-center
											flex-basis-btn-filter cursor-pointer bd-none gap-4'
							onClick={resetFilter}
							icon={<RedoOutlined />}>
							<span className='mg-0'>{t('filter.refresh')}</span>
						</Button>
					)}
					<Button
						onClick={() => getData(accountSelected.id, { ...filter, page: 0 })}
						loading={showProgressBar}
						type={'primary'}
						ghost
						className='robotoregular txt-color-white bg-color-blue7 fsz-14 line-h-22 bd-none border-radius4
						px-12 py-3 flex-basis-btn-filter btn-df btn-df-secondary-bg-blue'>
						{t('filter.btSearch')}
					</Button>
				</Row>
			</Card>
			<Card
				className={'transaction-card'}>
				<span className={'txt-size-h5 txt-color-black robotomedium fsz-16 line-h-24 pd-bt-16'}>
				
				</span>
				<Row className='dpl-flex align-items-center gap-4 pd-bt-12'>
					<h3 className='robotomedium mg-0 fsz-16 line-h-24 txt-color-black'>
					{t('transactions.list')}
					</h3>
					<span
					className={`${
						total > 0
							? 'bg-color-primary txt-color-white'
							: 'bg-color-gray-badge txt-color-gray4'
					} border-radius20  fsz-12 line-h-20 px-8`}>
					{total}
				</span>
				</Row>
				<Skeleton active={true} loading={showProgressBar}>
					<Table
						rowKey={(record) => record.id}
						dataSource={transactions}
						columns={columns}
						pagination={false}
						className='main-table'
					/>
				</Skeleton>
				{transactions && transactions.length > 0 && (
					<M24Pagination
						onPageChange={onPageChange}
						filter={filter}
						pagination={pagination} className='mg-bt-0 mg-t-12'/>
				)}
			</Card>
		</MainLayout>
	);
}

export default Transactions;
