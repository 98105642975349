import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Col, Collapse, Input, Row, Tag, Select, DatePicker, Checkbox, Button, Menu, Avatar, Divider, Card} from "antd";
import {useTranslation} from "react-i18next";
import {CaretRightOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons'
import {
    handleChangeDate,
    handleChangeRangeDate,
    handleInputChange,
    handleInputChangeMany
} from "../../../utils/StringUtils";
import './styles.scss'
import lodash from "lodash";
import MainLayout from "../../Layout";
import moment from "moment";

const {Panel} = Collapse;
const {CheckableTag} = Tag;
const {Option} = Select;
const {RangePicker} = DatePicker;
type OrderFilterProps = {
    statuses: any;
    marketplaces: any;
    providers: any;
    accounts: any;
    filter: any;
    setFilter: Dispatch<SetStateAction<any>>;
    getData: (filter?: any) => void;
}

function OrderFilter(props: OrderFilterProps) {
    const {t} = useTranslation();
    const {statuses, marketplaces, accounts, filter, setFilter, getData} = props;
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [isExpand, setExpand] = useState<boolean>(false);
    const [accountSelected, setAccountSelected] = useState<any>();

    useEffect(()=>{
        if(accounts&&accounts.length>0&&filter&&filter.providerUsername&&filter.provider){
            let account = accounts.find((x: any)=> x.name===filter.providerUsername&&x.provider.code===filter.provider);
            if(account){
                setAccountSelected(account);
            }
        }
    },[accounts]);
    const onChangeFilterMany = (key: string, isCheck: boolean, code: string) => {
        let temp = handleInputChangeMany(key, code, filter)
        // console.log(temp)
        setFilter(temp);
    }
    const onChange = (key: string, value: any) => {
        let temp = handleInputChange(key, value, filter);
        setFilter(temp);
    }
    const onChangeRangeDate = (date: any) => {
        let temp = handleChangeRangeDate(date, 'timestampFrom', 'timestampTo', filter);
        setFilter(temp);
    }
    const resetFilter = () => {
        setFilter({});
        setAccountSelected(null);
        getData();
    }
    const onChangeAccount = (key1:string,value1:any,key2:string,value2:any) => {
        let temp1 = handleInputChange(key1,value1,filter);
        let temp2 = handleInputChange(key2,value2,{...temp1});
        setFilter(temp2);
    }
    return (
        <div className={'border-card-header _filter-container pd12'}>
            <Row className={'align-items-center'}>
                <span className={'txt-size-h6 robotomedium txt-color-black'}>{t('orders.filterTitle')}</span>
                <Col flex={'auto'} className={'pdl8 pdr5'}>
                    <Divider type={'horizontal'} className={''} style={{backgroundColor:'#EBEBEB'}}/>
                </Col>
                <span onClick={() => {
                    setShowFilter(!showFilter);
                    setExpand(false);
                }} className={'cursor-pointer pdl5'}>{showFilter ?
                    <i className="fas fa-chevron-up txt-size-h7"/> : <i className="fas fa-chevron-down txt-size-h7"/>}</span>
            </Row>
            {showFilter && <div>
                <div>
                    <Row className={'dpl-flex align-items-center mgbt10'} align={'middle'} >
                        <span className={'txt-size-h7 robotomedium mgr10'}>{t('orders.order_status')}: </span>
                        {statuses.map((item: any) => {
                            let isSelect = false;
                            if(filter.statuses){
                                let temp = filter.statuses.split(',');
                                if(temp&&temp.includes(item.code)) isSelect = true;
                            }
                            return (
                                <div
                                    className={isSelect ? 'statusSelected' : 'status'}
                                    key={item.code}
                                    onClick={() => {
                                        onChangeFilterMany('statuses', !isSelect, item.code)
                                    }}
                                >
                                    <span
                                        className={isSelect ? 'txt-color-primary txt-size-h8 robotoregular ' : 'txt-color-black txt-size-h8 robotoregular' }>{item.name}</span>
                                </div>
                            );
                        })}
                    </Row>
                    {isExpand&&<div>
                        <Row justify={'start'} align={'middle'}>
                            <Col span={12}>
                                <Row align={'middle'}>
                                    <Col span={6}><span
                                        className={'txt-size-h7 robotomedium'}>{t('orders.order_code')}:</span></Col>
                                    <Col span={16}>
                                        <Input value={lodash.get(filter, 'code', '')}
                                               placeholder={t('orders.order_code')}
                                               onChange={(event => onChange('code', event.target.value))}
                                               className={'input-custom'}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row justify={'start'} align={'middle'} className={''}>
                                    <Col span={6}><span
                                        className={'txt-size-h7 robotomedium'}>{t('orders.order_times')}:</span></Col>
                                    {/*<Col span={5} className={'mgr5'}>*/}
                                    {/*    <Select*/}
                                    {/*        className={'width100'}*/}
                                    {/*        placeholder={t('orders.order_status')}*/}
                                    {/*        onChange={(value:any)=> handleInputChange('status',value,filter)}>*/}
                                    {/*        {statuses.map((x: any) => {*/}
                                    {/*            return <Option value={x.code}>*/}
                                    {/*                {x.name}*/}
                                    {/*            </Option>*/}
                                    {/*        })}*/}
                                    {/*    </Select>*/}

                                    {/*</Col>*/}
                                    <Col span={18}>
                                        <RangePicker
                                            format={'DD-MM-YYYY'}
                                            value={[filter.timestampFrom ? moment(filter.timestampFrom) : null, filter.timestampTo ? moment(filter.timestampTo) : null]}
                                            placeholder={[t('orders.from_dateTime'), t('orders.to_dateTime')]}
                                            onChange={(value: any, dateString: any) => {
                                                // console.log(value);
                                                onChangeRangeDate(value);
                                            }}
                                            className={'width100 datePicker-custom'}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row align={'middle'} className={'mgt5'} justify={'space-between'}>
                            <Col span={12}>
                                <Row justify={'start'} align={'middle'}>
                                    <Col span={6}><span className={'txt-size-h7 robotomedium'}>{t('orders.provider_domain')}:</span></Col>
                                    <Col span={16} className={''}>
                                        <Select
                                            placeholder={t('orders.provider_domain')}
                                            // style={{width: 120}}
                                            className={'width100 select-custom'}
                                            value={lodash.get(accountSelected, 'id', undefined)}
                                            onChange={(value) => {
                                                let account = accounts.find((x: any)=> x.id===value);
                                                if(account){
                                                    // console.log(account);
                                                    setAccountSelected(account);
                                                    onChangeAccount('providerUsername',account.name,'provider',account.provider.code)
                                                }
                                            }}>
                                            {accounts.map((x: any) => {
                                                return <Select.Option value={x.id} className={''}>
                                                    <Row className={'dpl-flex align-items-center'}>
                                                        <Avatar size={20} src={lodash.get(x, 'provider.logo', '')}
                                                                shape={'circle'}></Avatar>
                                                        <span
                                                            className={'mgl5 robotoregular txt-size-h8'}>{lodash.get(x, 'domain', '')}</span>
                                                        <Divider type={"vertical"} className={'bd-color-black'}/>
                                                        <span
                                                            className={'robotoregular txt-size-h8'}>{lodash.get(x, 'name', '')}</span>
                                                    </Row>
                                                </Select.Option>
                                            })}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row align={'middle'} className={''}>
                                    <Col span={6}><span
                                        className={'txt-size-h7 robotomedium'}>{t('orders.findBy_marketPlace')}:</span></Col>
                                    <Col className={''} span={16}>
                                        <Row>
                                            {marketplaces.map((x: any) => {
                                                let isSelect = false;
                                                if(filter.marketplaces){
                                                    let temp = filter.marketplaces.split(',');
                                                    if(temp&&temp.includes(x.code)) isSelect = true;
                                                }
                                                return <Checkbox value={x.code}
                                                                 checked={isSelect}
                                                                 onChange={(e) => {
                                                                     onChangeFilterMany('marketplaces', !isSelect, e.target.value)
                                                                 }}
                                                                 className={'_status-checkbox'}
                                                >
                                                    <span
                                                        className={'txt-size-h7 robotoregular txt-capitalize'}>{x.name}</span>
                                                </Checkbox>
                                            })}</Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/*<Row justify={'start'} align={'middle'} className={'mgt10'}>*/}
                        {/*    <Col span={10}>*/}

                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </div>}
                </div>
                <Row className={'align-items-center justify-content-between mgt5'}>
                    <Col>
                        <span onClick={() => {
                            setExpand(!isExpand);
                        }} className={'cursor-pointer txt-size-h8 robotoregular'}>{isExpand ?
                            <i className="fas fa-chevron-up mgr5"></i> : <i className="fas fa-chevron-down mgr5"></i>}{t(!isExpand ?'orders.filterExpand':'orders.filterCollapse')}</span>
                    </Col>
                    <Col>
                    {!lodash.isEmpty(filter) &&
                    <span onClick={resetFilter} className={'mgr20 cursor-pointer txt-size-h8 robotoregular txt-color-gray refresh-filter-btn'}>
                        <ReloadOutlined className={''}/> &nbsp;
                        {t('filter.refresh')}</span>}
                    <Button
                        className={'border-radius6 _btn-search'}
                        type={"primary"}
                        ghost
                        onClick={() => getData({...filter, page: 0})}
                    >
                        <i className="fal fa-search txt-size-h7 mgr5"/>
                        <span className={'txt-size-h7 robotoregular'}>{t('orders.button_search')}</span>

                    </Button>
                    </Col>
                </Row>
            </div>
            }
        </div>
    );
}

export default OrderFilter;
