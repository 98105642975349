//ORDER_COMMENT
//chatBox.threadTypeOrder
import moment from "moment";
import React, {useContext} from "react";
import {ThreadItemInterface} from "../../Interface/ThreadItemInterface";
import {useTranslation} from "react-i18next";
import {BoxChatContext} from "../../context/BoxChatContext";
import lodash from "lodash";
import {ThreadMessageInterface} from "../../Interface/ThreadMessageInterface";

interface Props {
    thread: ThreadItemInterface
}

const getThreadType = (type: string, t: any) => {
    if (type === "ORDER_COMMENT") {
        return t('chatBox.threadTypeOrder')
    }
    else if (type === 'CLAIM') {
        return t('chatBox.threadTypeClaim')
    }
    return 'Unknown'
}

export const ThreadItem = (props: Props) => {
    const {thread} = props
    const {t} = useTranslation()
    const boxChatContext = useContext(BoxChatContext)

    const handleSelectThread = () => {
        if (thread.id !== boxChatContext.thread.id) {
            boxChatContext.onSelectThread(thread)
        }
    }

    const renderContent = (message: ThreadMessageInterface) => {
        if (message.content) {
            return thread.lastMessage.customer
                ? t('chatBox.lastMessageMe') + ': ' + message.content
                : t('chatBox.lastMessageStaff') + ': ' + message.content
        }

        if (message.attachments.length > 0) {
            return thread.lastMessage.customer
                ? t('chatBox.lastMessageMeAttachment').replace('%s', message.attachments.length.toString())
                : t('chatBox.lastMessageStaffAttachment', message.attachments.length.toString())
        }
    }

    return (
        <div className={`box-chat-thread-item ${thread.id === boxChatContext.thread.id ? 'selected' : ''} ${thread.isRead ? 'read' : ''}`} onClick={() => handleSelectThread()}>
            <div className="thread-order">{getThreadType(thread.type, t)} #{thread.referenceCode} {!thread.isRead && <i className="thread-dot fa-solid fa-circle-small" />}</div>
            {lodash.get(thread, 'lastMessage.timestamp') && (
                <>
                    <div className="thread-message">
                        {renderContent(thread.lastMessage)}
                    </div>
                    <div className="thread-time">{moment(thread.lastMessage.timestamp).format("HH:mm DD/MM/YYYY")}</div>
                </>
            )}
        </div>
    )
}
