import * as React from "react";
import {Button, Modal} from "antd";
import {useTranslation} from "react-i18next";
import lodash from "lodash";


type Props = {
    isVisible:boolean;
    isLoading:boolean;
    onCancel:()=>void;
    onSubmit:()=>void;
    connectionParams:any;
}

const ConnectFromM1Confirm = (props: Props)=>{
    const {t} = useTranslation();
    const {isVisible, onCancel, onSubmit, isLoading,connectionParams} = props;
    return(
        <Modal
            className={`_connection-m1-confirm ant-modal-custom`}
            title={<span>{t('supplier.connect-m1-title')}</span>}
            visible={isVisible}
            onCancel={onCancel}
            width={500}
            bodyStyle={{height:70}}
            footer={[
                <Button
                    className={"btn_cancel _btn-cancel"}
                    key="cancel"
                    onClick={onCancel}
                >
                    <span className="mgr10"> <i className="fal fa-times"/></span> {t('button.cancel')}
                </Button>,
                <Button
                    className={"_btn-submit btn_check"} key="submit" type="primary"
                    autoFocus={true}
                    onClick={onSubmit}
                    loading={isLoading ? isLoading : false}
                    ghost
                >
                    <span className="mgr10"><i className="fal fa-check"/></span> {t('button.submit')}
                </Button>,
            ]}
        >
            <p className={'_p-content mgt10 txt-size-h7 robotoregular txt-color-black'} >
                <span className={''}>{t('supplier.connect-m1-content-1')} </span>
                <span className={'robotobold'}>{connectionParams && lodash.get(connectionParams,'username','----')}</span>
                &nbsp;-
                <span className={'txt-capitalize mgl5 robotobold'}>
                    {connectionParams && lodash.get(connectionParams,'tenant','----')}
                </span>
                <span className={''}> {t('supplier.connect-m1-content-2')}?</span>
            </p>
        </Modal>
    );
}

export default ConnectFromM1Confirm;
