import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import List from "../../../components/Delivery/Create/List"
import MainLayout from '../../../components/Layout'
import { TENANT_KEY } from '../../../core/config'
import { categoryRepository } from '../../../repositories/CategoryRepository'
import { deliveryRepository } from '../../../repositories/DeliveryRepository'
import { supplierRepository } from '../../../repositories/SupplierRepository'
import { tenantRepository } from '../../../repositories/TenantRepository'
import { localStorageRead } from '../../../utils/LocalStorageUtils'
import Filter from './Filter'

const CreateRequestDelivery = () => {
	const [showProgressBar, setShowProgressBar] = useState(true)
	const [searchParams, setSearchParams] = useSearchParams()
	const [accounts, setAccounts] = useState([])
	const [availableOrders, setAvailableOrders] = useState<Array<any>>([])

	const [filter, setFilter] = useState<any>({})
	const [providers, setProviders] = useState<any>([])
	const [statuses, setStatuses] = useState([])
	const [totalWeight, setTotalWeight] = useState(0);
	const [currentConfigShowBalance, setCurrentConfigShowBalance] = useState(false);

	const { t } = useTranslation()

	const getData =(filter: any)=>{
		setShowProgressBar(true);
		setAvailableOrders([]);
		try{
			let temp = lodash.cloneDeep(accounts);
			if(filter.provider)
			 temp = temp.filter((x: any)=> x.connection.provider.code===filter.provider);
			if(filter.providerUsername)
				temp = temp.filter((x: any)=> x.connection.id===filter.providerUsername);
			Promise.all(temp.map( async (x:any, index: number)=>{
				const res = await deliveryRepository
					.getAvailableOrders(
						{
							provider: x.connection.provider.code,
							providerUsername: x.connection.name,
						}
					);
				if(res.length>0)
				res.map((x: any)=>{
					x.selectedPackages= lodash.map(x.packages,'code');
					return x;
				});
				return {orders: res, account: x, checked: true, selectedOrders: lodash.map(res,'code')};
			})).then(values=>{
				let items = calculateCash(values);
				setAvailableOrders(items);
			}).finally(()=> setShowProgressBar(false));
		}catch(err){

		}

	}

	const getAccountBalance = useCallback(() => {
		supplierRepository
			.getAccountBalance()
			.then((res) => {
				const accounts = res.filter((x: any) => x.connection.status === 'CONNECTED');
				setAccounts(accounts);
				let items = lodash.chain(res)
					// Group the elements of Array based on `color` property
					.groupBy('connection.provider.domain')
					.map((value, key) => {
						let group = value[0].connection.provider;
						return {...group};
					})
					.value();
				setProviders(items);
			})
			.catch((err) => {})
	}, [])

	const getStatuses = useCallback(() => {
		categoryRepository
			.getPackageStatuses()
			.then((res: any) => {
				setStatuses(res)
			})
			.catch((err: any) => {})
	}, [])

	useEffect(() => {
		if(accounts&&accounts.length>0)
			getData(filter)
	}, [accounts]);

	useEffect(() => {
		setCurrentConfigShowBalance(lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', '') );
	}, []);

	const onSelectConnection=(index: number, checked: boolean)=>{
			let items = lodash.cloneDeep(availableOrders);
			let temp = items[index];
			temp.checked=checked;
			if(checked){
				temp.selectedOrders=lodash.map(temp.orders,'code');
				temp.orders.map((x: any)=>{
					x.selectedPackages= lodash.map(x.packages,'code');
					return x;
				});
			}else{
				temp.selectedOrders=[];
				temp.orders.map((x: any)=>{
					x.selectedPackages= [];
					return x;
				});
			}
			items[index]=temp;
			items = calculateCash(items);
			setAvailableOrders(items);
	}
	const onSelectOrder=(connectionIndex: number,orderCode: string, checked: boolean)=>{
		let items = lodash.cloneDeep(availableOrders);
		let temp = items[connectionIndex];
		if(checked){
			temp.selectedOrders.push(orderCode);
			temp.orders.map((x: any)=>{
				if(x.code===orderCode)
					x.selectedPackages= lodash.map(x.packages,'code');
				return x;
			});
			if(!temp.checked) temp.checked=true;
		}else{
			temp.selectedOrders = temp.selectedOrders.filter((x: string)=> x!==orderCode);
			if(temp.selectedOrders.length===0) temp.checked=false;
			temp.orders.map((x: any)=>{
				if(x.code===orderCode)
					x.selectedPackages= [];
				return x;
			});
		}
		items[connectionIndex]=temp;
		items = calculateCash(items);
		setAvailableOrders(items)
	}
	const onSelectPackage=(connectionIndex: number,orderCode: string, packageCode: string, checked: boolean)=>{
		let items = lodash.cloneDeep(availableOrders);
		let temp = items[connectionIndex];
		temp.orders.map((x: any)=>{
			if(x.code===orderCode){
				if(checked){
					x.selectedPackages.push(packageCode);
				}else{
					x.selectedPackages=x.selectedPackages.filter((xx: any)=> xx!==packageCode);
				}
				if(x.selectedPackages.length===0) {
					// x.checked=false;
					temp.selectedOrders = temp.selectedOrders.filter((x: string)=> x!==orderCode);
				}else {
					if(!temp.selectedOrders.includes(orderCode)) {
						temp.selectedOrders.push(orderCode);
					}
				}
				if(temp.selectedOrders.length===0) temp.checked=false;
				else  temp.checked=true;
			}
			return x;
		});
		items[connectionIndex]=temp;
		items = calculateCash(items);
		setAvailableOrders(items)
	}
	useEffect(() => {
		const providerUsername = searchParams.get('providerUsername');
		const provider = searchParams.get('provider');
		const params: any = {
		}
		if(providerUsername) params.providerUsername= providerUsername;
		if(provider) params.provider= provider;
		setFilter(params);

		getStatuses();
		getAccountBalance();
	}, []);

	const calculateCash = (items: any)=>{
		let weight = 0;
		items.map((x: any, idx: number)=>{
			if(x.checked){
				let refund = 0;
				let needToPaid = 0;
				let needCharged = 0;
				const ordersListSelected = x.orders.filter((xx: any) =>
					lodash.includes(x.selectedOrders, xx.code)
				);
				ordersListSelected.map((order: any)=>{
					const packages = order.packages.filter((x: any) =>
						lodash.includes(order.selectedPackages, x.code)
					);
					const shippingFee = lodash.sumBy(packages, 'shippingFee');
					const totalCash = lodash.get(order,'cogsAmount',0) + lodash.get(order,'serviceFee',0) + lodash.get(order,'totalRefund',0) - lodash.get(order,'totalPaid',0) + shippingFee;
					needToPaid += totalCash >= 0 ? totalCash : 0;
					refund += totalCash <= 0 ? totalCash : 0;
					weight += lodash.sumBy(packages, 'actualWeight');
				});
				needCharged = lodash.get(x,'account.balance',0) - needToPaid;
				x.needToPaid =  needToPaid;
				x.refund =  refund;
				x.needCharged =  needCharged;
			}else{
				x.needToPaid =  0;
				x.refund =  0;
				x.needCharged =  0;
			}
			return x;
		});
		setTotalWeight(weight)
		return items;
	}

	const onSelectAll=(checked: boolean)=>{
		let items = lodash.cloneDeep(availableOrders);
		items.map((x: any)=>{
			x.checked=checked;
			if(checked){
				x.selectedOrders= lodash.map(x.orders,'code');
				x.orders.map((xx: any)=>{
					xx.selectedPackages= lodash.map(xx.packages,'code');
					return xx;
				});
			}else{
				x.selectedOrders= [];
				x.orders.map((xx: any)=>{
					xx.selectedPackages= [];
					return xx;
				});
			}
		});
		items = calculateCash(items);
		setAvailableOrders(items);
	}
	return (
		<MainLayout title={t('menu.createDeliveryRequest')} showProgressBar={showProgressBar}>
			<Filter
				accounts={accounts}
				setFilter={setFilter}
				providers={providers}
				onSearch={getData}
				filter={filter}
			/>
			<List
				onSelectPackage={onSelectPackage}
				onSelectConnection={onSelectConnection}
				onSelectOrder={onSelectOrder}
				data={availableOrders}
				statuses={statuses}
				totalWeight={totalWeight}
				onSelectAll={onSelectAll}
				showProgressBar={showProgressBar}
				showBalance={currentConfigShowBalance}
			/>
		</MainLayout>
	)
}

export default CreateRequestDelivery
