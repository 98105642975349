import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Card, Col, Divider, List, Result, Row, Table } from 'antd';
import { ArrowRightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import lodash from 'lodash';
import { orderRepository } from '../../../repositories/OrderRepository';
import { formatMoneyByUnit } from '../../../core/helpers/string';
import { Link, useNavigate } from 'react-router-dom';
import './styles.scss';

type PropsStep3 = {
	orderSuccessView: any;
};

function Step3(props: PropsStep3) {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const { orderSuccessView } = props;
	// const [successView,setSuccessView] = useState<any>()
	useEffect(() => {
		countNumber();
	}, []);

	const [totalQuantity, setTotalQuantity] = useState<number>(0);
	const [totalSkus, setTotalSkus] = useState<number>(0);
	const [services, setServices] = useState<any>([]);

	const countNumber = () => {
		setTotalSkus(orderSuccessView.orderItems.length);
		let total: number = 0;
		orderSuccessView.orderItems.forEach((x: any) => {
			total = total + x.orderedQuantity;
			setTotalQuantity(total);
		});
	};

	const columns = [
		{
			title: (
				<span className={'robotomedium txt-color-black txt-size-h7'}>
					{t('shopping-cart.seller')}
				</span>
			),
			dataIndex: 'merchantName',
			key: 'merchantName',
			width: 280,
			render: (value: any, record: any) => (
				<Row className={'whitespace-pre-wrap break-word'}>
					<Avatar
						size={20}
						shape={'square'}
						className={'bd0'}
						src={lodash.get(record, 'marketplace.icon', null)}
					/>
					<span className={'mgl5 robotomedium txt-color-black txt-size-h7'}>
						{lodash.get(record, 'merchantName', '--')}
					</span>
				</Row>
			),
		},
		{
			title: (
				<span className={'robotomedium txt-color-black txt-size-h7'}>
					{t('shopping-cart.order-id')}
				</span>
			),
			dataIndex: 'code',
			key: 'code',
			width: 160,
			render: (value: any, record: any) => (
				<Link to={`/orders/${value}`}>
					<span className={'txt-color-blue robotomedium txt-size-h7'}>
						#{lodash.get(record, 'code', '--')}
					</span>
				</Link>
			),
		},
		{
			title: (
				<span className={'robotomedium txt-color-black txt-size-h7'}>
					{' '}
					{t('shopping-cart.products')}
				</span>
			),
			dataIndex: 'merchantName',
			key: 'merchantName',
			width: 250,
			render: (value: any, record: any) => (
				<span>
					{' '}
					<span className={'robotoregular txt-color-black txt-size-h7'}>
						{totalQuantity} {t('shopping-cart.products')}/{totalSkus} Link
					</span>
				</span>
			),
		},
		{
			title: (
				<span className={'robotomedium txt-color-black txt-size-h7'}>
					{t('shopping-cart.totalPayment')}
				</span>
			),
			dataIndex: 'totalPayment',
			key: 'totalPayment',
			width: 130,
			render: (value: any, record: any) => {
				let currency = JSON.parse(lodash.get(record, 'provider.currency', {}));
				return (
					<span className={'robotoregular txt-color-black txt-size-h7'}>
						{formatMoneyByUnit(
							lodash.get(record, 'provisionalAmount', '---'),
							lodash.get(currency, 'code', '')
						)}
					</span>
				);
			},
		},
		{
			title: (
				<span className={'robotomedium txt-color-black txt-size-h7'}>
					{t('shopping-cart.services')}
				</span>
			),
			dataIndex: 'services',
			key: 'services',
			width: 300,
			render: (value: any, record: any) => {
				return record.services.map((item: any, index: number) => {
					return (
						<span className={'robotoregular txt-color-black txt-size-h7'}>
							<span className={`${item.needApprove ? 'txt-color-primary' : ''}`}>{item.name}</span>
							{index < record.services.length - 1 && (
								<Divider type={'vertical'} style={{ border: '1px solid #C7C7C7' }} />
							)}
						</span>
					);
				});
			},
		},
	];
	return (
		// <div>
		<Row justify={'space-between'} gutter={12}>
			<Col span={7} className={''}>
				<Result
					icon={<i className='far fa-check-circle txt-color-orange3 fa-4x mg-bt-10'></i>}
					title={
						<span className={'txt-size-h6 robotomedium'}>{t('shopping-cart.order_success')}</span>
					}
                    className='h-fit-content success-shopping-cart pd-24'
					extra={[
						<Button
							className={'btn-create-success robotomedium txt-color-white fsz-14 line-h-22 border-radius4 py-9 px-14 dpl-flex align-items-center gap-10'}
							onClick={() => navigate('/orders')}
							key='console'
							// type={'primary'}
						>
							<i className='far fa-arrow-right txt-size-h7 mgr5' />
							<span className={'txt-size-h7 robotomedium'}>
								{t('shopping-cart.to-order-management')}
							</span>
						</Button>,
					]}
				/>
			</Col>
			<Col span={17}>
				<div className={'bd-color-transparent success-view-custom'}>
					<Row align={'middle'} className={'pdbt16'}>
						<Avatar
							src={lodash.get(orderSuccessView, 'provider.logo', '---')}
							alt={''}
							size={24}
							style={{ border: 'none' }}
						/>
						<span className={'txt-capitalize mgl5 txt-size-h6 robotomedium txt-color-black'}>
							{lodash.get(orderSuccessView, 'provider.name', '---')}
						</span>
						<span className={'mgl5 txt-size-h6 robotomedium txt-color-black'}>
							| {lodash.get(orderSuccessView, 'customer', '---')}
						</span>
					</Row>
					<Table
						className={'table-custom width100 main-table'}
						dataSource={[orderSuccessView]}
						// scroll={{ x: 1000}}
						columns={columns}
						pagination={false}
					/>
				</div>
			</Col>
		</Row>
		// </div>
	);
}

export default Step3;
