import {Repository} from '../core/repositories/Repository';
import axios, {AxiosResponse} from 'axios';
import {url} from '../core/helpers/string';
import {httpConfig} from "../core/config/http";
import {API_BASE_URL} from "../core/config";


export class LocationRepository extends Repository {
    constructor() {
        super(httpConfig, false);
        this.setBaseURL(url(process.env.REACT_APP_API_LOCATION_URL?process.env.REACT_APP_API_LOCATION_URL:'', ''));
    }
    public getDivisions = (countryCode: string): Promise<any> => {
        return this.http
            .get(`divisions?country=${countryCode}`)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getChildren = (code: string): Promise<any> => {
        return this.http
            .get(`locations/${code}/children`)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getParent = (code: string): Promise<any> => {
        return this.http.get(`locations/${code}/parent`)
            .then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getCountries = ():Promise<any> => {
        return this.http.get(`locations/`)
            .then((response:AxiosResponse) => {
                return response.data;
            });
    }
    public getDetail = (code: string):Promise<any> => {
        return this.http.get(`locations/${code}`)
            .then((response:AxiosResponse) => {
                return response.data;
            });
    }
}
export const locationRepository: LocationRepository = new LocationRepository();
