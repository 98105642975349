import { CheckOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Image } from 'antd';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatMoneyByUnit, formatNumber } from '../../../core/helpers/string';
import { deliveryRepository } from '../../../repositories/DeliveryRepository';
import M24ErrorUtils from '../../../utils/M24ErrorUtils';
import M24Notification from '../../../utils/M24Notification';
import DeliveryConnection from './DeliveryConnection';
import Supplier from './Supplier';
import EmptyDeliveryRequestImg from '../../../resources/images/empty-4.png';
interface ListProps {
	data: any;
	statuses: any;
	onSelectConnection: (index: number, checked: boolean) => void;
	onSelectOrder: (connectionIndex: number, orderCode: string, checked: boolean) => void;
	onSelectPackage: (
		connectionIndex: number,
		orderCode: string,
		packageCode: string,
		checked: boolean
	) => void;
	totalWeight: number;
	showProgressBar: boolean;
	onSelectAll: (checked: boolean) => void;
	showBalance: boolean
}
const List: React.FC<ListProps> = (props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const {
		data,
		statuses,
		onSelectConnection,
		onSelectOrder,
		onSelectPackage,
		totalWeight,
		onSelectAll,
		showProgressBar,
		showBalance
	} = props;
	const [checkAll, setCheckAll] = useState<boolean>(true);
	const [currency, setCurrency] = useState<string>();
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		let isAll = true;
		data.map((x: any) => {
			x.orders.map((xx: any) => {
				if (xx.packages.length > xx.selectedPackages.length && isAll) isAll = false;
			});
		});
		setCheckAll(isAll);
	}, [data]);

	useEffect(() => {
		let currency = lodash.get(data, 'account.connection.provider.currency', '');
		if (currency) {
			let temp = JSON.parse(currency);
			setCurrency(lodash.get(temp, 'code', ''));
		}
	}, []);

	const createDeliveryRequest = () => {
		let deliveryRequests: any = [];
		const dataMakeRequest = data.filter((x: any) => x.selectedOrders.length > 0);
		for (let i = 0; i < dataMakeRequest.length; i++) {
			let orders: any = [];
			const provider = lodash.get(dataMakeRequest[i], 'account.connection.provider.code');
			const providerUsername = lodash.get(dataMakeRequest[i], 'account.connection.name');
			const selectedOrders = lodash.get(dataMakeRequest[i], 'selectedOrders');
			const selectedOrdersObj = dataMakeRequest[i].orders.filter((x: any) =>
				lodash.includes(selectedOrders, x.code)
			);
			for (let j = 0; j < selectedOrdersObj.length; j++) {
				const selectedPackages = lodash.get(selectedOrdersObj[j], 'selectedPackages');
				const orderCode = lodash.get(selectedOrdersObj[j], 'code');
				orders.push({ orderCode, packages: selectedPackages });
			}
			deliveryRequests.push({ provider, providerUsername, orders });
		}
		setLoading(true);

		Promise.all(
			deliveryRequests.map(async (x: any, index: number) => {
				try {
					await deliveryRepository.createDeliveryRequest(x);
					dataMakeRequest[index].success = true;
				} catch (err: any) {
					dataMakeRequest[index].success = false;
				}
				return dataMakeRequest[index];
			})
		)
			.then((res) => {
				M24Notification.notifySuccess(t('delivery.success'), '', '', 5);
				navigate('/delivery/create-success', {
					state: { dataMakeRequest },
					replace: true,
				});
			})
			.catch((err: any) => {})
			.finally(() => setLoading(false));
	};

	const disabled =
		lodash.flattenDeep(
			lodash.map(lodash.flattenDeep(lodash.map(data, 'orders')), 'selectedPackages')
		).length === 0;
	const allOrders = lodash.flattenDeep(lodash.map(data, 'orders')).length;

	const txtMdClasses = 'robotomedium fsz-14 line-h-22';
	const txtRegularClasses = 'robotoregular fsz-12 line-h-20';
	const deliveryByChecked = data.filter(({ selectedOrders }: any) => selectedOrders.length > 0);
	return (
		<div
			className={`${
				allOrders === 0 ? 'empty-delivery' : ''
			} dpl-flex align-items-center justify-content-center`}>
			{allOrders > 0 && !showProgressBar && (
				<div className='min-h-100vh'>
					<div className='dpl-flex flex-col gap-12'>
						{data &&
							data.map((item: any, index: number) => {
								if (item.orders.length === 0) return;
								return (
									<DeliveryConnection
										onSelectPackage={onSelectPackage}
										onSelectOrder={onSelectOrder}
										onSelectConnection={onSelectConnection}
										position={index}
										key={index}
										statuses={statuses}
										showBalance={showBalance}
										data={item} />
								);
							})}
					</div>
				</div>
			)}
			{allOrders === 0 && !showProgressBar && (
				<div className='dpl-flex flex-col align-items-center gap-13'>
					<Image preview={false} src={EmptyDeliveryRequestImg} />
					<span className='robotomedium fsz-16 line-h-24 txt-color-gray4'>
						{t('delivery.emptyRequest')}
					</span>
				</div>
			)}
			<div className='white-box dpl-flex gap-24 justify-content-between calculate-box align-items-end'>
				<div className='dpl-flex gap-24'>
					<div className='dpl-flex flex-col'>
						<span className='dpl-flex gap-6'>
							<Checkbox checked={checkAll} onChange={(e) => onSelectAll(e.target.checked)} />
							<span className='robotoregular fsz-14 line-h-22 txt-color-black'>
								{t('delivery.chooseAllPackages')}
							</span>
						</span>
						<span className='dpl-flex gap-4'>
							<span className='robotoregualar fsz-12 line-h-20 txt-color-gray4 pd-l-20'>
								{t('availableOrders.totalWeight')}
							</span>
							<span className='robotoregular fsz-12 line-h-20'>{`${formatNumber(
								totalWeight
							)}kg`}</span>
						</span>
					</div>
					<div className='line-footer'></div>
					{!disabled && (
						<div>
							{deliveryByChecked.map(({ needToPaid, refund, account }: any, idx: number) => {
								const temp = JSON.parse(lodash.get(account, 'connection.provider.currency'));
								const { code } = temp;
								return (
									<div key={idx} className='dpl-flex gap-6 align-items-start'>
										<span
											className={`${
												idx > 0 ? 'none-visible' : ''
											} ${txtRegularClasses} txt-color-gray4`}>
											{t('delivery.needToPaid')}
										</span>
										<div className='dpl-flex align-items-center justify-content-between mg-bt-6 gap-8'>
											<Supplier acc={account.connection} />
											<span className={`${txtMdClasses} txt-color-red2`}>
												{formatMoneyByUnit(needToPaid, code)}
											</span>
										</div>
									</div>
								);
							})}
						</div>
					)}
					{!disabled && (
						<div>
							{deliveryByChecked.map(({ needCharged, account }: any, idx: number) => {
								const temp = JSON.parse(lodash.get(account, 'connection.provider.currency'));
								const { code } = temp;
								return (
									<div key={idx} className='dpl-flex gap-6 align-items-start'>
										<span
											className={`${
												idx > 0 ? 'none-visible' : ''
											} ${txtRegularClasses} txt-color-gray4`}>
											{`${t('delivery.needChardCol')}:`}
										</span>
										<div className='dpl-flex flex-col justify-content-between gap-6'>
											<div className='dpl-flex align-items-center mg-bt-6 gap-8'>
												<Supplier acc={account.connection} />
												<span className={`${txtMdClasses} txt-color-red2`}>
													{needCharged > 0
														? formatMoneyByUnit(0, code)
														: formatMoneyByUnit(Math.abs(needCharged), code)}
												</span>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
				<Button
					className='btn-create-delivery fsz-14 robotomedium txt-color-white align-self-end cursor-pointer bd-none'
					icon={<CheckOutlined />}
					loading={loading}
					disabled={disabled}
					onClick={createDeliveryRequest}>
					{t('delivery.requestSelectedPackages')}
				</Button>
			</div>
		</div>
	);
};

export default List;
