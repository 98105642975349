import {Repository} from '../core/repositories/Repository';
import {AxiosResponse} from 'axios';
import {url} from '../core/helpers/string';
import {httpConfig} from "../core/config/http";
import {API_BASE_URL, DEFAULT_PAGE_SIZE} from "../core/config";


export class OrderRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.setBaseURL(url(API_BASE_URL, ''));
    }
    public getServices = (customer: string, provider: string) : Promise<any> => {
        return this.http
            .get(`categories/order-services?customer=${customer}&provider=${provider}`).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public estimateFee = (customer: string, provider: string, body: any) : Promise<any> => {
        return this.http
            .post(`order-services/estimate-fee?customer=${customer}&provider=${provider}`, body).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getOrderFees = (customer: string, provider: string) : Promise<any> => {
        return this.http
            .get(`categories/order-fees?customer=${customer}&provider=${provider}`).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getOrders = (filter: any) : Promise<any> => {
        let limit = filter && filter.size? filter.size:DEFAULT_PAGE_SIZE;
        let offset=filter && filter.page? filter.page*limit:0;
        return this.http
            .get(`orders`, {params: {...filter, offset, limit, sort:'timestamp:desc'}}).then((response: AxiosResponse<any>) => {
                return response;
            });
    }
    public getDetail = (code: string|undefined) : Promise<any> => {
        return this.http
            .get(`orders/${code}`).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public reOrder = (code: string|undefined) : Promise<any> => {
        return this.http
            .post(`orders/${code}/reorder`).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public cancelOrder = (code: string|undefined) : Promise<any> => {
        return this.http
            .post(`orders/${code}/cancel`).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getFinancials = (code: string|undefined) : Promise<any> => {
        return this.http
            .get(`orders/${code}/financials`,{params: {limit:1000}}).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getOrderHistory = (code: string|undefined) : Promise<any> => {
        return this.http
            .get(`orders/${code}/milestones`).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }

    public confirmProductChange = (code: string|undefined, id: string, confirm: boolean) : Promise<any> => {
        return this.http
            .post(`orders/${code}/products/${id}/need_confirm`, {confirm}).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public updatePersonalNoteOfOrder = (code: string|undefined, personalNote:string) : Promise<any> => {
        return this.http
            .patch(`orders/${code}/`, {personalNote}).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public updatePersonalNoteOfOrderItem = (code: string|undefined, productId: string, personalNote:string) : Promise<any> => {
        return this.http
            .patch(`orders/${code}/items/${productId}`, {personalNote}).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }

    public getPackages = (code?: string) : Promise<any> => {
        return this.http
            .get(`orders/${code}/packages`, {params: {sort: 'lastStatusTime:desc'}}).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getPackageHistory = (packageCode?: string, customer?: string, provider?: string) : Promise<any> => {
        return this.http
            .get(`packages/${packageCode}/milestones`, {params: {customer, provider, sort: 'lastStatusTime:desc'}}).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getLogs = (code: string | undefined,filter: any) : Promise<any> => {
        let limit = filter && filter.size? filter.size:DEFAULT_PAGE_SIZE;
        let offset=filter && filter.page? filter.page*limit:0;
        delete filter.page;
        return this.http
            .get(`orders/${code}/logs`, {params: {...filter, offset, limit, sort:'timestamp:desc'}}).then((response: AxiosResponse<any>) => {
                return response;
            });
    }
   
}
export const orderRepository: OrderRepository = new OrderRepository();
