import {Col, Divider, Row} from 'antd'
import React, {useEffect, useState} from 'react'
import InfoItem from '../Information/Item'
import {useTranslation} from "react-i18next";
import lodash from "lodash";
import {formatMoneyByUnit} from "../../../core/helpers/string";
import Item from './Item';
interface FeeBoxProps{
    orderDetail: any;
}
const FeeBox = (props: FeeBoxProps) => {
    const {t} = useTranslation();
    const [totalAmount, setTotalAmount] = useState<any>();
    const [exchangedTotalAmount, setExchangedTotalAmount] = useState<any>();
    const [merchantShippingCost, setMerchantShippingCost] = useState<any>();
    const [exchangedMerchantShippingCost, setExchangedMerchantShippingCost] = useState<any>();
    const [totalFee, setTotalFee] = useState<any>();
    const [grandTotal, setGrandTotal] = useState<any>();
    const [totalRefund, setTotalRefund] = useState<any>();
    const [totalPaid, setTotalPaid] = useState<any>();
    const [totalUnpaid, setTotalUnpaid] = useState<any>();
    const [currencyMarketplace, setCurrencyMarketplace] = useState<any>();
    const [currencyProvider, setCurrencyProvider] = useState<any>();
    const {orderDetail} = props;
    useEffect(()=>{
        if(orderDetail){
            setTotalAmount(lodash.get(orderDetail,'totalAmount',''));
            setExchangedTotalAmount(lodash.get(orderDetail,'exchangedTotalAmount',''));
            setMerchantShippingCost(lodash.get(orderDetail,'merchantShippingCost',''));
            setExchangedMerchantShippingCost(lodash.get(orderDetail,'exchangedMerchantShippingCost',''));
            setTotalPaid(lodash.get(orderDetail,'totalPaid',''));
            setTotalUnpaid(lodash.get(orderDetail,'totalUnpaid',''));
            setTotalRefund(lodash.get(orderDetail,'totalRefund',''));
            setGrandTotal(lodash.get(orderDetail,'grandTotal',''));
            setCurrencyMarketplace(lodash.get(orderDetail,'currency',''));
            let provider = lodash.get(orderDetail,'provider.currency','');
            if(provider){
                setCurrencyProvider(JSON.parse(provider).code)
            }
            let totalFee=0;
            orderDetail.fees.map((x: any)=>{
                if(!x.free){
                    if(x.manual&&x.actualAmount){
                        totalFee =totalFee+x.actualAmount;
                    }else if(!x.manual&&x.provisionalAmount){
                        totalFee =totalFee+x.provisionalAmount;
                    }
                }
            });
            setTotalFee(totalFee);
            let total = totalFee+lodash.get(orderDetail,'exchangedTotalAmount',0)+lodash.get(orderDetail,'exchangedMerchantShippingCost',0);
            setGrandTotal(total);
            setTotalUnpaid(total - lodash.get(orderDetail,'totalPaid',0) + lodash.get(orderDetail,'totalRefund',0));
        }

    },[orderDetail]);
    const renderItemFee=(item: any, index: number)=>{
        return <Row key={index} className={'dpl-flex justify-content-between align-items-center'}>
            <Col  className={'align-items-center dpl-flex'}>
                <span className={`txt-size-h8 txt-color-black`}>{lodash.get(item,'type.name','--')}</span>
            </Col>
            <Col>
                {item.manual|| item.free ? <Row className={'justify-content-end'}>
                    {item.provisionalAmount&&<span className={'txt-size-h8 txt-color-secondary txt-linethrough mgr5'}>{formatMoneyByUnit(item.provisionalAmount, currencyProvider)}</span>}
                    <span className={'txt-size-h8 txt-color-black'}>{item.free?t('orderDetail.feeFree'):formatMoneyByUnit(item.actualAmount, currencyProvider)}</span>
                </Row> :
                    <Row className={'justify-content-end'}>
                        <span className={'txt-size-h8 txt-color-black'}>{formatMoneyByUnit(item.provisionalAmount, currencyProvider)}</span>
                    </Row>
                }
            </Col>
        </Row>
    }
    const renderFee=()=>{
        return <div className={'mgl8'}>
            {orderDetail&&orderDetail.fees.map((x: any,index:number)=> renderItemFee(x, index))}
        </div>
    }
  return (

      <div>
    <div className="dpl-flex gap-4 flex-col bg-color-pink py-8 pdl6 pdr10 fsz-14 robotoregular line-h-22 border-radius4">
        <Item number={'(1)'} label={t('orderDetail.totalAmount')} subValue={totalAmount} value={exchangedTotalAmount} currencyValue={currencyProvider} currencySubValue={currencyMarketplace}></Item>
        <Item number={'(2)'} label={t('orderDetail.merchantShippingCost')} subValue={merchantShippingCost} value={exchangedMerchantShippingCost} currencyValue={currencyProvider} currencySubValue={currencyMarketplace}></Item>
        <Item number={'(3)'} label={t('orderDetail.totalFee')} value={totalFee}  currencyValue={currencyProvider}></Item>
        {renderFee()}
        <Divider className='mg-0 bg-color-orange2'/>
        <Item number={`(4) = (1) + (2) + (3)`} valueClass={'robotomedium'} labelClass={'robotomedium'} label={t('orderDetail.grandTotal')} value={grandTotal}  currencyValue={currencyProvider}></Item>
    </div>
          <div className="dpl-flex gap-4 py-8 px-6 pdr10 flex-col fsz-14 bg-color-pink2 border-radius4 mgt8">
              <Item number={'(5)'} label={t('orderDetail.totalPaid')} value={totalPaid}  currencyValue={currencyProvider}></Item>
              <Item number={'(6)'} label={t('orderDetail.totalRefund')} value={totalRefund}  currencyValue={currencyProvider}></Item>
              {orderDetail&&orderDetail.status!=='CANCELLED'?<Item number={'(7) = (4) - (5) + (6)'} valueClass={'robotomedium txt-color-red'} labelClass={'robotomedium'} label={t(`orderDetail.${totalUnpaid>0?'totalUnpaid':'totalCash'}`)} value={totalUnpaid?Math.abs(totalUnpaid): totalUnpaid}  currencyValue={currencyProvider}></Item>:null}
              </div>
      </div>
  )
}

export default FeeBox
