import { Badge, Divider, Image, Row, Table } from 'antd';
import Tooltip from 'antd/es/tooltip';
import Paragraph from 'antd/lib/typography/Paragraph';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyData from '../../../components/EmptyData';
import { textFromData } from '../../../components/OrderDetail/Information';
import M24Pagination from '../../../components/Pagination/M24Pagination';
import { formatDateTime } from '../../../core/helpers/date-time';
import { formatNumber } from '../../../core/helpers/string';
import { getCleanFormFilter } from '../../../utils/StringUtils';
import NestedTable from './NestedTable';

interface ListProps {
	items: any;
	filter: any;
	accounts: any;
	total: number;
	page: number;
	size: number;
	idAcc: string | undefined;
	showProgressBar: boolean;
	hasError: boolean | null;
	handleFilter: (filter: any) => void;
}
const List: React.FC<ListProps> = (props) => {
	const { t } = useTranslation();
	const {
		idAcc,
		items,
		handleFilter,
		accounts,
		total,
		page,
		size,
		showProgressBar,
		filter,
		hasError,
	} = props;
	const [logo, setLogo] = useState('');
	const [provider, setProvider] = useState('');
	const [customer, setCustomer] = useState('');
	const [expandedRows, setExpandedRows] = useState<Array<any>>([]);
	const [loading] = useState<{ [id: string]: any }>({});
	const classes = 'robotoregular fsz-14 line-h-22 txt-color-black whitespace';

	useEffect(() => {
		const indexAcc = accounts.findIndex((acc: any) => acc.id === idAcc);
		if (indexAcc > -1 && !showProgressBar && !hasError) {
			const logo = lodash.get(accounts[indexAcc], 'provider.logo', '');
			const provider = lodash.get(accounts[indexAcc], 'provider.code', '');
			const customer = lodash.get(accounts[indexAcc], 'name', '');
			setLogo(logo);
			setProvider(provider);
			setCustomer(customer);
		}
	}, [accounts, idAcc, showProgressBar, hasError]);

	const columns = [
		{
			title: t('deliveryNote.code'),
			key: 'code',
			width: 180,
			render: (record: any) => {
				return (
					<div className='dpl-flex align-items-center gap-6'>
						{lodash.includes(expandedRows, record.delivery_note.code) ? (
							<i className='far fa-angle-down txt-color-orange2 w-8 line-h-22 cursor-pointer'></i>
						) : (
							<i className='far fa-angle-right txt-color-orange2 w-8 line-h-22 cursor-pointer'></i>
						)}
						<Paragraph
							className='robotomedium mg-0 txt-color-orange2 fsz-14 line-h-22 order-heading whitespace'
							copyable={{
								text: lodash.get(record, 'delivery_note.code'),
								icon: [
									<Tooltip
										title={
											<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copy</span>
										}
										color='#FBB739'>
										<i className='far fa-copy line-h-14 w-12'></i>
									</Tooltip>,
									<Tooltip
										title={
											<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copied</span>
										}
										color='#FBB739'>
										<i className='far fa-check fsz-12 line-h-14 w-12'></i>
									</Tooltip>,
								],
								tooltips: [false, false],
							}}>
							{lodash.get(record, 'delivery_note.code')}
						</Paragraph>
					</div>
				);
			},
		},
		{
			title: t('deliveryNote.supplier'),
			key: 'account',
			width: 260,
			render: (record: any) => {
				return (
					<div className='dpl-flex gap-4 align-items-center whitespace'>
						<Image
							preview={false}
							src={logo}
							className='bd-none w-12 h-12 dpl-flex align-items-center'
						/>
						<span className={classes}>{provider}</span>
						<Divider type={'vertical'} className={'bd-color-black line-h-22 mg-0'} />
						<span className={classes}>{customer}</span>
					</div>
				);
			},
		},
		{
			title: t('deliveryNote.exportedTime'),
			key: 'exportedTime',
			width: 180,
			render: (record: any) => {
				const exportedAt = lodash.isNil(lodash.get(record, 'delivery_note.exported_at'))
					? '---'
					: formatDateTime(lodash.get(record, 'delivery_note.exported_at'));
				return <span className={classes}>{exportedAt}</span>;
			},
		},
		{
			title: t('deliveryNote.totalPackages'),
			key: 'totalPackage',
			width: 150,
			align: 'center' as 'center',
			render: (record: any) => (
				<span className={classes}>{lodash.get(record, 'delivery_note.package_number')}</span>
			),
		},
		{
			title: t('deliveryNote.totalWeight'),
			key: 'totalWeight',
			width: 150,
			align: 'right' as 'right',
			render: (record: any) => {
				return (
					<span className={classes}>{`${formatNumber(
						lodash.get(record, 'delivery_note.package_weight')
					)}kg`}</span>
				);
			},
		},
		{
			title: t('deliveryNote.COD'),
			key: 'COD',
			width: '12%',
			align: 'center' as 'center',
			render: (record: any) => {
				return (
					<span className={classes}>
						{lodash.get(record, 'delivery_note.cod') === 0
							? '---'
							: formatNumber(lodash.get(record, 'delivery_note.cod'))}
					</span>
				);
			},
		},
		{
			title: t('deliveryNote.address'),
			key: 'address',
			width: '40%',
			ellipsis: true,
			render: (record: string) => (
				<span className={classes}>
					{textFromData(record, 'delivery_note.customer_receiver', '---')} -{' '}
					{textFromData(record, 'delivery_note.customer_address', '---')}
				</span>
			),
		},
	];

	const handleChangePage = (filter: any) => {
		const { page, size, providerUsername } = filter;
		handleFilter(
			getCleanFormFilter(
				{
					...filter,
					customer: providerUsername,
					page: (page - 1).toString(),
					size: size.toString(),
				},
				[],
				['pageSize']
			)
		);
	};
	const totalRendered = hasError ? 0 : total;
	return (
		<div className='white-box dpl-flex flex-col gap-13 pd-imp-12'>
			<div className='dpl-flex align-items-center gap-4'>
				<h3 className='robotomedium mg-0 fsz-16 line-h-24 txt-color-black'>
					{t('deliveryNote.list')}
				</h3>
				<span
					className={`${
						totalRendered > 0
							? 'bg-color-primary txt-color-white'
							: 'bg-color-gray5 txt-color-gray4'
					} border-radius20  fsz-12 line-h-20 px-8`}>
					{totalRendered}
				</span>
			</div>
			<Table
				rowKey={(record: any) => record.delivery_note.code}
				columns={columns}
				dataSource={items}
				locale={{ emptyText: !showProgressBar && <EmptyData text={t('deliveryNote.empty')} /> }}
				pagination={false}
				className='main-table table-expandable selected-table'
				loading={showProgressBar}
				expandRowByClick={true}
				expandable={{
					expandedRowRender: (record) => {
						const items =
							lodash.get(record, 'delivery_note_packages') &&
							lodash.get(record, 'delivery_note_packages').length > 0
								? lodash.get(record, 'delivery_note_packages')
								: [];
						return <NestedTable items={items} loading={loading} record={record} />;
					},
					expandIcon: ({ expanded, onExpand, record }) => null,
					columnWidth: 0,
					onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
				}}
				rowClassName={(record) =>
					lodash.includes(expandedRows, record.delivery_note.code)
						? 'active-row cursor-pointer'
						: 'data-row cursor-pointer'
				}
			/>
			<Row justify={'end'} className={''}>
				{items && items.length > 0 && (
					<M24Pagination
						onPageChange={handleChangePage}
						filter={{ ...filter, page, size }}
						pagination={{
							current: page,
							pageSize: size,
							hideOnSinglePage: true,
							total,
						}}
						className='mg-0'
					/>
				)}
			</Row>
		</div>
	);
};

export default List;