import { Badge, Button, Col, Divider, Image, Row, Table, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import EmptyData from '../../../components/EmptyData'
import M24Pagination from '../../../components/Pagination/M24Pagination'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatNumber } from '../../../core/helpers/string'
import { deliveryRepository } from '../../../repositories/DeliveryRepository'
import { getCleanFormFilter } from '../../../utils/StringUtils'
import NestedTable from './NestedTable'

interface ListProps {
	items: any
	statuses: any
	accounts: any
	total: number
	page: number
	size: number
	filter: any
	idAcc: string | undefined
	showProgressBar: boolean
	handleFilter: (filter: any) => void
}
const List: React.FC<ListProps> = (props) => {
	const [searchParams] = useSearchParams()
	const { t } = useTranslation()
	const {
		items,
		statuses,
		handleFilter,
		accounts,
		total,
		page,
		size,
		showProgressBar,
		filter,
		idAcc,
	} = props
	const [expandedRows, setExpandedRows] = useState<Array<any>>([])
	const [packages, setPackages] = useState<{ [id: string]: [] }>({})
	const [loading, setLoading] = useState<{ [id: string]: any }>({})
	const [logo, setLogo] = useState('')
	const [provider, setProvider] = useState('')
	const [customer, setCustomer] = useState('')
	const navigate = useNavigate()
	useEffect(() => {
		const indexAcc = accounts.findIndex((acc: any) => acc.id === idAcc)
		if (indexAcc > -1 && !showProgressBar) {
			const logo = lodash.get(accounts[indexAcc], 'provider.logo', '')
			const provider = lodash.get(accounts[indexAcc], 'provider.code', '')
			const customer = lodash.get(accounts[indexAcc], 'name', '')
			setLogo(logo)
			setProvider(provider)
			setCustomer(customer)
		}
	}, [accounts, idAcc, showProgressBar])

	const emptyText = '---'
	const classes = 'robotoregular fsz-14 line-h-22 txt-color-black whitespace'
	const columns = [
		{
			title: t('requestDelivery.code'),
			key: 'code',
			dataIndex: 'code',
			width: 120,
			render: (code: string) => (
				<div className='dpl-flex align-items-center gap-6'>
					{lodash.includes(expandedRows, code) ? (
						<i className='far fa-angle-down txt-color-orange2 w-8 line-h-22 cursor-pointer'></i>
					) : (
						<i className='far fa-angle-right txt-color-orange2 w-8 line-h-22 cursor-pointer'></i>
					)}
					<Paragraph
						className='robotomedium mg-0 txt-color-orange1 fsz-14 line-h-22 order-heading whitespace'
						copyable={{
							text: code,
							icon: [
								<Tooltip
									title={
										<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copy</span>
									}
									color='#FBB739'>
									<i className='far fa-copy line-h-14 w-12'></i>
								</Tooltip>,
								<Tooltip
									title={
										<span className='robotoregular fsz-12 txt-color-black line-h-20'>Copied</span>
									}
									color='#FBB739'>
									<i className='far fa-check fsz-12 line-h-14 w-12'></i>
								</Tooltip>,
							],
							tooltips: [false, false],
						}}>
						{code}
					</Paragraph>
				</div>
			),
		},
		{
			title: t('requestDelivery.account'),
			key: 'account',
			width: 250,
			ellipsis: true,
			render: (record: any) => (
				<div className='dpl-flex gap-4 align-items-center whitespace'>
					<Image
						preview={false}
						src={logo}
						className='bd-none w-12 h-12 dpl-flex align-items-center'
					/>
					<span className={classes}>{provider}</span>
					<Divider type={'vertical'} className={'bd-color-black line-h-22 mg-0'} />
					<span className={classes}>{customer}</span>
				</div>
			),
		},
		{
			title: t('requestDelivery.createdAt'),
			key: 'createdAt',
			dataIndex: 'createdAt',
			width: 160,
			render: (createdAt: any) => <span className={classes}>{formatDateTime(createdAt)}</span>,
		},
		{
			title: t('requestDelivery.status'),
			key: 'status',
			dataIndex: 'status',
			width: 160,
			render: (status: any) => {
				const statusObj = statuses.find(({ code }: any) => code === status)
				const statusName = lodash.isNull(lodash.get(statusObj, 'name'))
					? emptyText
					: lodash.get(statusObj, 'name', emptyText)
				return (
					<span
						className='robotoregular fsz-12 line-h-20 txt-color-white border-radius25 py-3 px-12 whitespace'
						style={{
							backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
								? '#09B2AA'
								: lodash.get(statusObj, 'color'),
						}}>
						{statusName}
					</span>
				)
			},
		},
		{
			title: t('requestDelivery.weight'),
			key: 'totalWeight',
			dataIndex: 'totalWeight',
			width: 120,
			align: 'right' as 'right',
			render: (totalWeight: any) => {
				return (
					<span className='robotoregular txt-color-black fsz-14 line-h-22 whitespace'>
						{`${formatNumber(totalWeight)} kg`}
					</span>
				)
			},
		},
		{
			title: t('requestDelivery.address'),
			key: 'address',
			width: '40%',
			ellipsis: true,
			render: (record: string) => (
				<span className='robotoregular txt-color-black fsz-14 line-h-22 whitespace'>
					{`${lodash.get(record, 'address.fullName')}, ${lodash.get(
						record,
						'address.address'
					)},${lodash.get(record, 'address.location.display')}`}
				</span>
			),
		},
	]


	const handleChangePage = (filter: any) => {
		const { page, size, providerUsername } = filter
		handleFilter(
			getCleanFormFilter({
				...filter,
				customer: providerUsername,
				page: (page - 1).toString(),
				size: size.toString(),
			})
		)
	}

	return (
		<div className='white-box dpl-flex flex-col gap-13 pd-imp-12'>
			<div className='dpl-flex align-items-center gap-4 justify-content-between'>
				<div className='dpl-flex align-items-center gap-4'>
					<h3 className='robotomedium mg-0 fsz-16 line-h-24 txt-color-black'>
						{t('requestDelivery.list')}
					</h3>
					<span
					className={`${
						total > 0
							? 'bg-color-primary txt-color-white'
							: 'bg-color-gray-badge txt-color-gray4'
					} border-radius20  fsz-12 line-h-20 px-8`}>
					{total}
				</span>
				</div>
				<Button
					className='robotoregular txt-color-white bg-color-blue7 fsz-14 line-h-22 bd-none border-radius4
													px-12 py-3 search-packages'
					icon={<i className='far fa-plus txt-color-white mg-r-10'></i>} onClick={() => navigate('/delivery/create')}>
					{t('requestDelivery.createRequestDelivery')}
				</Button>
			</div>
			<Table
				rowKey={(record: any) => record.code}
				columns={columns}
				dataSource={items}
				locale={{emptyText: !showProgressBar && <EmptyData text={t('packages.noData')}/>}}
				expandRowByClick={true}
				className='main-table table-expandable selected-table'
				loading={showProgressBar}
				pagination={false}
				expandable={{
					expandedRowRender: (record) => (
						<NestedTable items={packages} loading={loading} record={record} />
					),
					expandIcon: ({ expanded, onExpand, record }) => null,
					columnWidth: 0,
					onExpand: (expanded, record) => {
						if (expanded && !packages[record.code]) {
							setLoading({ ...loading, [record.code]: true })
							deliveryRepository
								.getPackages(
									lodash.get(record, 'code'),
									searchParams.get('customer'),
									searchParams.get('provider')
								)
								.then((res) => {
									setPackages({ ...packages, [record.code]: [...res] })
								})
								.catch((err) => {})
								.finally(() => setLoading({ ...loading, [record.code]: false }))
						}
					},
					onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
				}}
				rowClassName={(record) =>
					lodash.includes(expandedRows, record.code)
						? 'active-row cursor-pointer'
						: 'data-row cursor-pointer'
				}
			/>
			<Row justify={'end'} className={''}>
				{items && items.length > 0 && (
					<M24Pagination
						onPageChange={handleChangePage}
						filter={{ ...getCleanFormFilter(filter), page, size }}
						pagination={{
							current: page,
							pageSize: size,
							hideOnSinglePage: true,
							total,
						}}
						className='mg-0'
					/>
				)}
			</Row>
		</div>
	)
}

export default List