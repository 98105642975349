import {Col, Row} from "antd";
import {formatMoneyByUnit} from "../../../core/helpers/string";
import React from "react";

interface ItemProps{
    label: string;
    labelClass?: string;
    valueClass?: string;
    number?: number| string;
    value: any;
    subValue?: any;
    currencyValue: string;
    currencySubValue?: string;
}
function Item(props: ItemProps){
    const {label, labelClass, number, value, subValue, currencyValue, currencySubValue, valueClass} = props;
    return <Row className={'dpl-flex justify-content-between align-items-center'}>
        <Col  className={'align-items-center dpl-flex'}>
            <span className={`txt-size-h7 txt-color-black ${labelClass}`}>{label}</span>
            <span className={'txt-size-h8 txt-color-secondary mgl5'}>{number?number:''}</span>
        </Col>
        <Col>
            {value||value===0?<Row className={'justify-content-end'}>
                <span className={`txt-size-h7 txt-color-black ${valueClass}`}>{formatMoneyByUnit(value, currencyValue)}</span>
                <span
                    className={'txt-size-h7 txt-color-secondary mgl3'}>{subValue ? `(${formatMoneyByUnit(subValue, currencySubValue)})` : ''}</span>
            </Row>: <span className={'txt-size-h8'}>---</span>
            }
        </Col>
    </Row>
}
export default Item;
