import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";
import {deviceRepository} from "../repositories/DeviceTokenRepository";


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const firebaseMessaging = getMessaging(firebaseApp);

const YOUR_PUBLIC_VAPID_KEY_HERE = process.env.REACT_APP_FIREBAE_VAPID_KEY

getToken(firebaseMessaging, { vapidKey: YOUR_PUBLIC_VAPID_KEY_HERE }).then((currentToken) => {
    console.log('token', currentToken)
    if (currentToken) {
        deviceRepository.register({
            token: currentToken
        })
            .then(response => {
                console.log(response.data)
            })
            .catch(error => {
                console.log('error', error)
            })
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
});

export {
    firebaseConfig,
    firebaseApp,
    firebaseMessaging
}

