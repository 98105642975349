import {Select, Spin} from "antd";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {ThreadItem} from "./ThreadItem";
import moment from "moment";
import {BoxChatContext} from "../../context/BoxChatContext";

interface FilterInterface {
    isRead?: boolean,
    date?: string,
    timestampFrom?: string
    timestampTo?: string
}

export const Thread = () => {
    const [filter, setFilter] = useState<FilterInterface>({
        isRead: undefined,
        date: undefined
    })
    const {threads, threadLoading, onFilterThreads, onThreadScroll} = useContext(BoxChatContext)
    const {t} = useTranslation()

    const dates = [
        {code: '1week', name: t('chatBox.filterTimestamp1week')},
        {code: '3weeks', name: t('chatBox.filterTimestamp3weeks')},
        {code: '1month', name: t('chatBox.filterTimestamp1month')},
        {code: '2months', name: t('chatBox.filterTimestamp2months')},
        {code: '3months', name: t('chatBox.filterTimestamp3months')},
    ]

    const readStatuses = [
        {code: 'all', name: t('chatBox.filterStatusAll')},
        {code: true, name: t('chatBox.filterStatusRead')},
        {code: false, name: t('chatBox.filterStatusUnRead')}
    ]

    const handleFilterChanged = (field: string, value: any) => {
        setFilter(prevState => {
            const newFilter = {...prevState, [field]: value}

            if (field === 'date') {
                if (value === '1week') {
                    newFilter.timestampFrom = moment().subtract(1, 'week').toISOString()
                    newFilter.timestampTo = moment().toISOString()
                }
                else if (value === '3weeks') {
                    newFilter.timestampFrom = moment().subtract(3, 'weeks').toISOString()
                    newFilter.timestampTo = moment().toISOString()
                }
                else if (value === '1month') {
                    newFilter.timestampFrom = moment().subtract(1, 'month').toISOString()
                    newFilter.timestampTo = moment().toISOString()
                }
                else if (value === '2months') {
                    newFilter.timestampFrom = moment().subtract(2, 'months').toISOString()
                    newFilter.timestampTo = moment().toISOString()
                }
                else if (value === '3months') {
                    newFilter.timestampFrom = moment().subtract(3, 'months').toISOString()
                    newFilter.timestampTo = moment().toISOString()
                }
            }

            if (field === 'date' || field === 'isRead') {
                onFilterThreads({
                    ...newFilter
                })
            }

            return {...newFilter}
        })
    }

    const handleScroll = (e: any) => {
        onThreadScroll(e)
    }

    return (
        <div className="box-chat-thread">
            <Spin spinning={threadLoading}>
                <div id="box-chat-thread__filter" className="box-chat-thread__filter">
                    <Select
                        size={'small'}
                        placeholder={t('chatBox.filterTimestamp')}
                        value={filter.date}
                        onChange={value => handleFilterChanged('date', value)}
                        className={'box-chat-thread__filter__item mgr5'}
                        allowClear={true}
                        //@ts-ignore
                        getPopupContainer={() => document.getElementById('box-chat-thread__filter')}
                    >
                        {dates.map(d => (
                            <Select.Option key={d.code} value={d.code}>{d.name}</Select.Option>
                        ))}
                    </Select>

                    <Select
                        size={'small'}
                        placeholder={t('chatBox.filterStatus')}
                        value={filter.isRead}
                        allowClear={true}
                        onChange={value => handleFilterChanged('isRead', value)}
                        className={'box-chat-thread__filter__item'}
                        //@ts-ignore
                        getPopupContainer={() => document.getElementById('box-chat-thread__filter')}
                    >
                        {readStatuses.map(s => (
                            <Select.Option key={s.name} value={s.code}>{s.name}</Select.Option>
                        ))}
                    </Select>
                </div>

                <div className="box-chat-thread__threads" onScroll={handleScroll}>
                    {threads.map((item) => (
                        <ThreadItem key={item.id} thread={item} />
                    ))}
                </div>
            </Spin>
        </div>
    )
}
