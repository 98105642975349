import { Button, Divider, Image, Table } from 'antd';
import { t } from 'i18next';
import lodash from 'lodash';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EmptyData from '../../../components/EmptyData';
import MainLayout from '../../../components/Layout';
import { formatMoneyByUnit } from '../../../core/helpers/string';

const Success: React.FC = () => {
	const location = useLocation();
	const { dataMakeRequest }: any = location.state;
	const navigate = useNavigate();
	const orders = lodash.flattenDeep(lodash.map(dataMakeRequest, 'orders'));
	const selectedOrders = lodash.flattenDeep(lodash.map(dataMakeRequest, 'selectedOrders'));
	const orderSucceed = orders.filter((x: any) => lodash.includes(selectedOrders, x.code));

	const columnsOrderList = [
		{
			title: (
				<span className='robotomedium txt-color-black fsz-14 line-h-22 whitespace'>
					{t('delivery.orders')}
				</span>
			),
			key: 'orderCode',
			render: (record: any) => {
				return <span className='txt-color-orange2 robotoregular fsz-14 line-h-22'>{record.code}</span>;
			},
		},
		{
			title: (
				<span className='robotomedium txt-color-black fsz-14 line-h-22 whitespace'>
					{t('delivery.statusSuccessPage')}
				</span>
			),
			key: 'success',
			align: 'right' as 'right',
			render: (record: any) => {
				const requestByAccount = dataMakeRequest.find((x: any) => lodash.includes(x.selectedOrders, record.code))
				return <span className={`${lodash.get(requestByAccount, 'success') ? 'bg-color-blue5' : 'bg-color-gray4'} txt-color-white  fsz-12 line-h-20 robotoregular border-radius25 px-12 py-5`}>
					{lodash.get(requestByAccount, 'success') ? t('delivery.successOrder') : t('delivery.failedOrder')}
				</span>
			},
		},
	];
	const columnsNeedToPaid = [
		{
			title: (
				<span className='robotomedium txt-color-black fsz-14 line-h-22 whitespace'>
					{t('delivery.accountSuccess')}
				</span>
			),
			key: 'account',
			render: (record: any) => {
				const temp = JSON.parse(lodash.get(record, 'account.connection.provider.currency'));
				const {code} = temp
				return (
					<span className='dpl-flex gap-27 align-items-center'>
						<span className='dpl-flex gap-4 align-items-center txt-color-black fsz-14 robotoregular'>
							<Image
								preview={false}
								src={lodash.get(record, 'account.connection.provider.logo', '')}
								className='bd-none w-16 h-16 dpl-flex align-items-center'
							/>
							<span className='robotomedium fsz-14 line-h-22'>
								{lodash.get(record, 'account.connection.provider.code', '---')}
							</span>
							<Divider type={'vertical'} className={'bd-color-black line-h-22 mg-0'} />
							<span className='robotomedium fsz-14 line-h-22'>
								{lodash.get(record, 'account.connection.name', '---')}
							</span>
						</span>
					</span>
				);
			},
		},
		{
			title: (
				<span className='robotomedium txt-color-black fsz-14 line-h-22 whitespace'>
					{t('delivery.needChardCol')}
				</span>
			),
			key: 'cash',
			align: 'right' as 'right',
			render: (record: any) => {
				const temp = JSON.parse(lodash.get(record, 'account.connection.provider.currency'));
				const {code} = temp
				return <span className='txt-color-red2 fsz-14 line-h-22 robotoregular'>
					{lodash.get(record, 'needCharged') > 0
						? formatMoneyByUnit(0, code)
						: formatMoneyByUnit(Math.abs(lodash.get(record, 'needCharged')), code)}
				</span>
			},
		},
	];
	return (
		<MainLayout title={t('delivery.success')}>
			<div className='dpl-flex gap-12 flex-col'>
				<div className='dpl-flex gap-12'>
					<div className='success-delivery-box flex-basis-20pc py-12'>
						<i className='far fa-check-circle txt-color-orange3 fa-3x mg-bt-10'></i>
						<div className='dpl-flex flex-col align-items-center justify-content-center'>
							<span className='robotomedium fsz-16 line-h-24 txt-color-black mg-bt-16'>
								{t('delivery.success')}
							</span>
							<Button
							onClick={() => navigate('/delivery')}
								className='btn-create-success robotomedium txt-color-white fsz-14 line-h-22 border-radius4 py-9 px-14 dpl-flex align-items-center gap-10'
								icon={<i className='txt-color-white far fa-arrow-right'></i>}>
								{t('delivery.successBack')}
							</Button>
						</div>
					</div>
					<div className='flex-basis-80pc dpl-flex flex-col gap-12'>
						<div className='white-box min-h-152'>
							<h3 className='fsz-16 line-h-24 robotomedium txt-color-black'>
								{lodash.map(dataMakeRequest, 'needCharged').filter((x: any) => x > 0).length > 0
									? t('delivery.noNeedToCharged')
									: t('delivery.needChargedInfo')}
							</h3>
							<Table
								locale={{ emptyText: <EmptyData text={t('delivery.dontNeedChargedCash')} /> }}
								columns={columnsNeedToPaid}
								dataSource={dataMakeRequest}
								className='main-table'
								pagination={false}
							/>
						</div>
							<div className="white-box">
								<Table
								locale={{ emptyText: <EmptyData text={t('delivery.orderRequest')} /> }}
								dataSource={orderSucceed}
								columns={columnsOrderList}
								className='main-table'
								pagination={false}
														/>
							</div>
					</div>
				</div>
			</div>
		</MainLayout>
	);
};

export default Success;