import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Form, Input, Modal, notification, Row, Select, Spin, Upload} from "antd";
import MainLayout from "../../components/Layout";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ConnectionInterface} from "../../Interface/ConnectionInterface";
import {supplierRepository} from "../../repositories/SupplierRepository";
import {Provider} from "../../components/Provider";
import {claimRepository} from "../../repositories/ClaimRepository";
import {ClaimSolutionInterface} from "../../Interface/ClaimSolutionInterface";
import InputMoney, {InputMoneySeparatorType} from "../../components/InputMoney";
import {ClaimReasonInterface} from "../../Interface/ClaimReasonInterface";
import {getConnectionById, getConnectionByNameAndProviderCode} from "../../utils/util";
import {UploadFile} from "antd/es/upload/interface";
import {UploadChangeParam} from "antd/lib/upload/interface";
import {attachmentRepository} from "../../repositories/AttachmentRepository";
import lodash from "lodash";
import {MAX_UPLOAD_SIZE} from "../../core/config";
import {ClaimCreateInterface} from "../../Interface/ClaimCreateInterface";


const {TextArea} = Input;

const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const MAX_FILE_UPLOAD = 10

interface ClaimUploadedFile {
    uid: string
    id: string
}

const generateClaimName = (t: any, orderCode: string, productCode: string) => {
    let name = t('ticket-create.default_name') + ' ' + orderCode
    if (productCode) name += ', ' + t('ticket-create.default_name_with_product') + ' ' + productCode
    return name
}

const defaultForm: ClaimCreateInterface = {
    attachments: [],
    description: "",
    name: "",
    owner: "",
    provider: "",
    reason: "",
    reasonData: {},
    relatedOrder: "",
    relatedProduct: "",
    solution: "",
    suggest: 0,
    ticketType: ""
}

interface ErrorInterface {
    connectionId: any
    relatedOrder: any
    reason: any
    reasonData: {
        notReceived: any
    }
    description: any
    suggest: any
    solution: any
    uploadFile: any
}

const defaultError: ErrorInterface = {
    connectionId: undefined,
    description: undefined,
    reason: undefined,
    reasonData: {notReceived: undefined},
    relatedOrder: undefined,
    suggest: undefined,
    solution: undefined,
    uploadFile: undefined
}

const ClaimCreate = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [connectionId, setConnectionId] = useState("")
    const [connections, setConnections] = useState<ConnectionInterface[]>([])
    const [solutions, setSolutions] = useState<ClaimSolutionInterface[]>([])
    const [reasons, setReasons] = useState<ClaimReasonInterface[]>([])
    const [orderReasons, setOrderReasons] = useState<ClaimReasonInterface[]>([])
    const [productReasons, setProductReasons] = useState<ClaimReasonInterface[]>([])
    const [uploading, setUploading] = useState<boolean>(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [loading, setLoading] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState<ClaimUploadedFile[]>([])
    const [form, setForm] = useState<ClaimCreateInterface>(defaultForm)
    const [error, setError] = useState<ErrorInterface>(defaultError)
    const [previewFile, setPreviewFile] = useState<any>()
    const [isShowPreviewFile, setIsShowPreviewFile] = useState(false)
    const [searchParams, ] = useSearchParams()
    const isShowMissingQuantity = form.reason === 'not_received'

    useEffect(() => {
        setForm({...defaultForm})
        supplierRepository.getListAccount().then(response => {
            setConnections(response);
        })
    }, [])

    useEffect(() => {
        if (connectionId) {
            const connection = getConnectionById(connections, connectionId!)

            claimRepository.getReasons(connection.provider.code, {type: "ORDER"})
                .then(response => setOrderReasons(response))
                .catch(() => setOrderReasons([]))

            claimRepository.getReasons(connection.provider.code, {type: "PRODUCT"})
                .then(response => setProductReasons(response))
                .catch(() => setProductReasons([]))

            claimRepository.getSolutions(connection.provider.code)
                .then(response => setSolutions(response))
                .catch(() => setSolutions([]))
        }
    }, [connectionId])

    useEffect(() => {
        const connection = getConnectionByNameAndProviderCode(
            connections,
            searchParams.get('customerCode')!,
            searchParams.get('providerCode')!
        )

        if (connection.id) {
            setConnectionId(connection.id)
            setForm({
                ...form,
                relatedOrder: searchParams.get('orderCode') || ""
            })
        }
    }, [searchParams, connections])

    useEffect(() => {
        const currentReason = lodash.find(reasons, item => item.code === form.reason)
        if (currentReason) {
            if (form.relatedOrder && !form.relatedProduct && currentReason.type === 'PRODUCT') {
                form.reason = ""
                setForm({...form})
            }
            else if (form.relatedProduct && currentReason.type === 'ORDER') {
                form.reason = ""
                setForm({...form})
            }
        }

        if (form.reason !== 'faulty_product') {
            resetError('uploadFile')
        }
    }, [form])

    useEffect(() => {
        if (form.relatedOrder) {
            if (!form.relatedProduct) {
                setReasons([...orderReasons])
            }
        }

        if (form.relatedProduct) {
            setReasons([...productReasons])
        }

    }, [connectionId, searchParams, orderReasons, productReasons, form])

    const handleUploadFile = () => {
        return false
    }

    const handleChangeFile = (info: UploadChangeParam<any>) => {
        resetError('uploadFile')
        if (info.fileList.length > MAX_FILE_UPLOAD) {
            notification.error({
                message: t('ticket-create.error_max_upload_file'),
                key: 'upload_error'
            })
            return false
        }

        const connection = getConnectionById(connections, connectionId!)
        if (!connection.id) {
            notification.error({
                message: t('ticket-create.select_provider_before_upload'),
                key: 'upload_error'
            })
            return false
        }

        if (info.file.size > MAX_UPLOAD_SIZE * 1024 * 1024) {
            notification.error({
                message: t('ticket-create.error_max_upload_file_size'),
                key: 'upload_error'
            })
            return false
        }

        setFileList(info.fileList)

        if (info.file instanceof File) {
            setUploading(true)

            attachmentRepository.create({
                provider: connection.provider.code,
                attachments: [info.file]
            })
                .then((response) => {
                    uploadedFiles.push({
                        uid: info.file.uid,
                        id: response.data[0]
                    })
                    setUploadedFiles(uploadedFiles)
                })
                .finally(() => setUploading(false))
        }

        if (info.file.status === 'removed') {
            setUploadedFiles(uploadedFiles.filter(f => f.uid !== info.file.uid))
        }

        return false
    }

    const handleCancelCreateTicket = () => {
        setForm({...defaultForm})
        navigate('/claims')
    }

    const handleChangeForm = (field: string, value: any) => {
        resetError(field)
        if (field === 'reason' && value !== 'other') {
            resetError('description')
        }

        lodash.set(form, field, value)
        setForm({
            ...form
        })
    }

    const validateForm = () => {
        let hasError = false
        if (!connectionId) {
            error.connectionId = t('ticket-create.error_require_provider')
            hasError = true
        }

        if (!form.relatedOrder) {
            error.relatedOrder = t('ticket-create.error_require_order_code')
            hasError = true
        }

        if (!form.reason) {
            error.reason = t('ticket-create.error_require_reason')
            hasError = true
        }

        if (form.reason === 'not_received' && !form.reasonData?.notReceived) {
            error.reasonData.notReceived = t('ticket-create.error_require_missing_quantity')
            hasError = true
        }

        if (form.reason === 'other' && !form.description) {
            error.description = t('ticket-create.error_require_description')
            hasError = true
        }

        if (!form.solution) {
            error.solution = t('ticket-create.error_require_resolve_suggestion')
            hasError = true
        }

        if (form.solution === 'compensate' && !form.suggest) {
            error.suggest = t('ticket-create.error_suggest_must_not_be_null')
            hasError = true
        }

        if (form.reason === 'faulty_product' && (!uploadedFiles.length)) {
            error.uploadFile = t('ticket-create.create_error_files_must_be_uploaded')
            hasError = true
        }

        setError({...error})

        return hasError
    }

    const handlePreviewFile = (file: any) => {
        setPreviewFile(file)
        setIsShowPreviewFile(true)
    }

    const resetError = (key: string) => {
        lodash.set(error, key, undefined)
        setError({...error})
    }

    const handleSubmit = () => {
        const hasError = validateForm()
        if (!hasError) {
            setLoading(true)
            const connection = getConnectionById(connections, connectionId!)
            const data = {
                name: generateClaimName(t, form.relatedOrder, form.relatedProduct),
                relatedProduct: form.relatedProduct,
                relatedOrder: form.relatedOrder,
                reason: form.reason,
                reasonData: form.reasonData,
                solution: form.solution,
                suggest: form.suggest,
                owner: connection.name,
                description: form.description,
                provider: connection.provider.code,
                ticketType: 'order',
                attachments: uploadedFiles.map(f => f.id)
            }

            if (['return', 'redelivery'].indexOf(data.solution) >= 0) {
                lodash.unset(data, 'suggest')
            }

            claimRepository.create(data)
                .then(() => {
                    setLoading(false)
                    setForm({...defaultForm})
                    setConnectionId("")

                    notification.success({
                        message: t('ticket-create.create_success')
                    })

                    navigate(searchParams.get('back') || '/claims')
                })
                .catch(error => {
                    setLoading(false)
                    if (lodash.get(error, 'response.status') === 400) {
                        notification.error({
                            message: t(`ticket-create.create_error_${lodash.get(error, 'response.data.title')}`)
                        })
                    }
                    else if (lodash.get(error, 'response.status') === 404) {
                        notification.error({
                            message: t(`ticket-create.create_error_${lodash.get(error, 'response.data.title')}`)
                        })
                    }
                    else {
                        notification.error({
                            message: t('ticket-create.create_error')
                        })
                    }
                })
        }
    }

    return (
        <MainLayout title={t('tickets.createTicketTittle')}>
            <div className={'ticket-container'}>
                <Row className={''}>
                    <Col span={5}/>
                    <Col span={14} className={'pd12 border-card-header border-radius4'}>
                        <Row justify={'space-around'} align={'middle'}>
                            <Col span={9}><span className={'horizontal-line width100pc'}/></Col>
                            <Col span={6} className={'txt-center'}><span className={'txt-size-h5 robotomedium align-items-center txt-center txt-capitalize txt-color-black'}>{t('tickets.createTicketTittle')}</span></Col>
                            <Col span={9}><span className={'horizontal-line'}/></Col>
                        </Row>
                        <Form
                            {...formLayout}
                            className={'mgt16 create-ticket-form-custom'}
                            layout={'horizontal'}
                            colon={false}
                        >
                            <Form.Item
                                label={<span className={'txt-size-h7 txt-color-black robotomedium'}>{t('tickets.providerAccounts')}: <span className={'txt-color-red'}>*</span></span>}
                                validateStatus={error.connectionId ? 'error': ''}
                                help={error.connectionId}
                            >
                                <Select
                                    className={'select-layout'}
                                    placeholder={t('tickets.select-provider-account')}
                                    value={connectionId || undefined}
                                    onChange={value => {
                                        setConnectionId(value)
                                        resetError('connectionId')
                                    }}
                                >
                                    {connections.map(item => (
                                        <Select.Option key={item.id} value={item.id}>
                                            <Provider provider={item.provider} customerUsername={item.name} />
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={<span className={'txt-size-h7 txt-color-black robotomedium'}>{t('tickets.orderCode')}: <span className={'txt-color-red'}>*</span></span>}
                                validateStatus={error.relatedOrder ? 'error' : ''}
                                help={error.relatedOrder}>
                                <Input
                                    className={'input-layout'}
                                    placeholder={t('tickets.orderCode')}
                                    value={form.relatedOrder}
                                    onChange={e => handleChangeForm('relatedOrder', e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<span className={'txt-size-h7 txt-color-black robotomedium'}>{t('tickets.productsCode')}: </span>}
                            >
                                <Input
                                    className={'input-layout'}
                                    placeholder={t('tickets.productsCode')}
                                    value={form.relatedProduct}
                                    onChange={e => handleChangeForm('relatedProduct', e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<span className={'txt-size-h7 txt-color-black robotomedium'}>{t('tickets.reason')}: <span className={'txt-color-red'}>*</span></span>}
                                validateStatus={error.reason ? 'error' : ''}
                                help={error.reason}
                            >
                                <Select
                                    disabled={!connectionId}
                                    placeholder={t('ticket-create.select-claim-reason')}
                                    className={'select-layout'}
                                    value={form.reason || undefined}
                                    onChange={value => handleChangeForm('reason', value)}
                                >
                                    {reasons.map(item => (
                                        <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {isShowMissingQuantity && (
                                <Form.Item
                                    label={<span className={'txt-size-h7 txt-color-black robotomedium'}>{t('ticket-create.missing_quantity')}: <span className={'txt-color-red'}>*</span></span>}
                                    validateStatus={error.reasonData.notReceived ? 'error' : ''}
                                    help={error.reasonData.notReceived}
                                >
                                    <Input
                                        placeholder={t('ticket-create.missing_quantity')}
                                        value={lodash.get(form, 'reasonData.notReceived')}
                                        onChange={e => handleChangeForm('reasonData.notReceived', e.target.value)}
                                    />
                                </Form.Item>
                            )}

                            <Form.Item
                                label={<span className={'txt-size-h7 txt-color-black robotomedium'}>{t('tickets.description')}: {form.reason === 'other' && <span className={'txt-color-red'}>*</span>}</span>}
                                validateStatus={error.description ? 'error' : ''}
                                help={error.description}
                            >
                                <TextArea
                                    rows={2}
                                    className={'text-area-layout'}
                                    placeholder={t('tickets.fill-description') + '...'}
                                    value={form.description}
                                    onChange={e => handleChangeForm('description', e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<span className={'txt-size-h7 txt-color-black robotomedium'}>{t('tickets.resolvesSuggestion')}: <span className={'txt-color-red'}>*</span></span>}
                                validateStatus={error.solution ? 'error' : ''}
                                help={error.solution}>
                                <Select
                                    disabled={!connectionId}
                                    className={'select-layout'}
                                    placeholder={t('ticket-create.select-claim-solution')}
                                    value={form.solution || undefined}
                                    onChange={value => handleChangeForm('solution', value)}
                                >
                                    {solutions.map(item => (
                                        <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {form.solution === 'compensate' && (
                                <Form.Item
                                    label={<span className={'txt-size-h7 txt-color-black robotomedium'}>{t('tickets.moneySuggestion')}: <span className={'txt-color-red'}>*</span></span>}
                                    validateStatus={error.suggest ? 'error' : ''}
                                    help={error.suggest}
                                >
                                    <InputMoney
                                        className={'input-layout'}
                                        style={{width: '100%'}}
                                        placeholder={t('tickets.fill-money-suggestion') + '...'}
                                        thousandSeparator={InputMoneySeparatorType.comma}
                                        suffix={"đ"}
                                        value={form.suggest}
                                        onChange={value => handleChangeForm('suggest', value)}
                                    />
                                </Form.Item>
                            )}
                            <Form.Item
                                label={<span className={'txt-size-h7 txt-color-black robotomedium'}>{t('tickets.uploadFiles')}: </span>}
                                validateStatus={error.uploadFile ? 'error' : ''}
                                help={error.uploadFile}
                            >
                                <Upload
                                    className="avatar-uploader claim-upload-file"
                                    listType={"picture-card"}
                                    accept={"image/*"}
                                    beforeUpload={handleUploadFile}
                                    onChange={handleChangeFile}
                                    showUploadList={true}
                                    onPreview={handlePreviewFile}
                                    fileList={fileList}
                                    multiple={true}
                                >
                                    <Spin spinning={uploading}>
                                        <span className={'txt-size-h7 robotoregular txt-color-gray'}><i className={'far fa-plus'}/> Upload</span>
                                    </Spin>
                                </Upload>
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                                <div className={'mgl15'}>
                                    <p className={'txt-size-h8 robotoregular mgbt5 txt-color-black'}><span className={'txt-color-red'}>*</span> {t('tickets.upload-required')}</p>
                                    <p className={'txt-size-h8 robotoregular mgbt5 txt-color-black'}><span className={'txt-color-red'}>*</span> {t('tickets.file-size--upload-maximum')}</p>
                                    <p className={'txt-size-h8 robotoregular mgbt5 txt-color-black'}><span className={'txt-color-red'}>*</span> {t('tickets.img-upload-maximum')}</p>
                                </div>
                            </Form.Item>
                            <Row justify={'end'} className={'pdbt6'}>
                                <Form.Item>
                                    <Button disabled={loading} loading={loading} className={'btn_cancel cancel-create-btn mgr5'} onClick={handleCancelCreateTicket}>
                                        {t('tickets.btnCancel')}
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={handleSubmit} className={'create-ticket-btn'} loading={loading} disabled={loading} htmlType={'submit'}>
                                        <span className={'txt-size-h7 robotoregular'}>{t('tickets.btnCreate')}</span>
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>

            <Modal
                visible={isShowPreviewFile}
                footer={false}
                onCancel={() => {
                    setIsShowPreviewFile(false)
                    setPreviewFile(null)
                }}
            >
                <img className={'width100'} src={previewFile && previewFile.thumbUrl} />
            </Modal>
        </MainLayout>
    );
}

export default ClaimCreate;
