import { CheckOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Divider, Drawer, Empty, List, Row, Spin
} from 'antd';
import { Select } from 'antd/lib/index';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TENANT_KEY } from '../../../core/config';
import {
	formatMoneyByUnit
} from '../../../core/helpers/string';
import { cartRepository } from '../../../repositories/CartRepository';
import { orderRepository } from '../../../repositories/OrderRepository';
import { supplierRepository } from '../../../repositories/SupplierRepository';
import { localStorageRead } from '../../../utils/LocalStorageUtils';
import M24ErrorUtils from '../../../utils/M24ErrorUtils';
import M24Notification from '../../../utils/M24Notification';
import InlineEdit from '../../InlineEdit';
import ConfirmModal from '../../Modal/ConfirmModal';
import Address from '../Address/AddressList';
import CreateAddressModal from '../Address/CreateAddressModal';
import Services from '../Services';
import Product from './Product';
import './styles.scss';

export interface Step2Props {
	merchant: any;
	setMerchant: (merchant: any) => void;
	setStep: (value: number) => void;
	accountBalance: any[];
	handleCreateOrder: (body: any, callback: () => void) => void;
}

function Step2(props: Step2Props) {
	const { merchant, accountBalance, setMerchant, setStep, handleCreateOrder } =
		props;
	const { t } = useTranslation();
	const [accountDefault, setAccountDefault] = useState<any>();
	const [isFirst, setIsFirst] = useState<boolean>(true);
	const [exchangeRate, setExchangeRate] = useState<any>();
	const [totalPrice, setTotalPrice] = useState<number>();
	const [services, setServices] = useState<any>([]);
	const [feeCategories, setFeeCategories] = useState<any>([]);

	const [currentServiceSelected, setCurrentServiceSelected] = useState<any>([]);
	const [serviceSelectedCache, setServiceSelectedCache] = useState<any>({});
	const [serviceGroups, setServiceGroups] = useState<any>([]);
	const [favoriteServices, setFavoriteServices] = useState<any>([]);
	const [favoriteUpdating, setFavoriteUpdating] = useState(false);
	const [confirmFavorite, setConfirmFavorite] = useState(false);
	const [noticeFavoriteService, setNoticeFavoriteService] = useState<any>();

	const [currency, setCurrency] = useState<string>();
	const [showDrawer, setShowDrawer] = useState(false);
	const [employeeNote, setEmployeeNote] = useState<string>('');
	const [personalNote, setPersonalNote] = useState<string>('');
	const [fees, setFees] = useState<any>();
	const [noticeWarehouse, setNoticeWarehouse] = useState<any>();
	const [openAddressList, setOpenAddressList] = useState<boolean>(false);
	const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
	const [addressList, setAddressList] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [creatingOrder, setCreatingOrder] = useState<boolean>(false);
	const [selectedAddress, setSelectedAddress] = useState<any>();
	const [deliveryAddress, setDeliveryAddress] = useState<any>(null);
	const [addressSupported, setAddressSupported] = useState<boolean>(false);
	const [currentConfigShowBalance, setCurrentConfigShowBalance] = useState();

	useEffect(() => {
		getAddressList();
		getFavoriteServices();
	}, []);
	// console.log(serviceGroups);

	useEffect(() => {
		setCurrentConfigShowBalance(lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', '') );
	}, []);

	useEffect(() => {
		setCurrency(lodash.get(merchant, 'products[0].skus[0].currency', ''));
		if (accountBalance && accountBalance.length > 0) {
			let accDefault = accountBalance.find(
				(x) => x.connection.defaultConnection
			);
			if (accDefault) {
				setAccountDefault(accDefault);
			}
		}
	}, []);
	useEffect(() => {
		if (accountDefault) {
			getExchangeRate(accountDefault);
			getServices();
			getOrderFees();
			if (deliveryAddress) validAddress();
		}
	}, [accountDefault]);
	useEffect(() => {
		if (accountDefault) {
			let tempService = lodash.get(
				serviceSelectedCache,
				accountDefault.connection.id,
				[]
			);
			if (!tempService || tempService.length === 0) {
				tempService = services.filter((xx: any) => xx.defaultApplied);
				let temp = lodash.cloneDeep(serviceSelectedCache);
				let favorite = favoriteServices.find(
					(x: any) =>
						x.provider === accountDefault.connection.provider.code &&
						x.customer === accountDefault.connection.name
				);
				// console.log(favorite);
				if (favorite) {
					let isConflict = false;
					let items: any = [];
					favorite.services.map((x: string) => {
						let temp = services.find((xx: any) => xx.code === x);
						if (!temp) isConflict = true;
						else items.push(temp);
					});
					if (!isConflict) {
						setCurrentServiceSelected(items);
						temp[accountDefault.connection.id] = items;
						setServiceSelectedCache(temp);
					} else {
						setNoticeFavoriteService(true);
						temp[accountDefault.connection.id] = tempService;
						setServiceSelectedCache(temp);
						setCurrentServiceSelected(tempService);
					}
				} else {
					temp[accountDefault.connection.id] = tempService;
					setServiceSelectedCache(temp);
					setCurrentServiceSelected(tempService);
				}
			} else {
				setCurrentServiceSelected(tempService);
			}
		}
	}, [services]);
	useEffect(() => {
		estimateFee();
	}, [currentServiceSelected]);
	useEffect(() => {
		if (deliveryAddress) {
			if (!isFirst) estimateFee();
			validAddress();
		}
	}, [deliveryAddress]);
	const getFavoriteServices = () => {
		cartRepository
			.getFavoriteServices()
			.then((res) => {
				setFavoriteServices(res);
			})
			.catch((err) => {});
	};
	const updateFavoriteService = () => {
		setFavoriteUpdating(true);
		let items: string[] = [];
		currentServiceSelected.map((x: any) => items.push(x.code));
		let body: any = {
			services: items,
			provider: lodash.get(accountDefault, 'connection.provider.code', ''),
			providerUsername: lodash.get(accountDefault, 'connection.name', ''),
		};
		cartRepository
			.updateFavoriteServices(body)
			.then((res) => {
				M24Notification.messageSuccess(t('message.saveFavoriteServiceSuccess'));
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err);
				getFavoriteServices();
			})
			.finally(() => {
				setFavoriteUpdating(false);
				setConfirmFavorite(false);
			});
	};
	const validAddress = () => {
		setIsLoading(true);
		setAddressSupported(false);
		if (!accountDefault) return;
		cartRepository
			.checkAddressSupport({
				location: deliveryAddress.location,
				provider: lodash.get(accountDefault, 'connection.provider.code', ''),
			})
			.then((response) => {
				setAddressSupported(true);
			})
			.catch((err) => {
				// console.log(err)
				M24ErrorUtils.showError(t, err, {
					value: lodash.get(accountDefault, 'connection.provider.name', ''),
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	useEffect(() => {
		if (exchangeRate) {
			let totalPrice: number = 0;
			let totalSku = 0;
			merchant.products.map((product: any) => {
				let total: number = 0;
				let price: any = 0;
				product.skus.map((xx: any) => {
					total = total + xx.quantity;
					totalSku = totalSku + xx.quantity;
				});
				if (product.pricePolicies && product.pricePolicies.length > 0) {
					let pricePolicies = lodash.sortBy(product.pricePolicies, [
						'minQuantity',
					]);
					if (total < pricePolicies[0].minQuantity) {
						price = pricePolicies[0].salePrice;
					} else if (
						total > pricePolicies[pricePolicies.length - 1].maxQuantity
					) {
						price = pricePolicies[pricePolicies.length - 1].salePrice;
					} else {
						pricePolicies.map((x: any) => {
							if (total >= x.minQuantity && total <= x.maxQuantity) {
								price = x.salePrice;
							}
						});
					}
					totalPrice += total * price;
				} else {
					product.skus.map((xx: any) => {
						totalPrice += xx.salePrice * xx.quantity;
					});
				}
				setTotalPrice(totalPrice);
			});
		}
	}, [exchangeRate]);
	const [totalSKU, setTotalSKU] = useState<number>(0);
	const [totalLink, setTotalLink] = useState<number>(0);

	const getExchangeRate = (account: any) => {
		if (currency) {
			supplierRepository
				.getExchangeRateByAccount(currency, account?.connection?.id)
				.then((res) => {
					if (res.length > 0) setExchangeRate(res[0]);
				})
				.catch((err) => {
					M24ErrorUtils.showError(t, err);
				});
		}
	};
	const getOrderFees = () => {
		orderRepository
			.getOrderFees(
				lodash.get(accountDefault, 'connection.name', ''),
				lodash.get(accountDefault, 'connection.provider.code', '')
			)
			.then((res) => {
				setFeeCategories(res);
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err);
			});
	};
	const getServices = () => {
		orderRepository
			.getServices(
				lodash.get(accountDefault, 'connection.name', ''),
				lodash.get(accountDefault, 'connection.provider.code', '')
			)
			.then((res) => {
				let otherServices = lodash.sortBy(
					res.filter((x: any) => !x.serviceGroup),
					'position'
				);
				let services = lodash.sortBy(
					res.filter((x: any) => x.serviceGroup),
					'position'
				);
				let items = lodash
					.chain(services)
					.groupBy('serviceGroup.code')
					.map((value, key) => {
						let group = value[0].serviceGroup;
						return { ...group, services: value };
					})
					.value();
				let temp: any = [];
				temp.push({
					code: 'other',
					name: t('shopping-cart.otherService'),
					services: otherServices,
				});
				temp = temp.concat(items);
				setServices(res);
				setServiceGroups(temp);
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err);
			});
	};
	const estimateFee = () => {
		if (!accountDefault) return;
		setNoticeWarehouse(null);
		let body: any = {
			merchantId: lodash.get(merchant, 'merchant.id'),
		};
		let services: string[] = [];
		currentServiceSelected.map((x: any) => {
			services.push(x.code);
		});
		body.services = services;
		let skus: any = [];
		merchant.products.map((product: any) => {
			let total: number = 0;
			let price: any = 0;
			product.skus.map((sku: any) => {
				total = total + sku.quantity;
			});
			if (product.pricePolicies && product.pricePolicies.length > 0) {
				let pricePolicies = lodash.sortBy(product.pricePolicies, [
					'minQuantity',
				]);
				if (total < pricePolicies[0].minQuantity) {
					price = pricePolicies[0].salePrice;
				} else if (
					total > pricePolicies[pricePolicies.length - 1].maxQuantity
				) {
					price = pricePolicies[pricePolicies.length - 1].salePrice;
				} else {
					pricePolicies.map((x: any) => {
						if (total >= x.minQuantity && total <= x.maxQuantity) {
							price = x.salePrice;
						}
					});
				}
				product.skus.map((xx: any) => {
					skus.push({
						skuId: xx.id,
						orderedQuantity: xx.quantity,
						actualPrice: price,
					});
				});
			} else {
				product.skus.map((xx: any) => {
					skus.push({
						skuId: xx.id,
						orderedQuantity: xx.quantity,
						actualPrice: xx.salePrice,
					});
				});
			}
		});
		body.skus = skus;
		body.location = lodash.get(deliveryAddress, 'location', '');
		// console.log(body);
		orderRepository
			.estimateFee(
				lodash.get(accountDefault, 'connection.name', ''),
				lodash.get(accountDefault, 'connection.provider.code', ''),
				body
			)
			.then((res) => {
				setFees(res);
				if (res.notices) {
					let temp = res.notices.find(
						(x: any) => x.title === 'not_preferred_warehouse'
					);
					if (temp) {
						setNoticeWarehouse(lodash.get(temp, 'reference.name', ''));
					}
				}
			})
			.catch((err) => {
				// M24ErrorUtils.showError(t, err);
			})
			.finally(() => setIsFirst(false));
	};
	useEffect(() => {
		if (merchant && merchant.products && merchant.products.length > 0) {
			let totalSKU = 0;
			let totalLink = 0;
			let totalPrice: number = 0;
			merchant.products.map((product: any) => {
				let total: number = 0;
				let price: any = 0;
				totalLink = totalLink + product.skus.length;
				product.skus.map((sku: any) => {
					totalSKU = totalSKU + sku.quantity;
					total = total + sku.quantity;
				});
				if (product.pricePolicies && product.pricePolicies.length > 0) {
					let pricePolicies = lodash.sortBy(product.pricePolicies, [
						'minQuantity',
					]);
					if (total < pricePolicies[0].minQuantity) {
						price = pricePolicies[0].salePrice;
					} else if (
						total > pricePolicies[pricePolicies.length - 1].maxQuantity
					) {
						price = pricePolicies[pricePolicies.length - 1].salePrice;
					} else {
						pricePolicies.map((x: any) => {
							if (total >= x.minQuantity && total <= x.maxQuantity) {
								price = x.salePrice;
							}
						});
					}
					totalPrice += total * price;
				} else {
					product.skus.map((xx: any) => {
						totalPrice += xx.salePrice * xx.quantity;
					});
				}
			});
			setTotalPrice(totalPrice);
			setTotalSKU(totalSKU);
			setTotalLink(totalLink);
		} else {
			setTotalLink(0);
			setTotalSKU(0);
			setTotalPrice(0);
		}
		estimateFee();
	}, [merchant]);
	const handleChange = (code: string) => {
		let temp = accountBalance.find((x: any) => x.connection.id === code);
		if (temp) {
			setAccountDefault(temp);
		}
	};
	const formatAmount = (amount: any, unit?: string) => {
		if (!amount) return '';
		let color = amount < 0 ? 'txt-color-red' : 'txt-color-green';
		return (
			<span className={color}>{`${formatMoneyByUnit(amount, unit)}`}</span>
		);
	};
	const changeService = (items: any) => {
		if (accountDefault && accountDefault.connection) {
			setCurrentServiceSelected(items);
			let temp = lodash.cloneDeep(serviceSelectedCache);
			temp[accountDefault.connection.id] = items;
			setServiceSelectedCache(temp);
			// if(!showWarning){
			//     setShowWarning(true);
			//     notification['warning']({
			//         message: t('message.waning'),
			//         description: t('message.calculateFee'),
			//         duration:10,
			//         onClose:()=>{
			//             setShowWarning(false);
			//         },
			//     });
			// }
		}
	};
	const onCloseDrawer = () => {
		setShowDrawer(false);
	};
	const updateQuantity = (
		quantity: number,
		productId: string,
		skuId: string
	) => {
		let temp = lodash.cloneDeep(merchant);
		temp.products.map((product: any) => {
			if (product.id === productId) {
				product.skus.map((sku: any) => {
					if (sku.id === skuId) {
						sku.quantity = quantity;
					}
				});
				product.skus = product.skus.filter((sku: any) => sku.quantity);
			}
		});
		temp.products = temp.products.filter(
			(product: any) => product.skus.length > 0
		);
		if (temp.products.length === 0) {
			setStep(0);
		} else {
			setMerchant(temp);
		}
	};
	const updateEmployeeNote = (value: string, callback: () => void) => {
		setEmployeeNote(value);
		callback();
	};
	const updatePersonalNote = (value: string, callback: () => void) => {
		setPersonalNote(value);
		callback();
	};

	const handleCloseAddressList = () => {
		setOpenAddressList(false);
	};
	const handleCloseAddressCreate = () => {
		setOpenModalAdd(false);
		setSelectedAddress(null);
		if (addressList && addressList.length > 0) setOpenAddressList(true);
		else setOpenAddressList(false);
	};
	const openAddressCreate = () => {
		setOpenAddressList(false);
		setOpenModalAdd(true);
	};
	const showModalEditAddress = (item: any) => {
		setSelectedAddress(item);
		setOpenModalAdd(true);
	};
	const getAddressList = (isReset?: boolean) => {
		setIsLoading(true);
		cartRepository
			.getAddressList()
			.then((response) => {
				setAddressList(response);
				if (!deliveryAddress || isReset) {
					let temp = response.find((x: any) => x.isDefault);
					setDeliveryAddress(temp);
				} else {
					let temp = response.find((x: any) => x.id === deliveryAddress.id);
					setDeliveryAddress(temp);
				}
			})
			.catch((err) => {
				// console.log(err)
				// M24ErrorUtils.showError(t,err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onSubmitCreateModal = (body: any) => {
		setIsLoading(true);
		if (selectedAddress) {
			cartRepository
				.updateAddress(selectedAddress.id, body)
				.then((response) => {
					// console.log(body)
					M24Notification.messageSuccess(t('message.success'));
					getAddressList();
					handleCloseAddressCreate();
					setSelectedAddress(null);
					setOpenAddressList(true);
				})
				.catch((error) => {
					M24ErrorUtils.showError(t, error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			cartRepository
				.createAddress(body)
				.then((response) => {
					// console.log(body)
					M24Notification.messageSuccess(t('message.success'));
					if (addressList.length > 0) {
						setOpenAddressList(true);
					}
					getAddressList();
					handleCloseAddressCreate();
				})
				.catch((error) => {
					M24ErrorUtils.showError(t, error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const makeDeliveryAddress = (address: any) => {
		setDeliveryAddress(address);
	};
	// const [orderBody,setOrderBody] = useState<any>({});
	const allowToOrder = () => {
		if (
			!addressSupported ||
			(currentServiceSelected && currentServiceSelected.length === 0) ||
			addressList.length === 0 ||
			!deliveryAddress
		)
			return false;
		else {
			let groups = serviceGroups.filter((x: any) => x.required);
			if (groups && groups.length > 0) {
				let isAllow = true;
				groups.map((group: any) => {
					let temp = currentServiceSelected.find(
						(x: any) => x.serviceGroup && x.serviceGroup.code === group.code
					);
					if (!temp) isAllow = false;
				});
				return isAllow;
			} else return true;
		}
	};

	const createOrder = () => {
		if (creatingOrder) return;
		let body: any = {};
		let selectedService: any = [];
		currentServiceSelected.forEach((x: any) => {
			selectedService.push(x.code);
		});
		let selectedSkus: any = [];
		merchant.products.forEach((product: any) => {
			if (product.skus && product.skus.length > 0) {
				product.skus.forEach((sku: any) => {
					let item: any = {};
					item.skuId = sku.id;
					item.orderedQuantity = sku.quantity;
					item.actualPrice = sku.salePrice;
					selectedSkus.push(item);
				});
			}
		});
		body.merchantId = lodash.get(merchant, 'merchant.id');
		body.customer = lodash.get(accountDefault, 'connection.name');
		body.provider = lodash.get(accountDefault, 'connection.provider.code');
		body.currency = currency;
		body.services = selectedService;
		body.address = {
			fullname: deliveryAddress?.receiver,
			detail: deliveryAddress?.address1,
			phone: deliveryAddress?.phoneNumber,
			location: deliveryAddress?.location,
		};
		body.employeeNote = employeeNote;
		body.personalNote = personalNote;
		body.skus = selectedSkus;
		setCreatingOrder(true);
		handleCreateOrder(body, () => {
			setCreatingOrder(false);
		});
	};

	return (
		<Card bodyStyle={{ padding: 0 }}>
			<Spin spinning={creatingOrder}>
				<Row>
					<Col span={18} style={{ padding: 24 }}>
						<Row
							className={'dpl-flex align-items-center justify-content-between'}>
							<Col span={11}>
								<Avatar
									className={'border-radius6'}
									shape={'square'}
									size={20}
									src={lodash.get(merchant, 'merchant.icon')}></Avatar>
								<span
									className={
										'mgl10 mgr5 txt-size-h8 rebotomedium txt-color-secondary'
									}>
									{t('shopping-cart.seller')}:{' '}
								</span>
								<span className={'txt-size-h8 rebotomedium txt-color-black'}>
									{lodash.get(merchant, 'merchant.name', '')}
								</span>
								<Divider type={'vertical'} className={'bd-color-black'} />
								<Button
									className={'pdl0'}
									onClick={() => setShowDrawer(true)}
									type={'link'}>
									<span>{t('shopping-cart.orderDetail')}</span>
								</Button>
							</Col>
							<Col span={13}>
								<Select
									placeholder={t('shopping-cart.selectAccount')}
									value={accountDefault?.connection?.id}
									className={'width100'}
									onChange={handleChange}>
									{accountBalance &&
										accountBalance.length > 0 &&
										accountBalance.map((x: any) => {
											let currencyItem = JSON.parse(
												x.connection.provider.currency
											);
											return (
												<Select.Option value={x.connection.id}>
													<Row className={'dpl-flex align-items-center'}>
														<Avatar
															size={20}
															src={lodash.get(
																x,
																'connection.provider.logo',
																''
															)}
															shape={'circle'}></Avatar>
														<span className={'mgl5 robotoregular txt-size-h7'}>
															{lodash.get(x, 'connection.domain', '')}
														</span>
														<Divider
															type={'vertical'}
															className={'bd-color-black'}
														/>
														<span className={'robotoregular txt-size-h7'}>
															{lodash.get(x, 'connection.name', '')}
														</span>
													{currentConfigShowBalance && <span className={'mgl10 robotoregular txt-size-h7'}>
															Số dư:{' '}
															<span>
																{formatAmount(
																	lodash.get(x, 'balance', ''),
																	lodash.get(currencyItem, 'code', '')
																)}
															</span>
														</span>}
													</Row>
												</Select.Option>
											);
										})}
								</Select>
							</Col>
						</Row>
						<Row className={'dpl-flex align-items-center'}>
							<Col span={4}>
								<span className={'txt-size-h5 robotomedium txt-color-black'}>
									{t('shopping-cart.orderService')}
								</span>
							</Col>
							<Col flex={'auto'}>
								<Divider />
							</Col>
							<Col span={5} className={'dpl-flex justify-content-end'}>
								<Button
									icon={<i className='fas fa-check mgr5'></i>}
									onClick={() => setConfirmFavorite(true)}
									type={'primary'}>
									{t('shopping-cart.saveFavoriteServices')}
								</Button>
							</Col>
						</Row>
						<Services
							setCheckedList={changeService}
							checkedList={currentServiceSelected}
							services={services}
							serviceGroups={serviceGroups}
						/>
						{noticeFavoriteService && (
							<Row className={'notice-warehouse'}>
								<Col span={23}>
									<Row>
										<Col>
											<span className={'txt-color-primary'}>
												<i className='fas fa-exclamation-circle'></i>
											</span>
										</Col>
										<Col span={22}>
											<span className='mgl12 txt-size-h7 txt-color-black dpl-block whitespace-pre-wrap break-word'>
												{t('message.warningFavoriteServiceConflict')}
											</span>
										</Col>
									</Row>
								</Col>
								<Col className={'dpl-flex justify-content-end'} span={1}>
									<span onClick={() => setNoticeFavoriteService(null)}>
										<i className='cursor-pointer fas fa-times'></i>
									</span>
								</Col>
							</Row>
						)}
						<Row className={'align-items-center'} justify={'start'}>
							<Col span={4}>
								<span className={'txt-size-h5 robotomedium txt-color-black'}>
									{t('shopping-cart.orderAddress')}
								</span>
							</Col>
							<Col span={17}>
								<span className={''}>
									<Divider />
								</span>
							</Col>
							{addressList && addressList.length > 0 && (
								<Col span={3}>
									<Button
										type={'text'}
										className={'flr txt-size-h7 robotoregular'}
										onClick={() => {
											setOpenAddressList(true);
										}}>
										<span
											className={'txt-size-h7 robotoregular txt-color-primary'}>
											<i className='fad fa-cog' />{' '}
											{t('shopping-cart.editAddress')}
										</span>
									</Button>
								</Col>
							)}
						</Row>
						<div>
							{addressList && addressList.length <= 0 ? (
								<Empty
									description={
										<span>
											{t('address.empty-address-content-1')}
											<a onClick={() => setOpenModalAdd(true)}>
												{' '}
												{t('address.empty-address-content-2')}
											</a>
										</span>
									}
								/>
							) : (
								<Col className={'_delivery-address'}>
									<Row style={{ height: 22 }} align={'middle'}>
										<span>
											<i className='fas fa-user txt-size-h8 txt-color-gray2' />
										</span>
										<span className={'txt-size-h7 robotomedium mgl10'}>
											{lodash.get(deliveryAddress, 'receiver', '---')}
										</span>
									</Row>
									<Row style={{ height: 22 }} align={'middle'}>
										<span className={'dpl-block'}>
											<i className='fas fa-home-lg-alt txt-size-h8 txt-color-gray2' />
										</span>
										<span className={'txt-size-h7 robotoregular mgl10'}>
											{lodash.get(deliveryAddress, 'address1', '')}{' '}
											{lodash.get(deliveryAddress, 'display', '---')}
										</span>
									</Row>
									<Row style={{ height: 22 }} align={'middle'}>
										<span className={'dpl-block'}>
											<i className='fas fa-phone txt-size-h8 txt-color-gray2' />
										</span>
										<span className={'txt-size-h7 robotomedium mgl10'}>
											{lodash.get(deliveryAddress, 'phoneNumber', '---')}
										</span>
									</Row>
									{deliveryAddress && deliveryAddress.isDefault ? (
										<Row style={{ height: 22 }} align={'middle'}>
											<span>
												<i className='fas fa-cog txt-size-h8 txt-color-gray2' />
											</span>
											<span className={'txt-size-h7 robotoregular mgl10'}>
												{t('address.status-isDefault')}
											</span>
										</Row>
									) : null}
									{noticeWarehouse && (
										<Row className={'notice-warehouse'}>
											<Col span={23}>
												<Row>
													<Col>
														<span className={'txt-color-primary'}>
															<i className='fas fa-exclamation-circle'></i>
														</span>
													</Col>
													<Col span={22}>
														<span
															className='mgl12 txt-size-h7 txt-color-black dpl-block whitespace-pre-wrap break-word'
															dangerouslySetInnerHTML={{
																__html: t('cart.not_preferred_warehouse', {
																	code: noticeWarehouse,
																}),
															}}></span>
													</Col>
												</Row>
											</Col>
											<Col className={'dpl-flex justify-content-end'} span={1}>
												<span onClick={() => setNoticeWarehouse(null)}>
													<i className='cursor-pointer fas fa-times'></i>
												</span>
											</Col>
										</Row>
									)}
									{/*{noticeWarehouse&&<Alert className={'mgt12'} message={<span className={'txt-size-h7 txt-color-black'}>{t('cart.not_preferred_warehouse',{code: noticeWarehouse})}</span>} type="warning" showIcon closable />}*/}
								</Col>
							)}
						</div>
						<Row className={'dpl-flex align-items-center'}>
							<Col span={4}>
								<span className={'txt-size-h5 robotomedium txt-color-black'}>
									{t('shopping-cart.orderNote')}
								</span>
							</Col>
							<Col span={20}>
								<Divider />
							</Col>
						</Row>
						<Row className={'dpl-flex align-items-center'}>
							<span
								className={
									'txt-color-secondary robotoregular txt-size-h8 mgr5'
								}>
								{t('shopping-cart.personalNote')}:
							</span>
							<InlineEdit
								onSubmit={updatePersonalNote}
								title={t('shopping-cart.personalNote')}
								type={'textarea-modal'}
								defaultValue={personalNote}
								className={'_note-custom'}></InlineEdit>
						</Row>
						<Row className={'dpl-flex align-items-center'}>
							<span
								className={
									'txt-color-secondary robotoregular txt-size-h8 mgr5'
								}>
								{t('shopping-cart.employeeNote')}:
							</span>
							<InlineEdit
								onSubmit={updateEmployeeNote}
								title={t('shopping-cart.employeeNote')}
								type={'textarea-modal'}
								defaultValue={employeeNote}></InlineEdit>
						</Row>
					</Col>
					<Col span={6} className={'freeContainer'}>
						<Row className={'dpl-flex align-items-center'}>
							<Col span={14}>
								<span className={'txt-size-h6 robotomedium txt-color-black'}>
									{t('shopping-cart.productInformation')}
								</span>
							</Col>
							<Col span={10}>
								<Divider style={{ border: '1px solid #F2C265', height: 0 }} />
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<span className={'txt-size-h7 robotoregular'}>
									{t('shopping-cart.productLink')}
								</span>
							</Col>
							<Col span={12}>
								<span className={'txt-size-h7  flr'}>{`${
									totalSKU ? totalSKU : '...'
								} ${t('shopping-cart.products')}/${
									totalLink ? totalLink : '...'
								} Link`}</span>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<span className={'txt-size-h7 robotoregular'}>
									{t('shopping-cart.exchangeRate')}
								</span>
							</Col>
							<Col span={12}>
								<span className={'txt-size-h7 robotoregular flr'}>
									{exchangeRate && exchangeRate.rate && exchangeRate.exchange
										? `${formatMoneyByUnit(1, 'CNY')} = ${formatMoneyByUnit(
												exchangeRate.rate,
												exchangeRate.exchange
										  )}`
										: '--'}
								</span>
							</Col>
						</Row>

						<Row className={'dpl-flex align-items-center'}>
							<Col span={12}>
								<span className={'txt-size-h6 robotomedium txt-color-black'}>
									{t('shopping-cart.feeInformation')}
								</span>
							</Col>
							<Col span={12}>
								<Divider style={{ border: '1px solid #F2C265', height: 0 }} />
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<span className={'txt-size-h7 robotoregular'}>
									{t('shopping-cart.totalMoneyGoods')}
								</span>
							</Col>
							<Col span={12}>
								<span className={'txt-size-h7 robotoregular flr'}>
									{totalPrice &&
									exchangeRate &&
									exchangeRate.rate &&
									exchangeRate.exchange
										? formatMoneyByUnit(
												totalPrice * exchangeRate.rate,
												exchangeRate.exchange
										  )
										: '--'}
								</span>
							</Col>
						</Row>
						{fees && Array.isArray(fees.estimatedFees)
							? fees.estimatedFees.map((fee: any) => {
									let feeCategory = feeCategories.find(
										(x: any) => x.code === fee.type
									);
									return (
										<Row>
											<Col span={15}>
												<span className={'txt-size-h7 robotoregular'}>
													{lodash.get(feeCategory, 'name', fee.type)}
												</span>
											</Col>
											<Col span={9}>
												<span className={'txt-size-h7 robotoregular flr'}>
													{formatMoneyByUnit(
														fee.provisionalAmount,
														exchangeRate.exchange
													)}
												</span>
											</Col>
										</Row>
									);
							  })
							: null}
						<Row>
							<Col span={12}>
								<span className={'txt-size-h7 robotomedium'}>
									{t('shopping-cart.emdPercent', {
										value:
											fees && fees.emdPercent ? `${fees.emdPercent}%` : '--',
									})}
								</span>
							</Col>
							<Col span={12}>
								<span className={'txt-size-h7 robotomedium flr'}>
									{totalPrice &&
									exchangeRate &&
									exchangeRate.rate &&
									exchangeRate.exchange &&
									fees &&
									fees.emdPercent
										? formatMoneyByUnit(
												(totalPrice * exchangeRate.rate * fees.emdPercent) /
													100,
												exchangeRate.exchange
										  )
										: '--'}
								</span>
							</Col>
						</Row>
						{currentConfigShowBalance && (
							<Row>
								<Col span={12}>
									<span className={'txt-size-h7 robotomedium'}>
										{t('shopping-cart.balance')}
									</span>
								</Col>
								<Col span={12}>
									<span className={'txt-size-h7 robotomedium flr'}>
										{formatAmount(
											lodash.get(accountDefault, 'balance', ''),
											lodash.get(currency, 'code', '')
										)}
									</span>
								</Col>
							</Row>
						)}
						{accountDefault &&
						totalPrice &&
						exchangeRate &&
						exchangeRate.rate &&
						exchangeRate.exchange &&
						fees &&
						fees.emdPercent &&
						accountDefault.balance <
							(totalPrice * exchangeRate.rate * fees.emdPercent) / 100 ? (
							<div>
								<Row>
									<Col span={12}>
										<span className={'txt-size-h7'}>
											{t('shopping-cart.needMoney')}
										</span>
									</Col>
									<Col span={12}>
										<span className={'txt-size-h7 flr txt-color-red'}>
											{formatMoneyByUnit(
												Math.abs(
													accountDefault.balance -
														(totalPrice * exchangeRate.rate * fees.emdPercent) /
															100
												),
												lodash.get(currency, 'code', '')
											)}
										</span>
									</Col>
								</Row>
								<Row
									className={'align-items-start mgt10 justify-content-center'}>
									<span className={'txt-color-red'}>
										<i className='far fa-exclamation-triangle'></i>
									</span>
									<Col span={22}>
										<span className={'txt-size-h7 mgl5'}>
											{t('message.waningDepositMoney')}
										</span>
									</Col>
								</Row>
								<Row justify={'center'} align={'middle'}>
									<Button
										size={'large'}
										icon={<CheckOutlined />}
										disabled={true}
										type={'primary'}
										ghost
										className={'mgt20 width100'}>
										{t('shopping-cart.orderActionPayment')}
									</Button>
								</Row>
							</div>
						) : (
							<Row justify={'center'} align={'middle'}>
								<Button
									disabled={!allowToOrder() || isLoading}
									size={'large'}
									icon={<CheckOutlined />}
									className={'mgt20 width100 _btn-order-custom'}
									// type={"primary"}
									// ghost
									onClick={() => {
										createOrder();
									}}>
									{t('shopping-cart.orderActionPayment')}
								</Button>
							</Row>
						)}
					</Col>
				</Row>
			</Spin>
			<Drawer
				closable={false}
				bodyStyle={{ padding: 0 }}
				width={800}
				title={
					<Row
						className={'dpl-flex align-items-center justify-content-between'}>
						<Col span={12}>
							<Row justify={'start'} align={'middle'}>
								<span
									onClick={() => setShowDrawer(false)}
									className={'txt-size-h7 txt-color-gray cursor-pointer'}>
									<i className='fas fa-arrow-right mgr5' />
								</span>
								<Avatar
									className={'mgl10 border-radius6'}
									shape={'square'}
									size={20}
									src={lodash.get(merchant, 'merchant.icon')}
								/>
								<span className={'mgl10 mgr5 txt-size-h8 robotoregular'}>
									{t('shopping-cart.seller')}:{' '}
								</span>
								<span className={'txt-size-h8 robotoregular'}>
									{lodash.get(merchant, 'merchant.name', '')}
								</span>
							</Row>
						</Col>
					</Row>
				}
				placement='right'
				onClose={onCloseDrawer}
				visible={showDrawer}>
				<Row
					style={{ backgroundColor: '#FFEED9', padding: 16 }}
					className={'border-top-1x bd-color-primary'}>
					<Col span={7} className={'txt-size-h7 robotomedium'}>
						<span className={''}>{t('shopping-cart.products')}</span>
					</Col>
					<Col span={6} className={'txt-center txt-size-h7 robotomedium'}>
						<span>{t('shopping-cart.quantity')}</span>
					</Col>
					<Col span={5} className={'txt-left txt-size-h7 robotomedium'}>
						{' '}
						<span className={''}>{t('shopping-cart.variantProperties')}</span>
					</Col>
					<Col span={3} className={'txt-right txt-size-h7 robotomedium'}>
						<span>{t('shopping-cart.unitPrice')}</span>
					</Col>
					<Col span={3} className={'txt-right txt-size-h7 robotomedium'}>
						<span>{t('shopping-cart.totalPrice')}</span>
					</Col>
				</Row>
				<div style={{ padding: 16, marginBottom: 90 }}>
					{merchant?.products?.map((product: any, index: number) => {
						let total: number = 0;
						let price: any = null;
						product.skus.map((xx: any) => {
							total = total + xx.quantity;
						});
						if (product.pricePolicies && product.pricePolicies.length > 0) {
							let pricePolicies = lodash.sortBy(product.pricePolicies, [
								'minQuantity',
							]);
							if (total < pricePolicies[0].minQuantity) {
								price = pricePolicies[0].salePrice;
							} else if (
								total > pricePolicies[pricePolicies.length - 1].maxQuantity
							) {
								price = pricePolicies[pricePolicies.length - 1].salePrice;
							} else {
								pricePolicies.map((x: any) => {
									if (total >= x.minQuantity && total <= x.maxQuantity) {
										price = x.salePrice;
									}
								});
							}
						}
						return (
							<List
								key={product.id}
								itemLayout='horizontal'
								dataSource={product.skus}
								renderItem={(sku: any) => {
									return (
										<List.Item>
											<Product
												exchangeRate={exchangeRate}
												handleUpdateQuantitySku={updateQuantity}
												product={sku}
												salePrice={price}
												currency={lodash.get(sku, 'currency', '')}
											/>
										</List.Item>
									);
								}}
							/>
						);
					})}
				</div>
				<Col
					span={24}
					style={{
						position: 'fixed',
						bottom: 0,
						width: 800,
						padding: 16,
						backgroundColor: 'white',
					}}>
					<Row>
						<span className={'txt-size-h7 mgbt10'}>
							Thông tin:{' '}
							<span className={'robotomedium'}>{`${
								totalSKU ? totalSKU : '...'
							} ${t('shopping-cart.products')}/ ${
								totalLink ? totalLink : '...'
							} Link`}</span>
						</span>
						<span className={'txt-size-h7 mgl10'}>
							Thành tiền:{' '}
							<span className={'robotomedium'}>{`${
								totalPrice
									? exchangeRate && exchangeRate.rate && exchangeRate.exchange
										? formatMoneyByUnit(
												totalPrice * exchangeRate.rate,
												exchangeRate.exchange
										  )
										: formatMoneyByUnit(totalPrice, currency)
									: '--'
							}`}</span>
						</span>
					</Row>
					{/*<Button disabled={isDraftUpdating} loading={isDraftUpdating} onClick={updateDraftOrder} icon={  <i className="fal fa-check mgr5"/>} className={'width100'} type={'primary'} ghost>{t('shopping-cart.btUpdateDaftOrder')}</Button>*/}
				</Col>
			</Drawer>
			{openAddressList && (
				<Address
					isVisible={openAddressList}
					onCancel={handleCloseAddressList}
					openAddressCreate={openAddressCreate}
					addressList={addressList}
					updateAddress={showModalEditAddress}
					getAddressList={getAddressList}
					makeDeliveryAddress={makeDeliveryAddress}
					deliveryAddress={deliveryAddress}
				/>
			)}
			{openModalAdd && (
				<CreateAddressModal
					isVisible={openModalAdd}
					onCancel={handleCloseAddressCreate}
					onSubmit={onSubmitCreateModal}
					datasource={selectedAddress}
					isDefault={addressList.length === 0}
				/>
			)}
			{confirmFavorite && (
				<ConfirmModal
					loading={favoriteUpdating}
					onCancel={() => setConfirmFavorite(false)}
					onSubmit={updateFavoriteService}
					visible={confirmFavorite}
					content={t('message.cofirmSaveFavoriteService')}
				/>
			)}
		</Card>
	);
}

export default Step2;