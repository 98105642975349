import {ThreadItemInterface} from "../Interface/ThreadItemInterface";
import {ThreadMessageModel} from "./ThreadMessageInterface";

export const ThreadItemModel: ThreadItemInterface = {
    id: "",
    isRead: false,
    lastMessage: ThreadMessageModel,
    provider: "",
    referenceCode: "",
    tenant: "",
    timestamp: "",
    type: "",
    username: ""
}
