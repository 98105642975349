import React from 'react'
import {Select} from "antd/lib/index";
import {useTranslation} from "react-i18next";
import {PAGINATION_SIZE} from "../../core/config";
const {Option} = Select;
export interface SizeChangerProps{
    onChange:(size:number)=>void;
    size: number;
}
const SizeChanger=(props: SizeChangerProps)=>{
    const {size, onChange} = props;
    const {t} = useTranslation();
    return   <div className={""}>
        {/*{t('search.pagination_show')}*/}
        <Select
            style={{width:100}}
            className={"_size-changer"}
            onChange={size => onChange(size)}
            value={size}
        >
            {PAGINATION_SIZE.map((size: number) =>
                <Option key={size} value={size}>{`${size}/${t('pagination.page')}`}</Option>
            )}
        </Select>
    </div>;
}
export default SizeChanger;
