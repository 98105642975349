import React from "react";
import MainLayout from "../../components/Layout";
import {useTranslation} from "react-i18next";
import {Col, Row} from "antd";
import UserInformation from "../../components/Profile/UserInformation";
import Service from "../../components/Profile/Service";


const MENU_ITEMS = [
    {code:'user-info',name:'profile.user-info'},
    // {code:'services',name:'profile.services'},
]

function Profile () {
    const {t} = useTranslation();
    const [showProgressBar,setShowProgressBar] = React.useState<boolean>(false);
    const [currentMenu,setCurrentMenu] = React.useState<string>('user-info')


    const renderBody = () => {
        if(currentMenu === 'user-info') {
            return <UserInformation/>
        }
        else if(currentMenu === 'service') {
            return <Service/> ;
        }

    }


    return(
        <MainLayout title={t('menu.profile')} showProgressBar={showProgressBar}>
            <Row className={'profile-container'}>
                <Col span={4} className={'menu-item'}>
                    {MENU_ITEMS.map((m) => {
                        return <div className={`item ${currentMenu === m.code ? `__current`:``}`} key={m.code}
                                    onClick={()=>setCurrentMenu(m.code)}
                        >
                            <span className={'txt-size-h5 robotomedium txt-capitalize'}>{t(m.name)}</span>
                        </div>
                    })}
                </Col>
                <Col span={20} className={'content-container'}>
                        {renderBody()}
                </Col>
            </Row>
        </MainLayout>
    )
}
export default Profile;
