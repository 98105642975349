import * as React from "react";
import {Button, Modal} from "antd";
import {useTranslation} from "react-i18next";
export interface ConfirmModalProps{
    title?:string;
    content:string;
    visible:boolean;
    loading:boolean;
    className?:any;
    onCancel:()=>void;
    onSubmit:()=>void;
}
const ConfirmModal = (props: ConfirmModalProps)=>{
    const {t} = useTranslation();
    const {title, visible, onCancel, onSubmit, content, loading, className} = props;
    return(
        <Modal
            className={`_modal-confirm ${className}`}
            title={<span style={{color: '#1890FF', fontWeight: 500}}>{title?title:t('confirm.title')}</span>}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button
                    className={" btn_cancel _btn-cancel"}
                    key="cancel"
                    onClick={onCancel}
                >
                    <span className="mgr10"> <i className="fal fa-times"></i></span> {t('button.cancel')}

                </Button>,
                <Button
                    className={"_btn-submit btn_check"} key="submit" type="primary"
                    autoFocus={true}
                    onClick={onSubmit}
                    icon={<span className="mgr10"><i className="fal fa-check"></i></span>}
                    loading={loading ? loading : false}
                    ghost
                >
                    {t('button.submit')}
                </Button>,
            ]}
        >
            <p className={'_p-content'} dangerouslySetInnerHTML={{__html: content}}/>
        </Modal>
    );
}
export default ConfirmModal;
