import { Col, Image, Row, Table } from 'antd'
import React from 'react'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatNumber } from '../../../core/helpers/string'
import IconDrawFailedImg from '../../../resources/images/empty-3.png'
import EmptyData from '../../../components/EmptyData'

interface NestedData {
  loading: any
  items: any
  record: any
}

const NestedTable:React.FC<NestedData> = ({loading, items, record}) => {
  const {t} = useTranslation()

  const classes = 'robotoregular fsz-14 line-h-22 txt-color-black'
	const columns = [
   
    {
      title: <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.orderCode')}</span>,
      key: 'orderCode',
      width: '30%',
      render: (record: any) => (
        <span className={`${classes} txt-color-orange2`}>
          {lodash.get(record, 'order.code')}
        </span>
      ),
    },
    {
      title: <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.code')}</span>,
      key: 'code',
      width: '30%',
      render: (record: any) => {
        const packageCode = lodash.get(record, 'package.code')
        return <span className={classes}>
          {packageCode}
        </span>
      },
    },
    
    {
      title:  <span className='txt-color-blue6 robotomedium fsz-12 line-h-20'>{t('requestDeliveryPackage.weight')}</span>,
      key: 'weight',
      width: '30%',
      render: (record: any) => (
        <span className={classes}>
          {`${formatNumber(lodash.get(record, 'package.weight_net'))}kg`}
        </span>
      ),
    },
    
  ]
  const data =
    items && items.length > 0 ? items : []
  return (
    <Table
      locale={{emptyText: <EmptyData text={t('packages.noData')}/>}}
      loading={loading[record.code]}
      rowKey={(record: any) => record.code}
      dataSource={data}
      columns={columns}
      pagination={false}
      className='main-table'
    />
  )
}

export default NestedTable