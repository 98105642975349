import { message } from 'antd'
import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import MainLayout from '../../../components/Layout'
import { deliveryRepository } from '../../../repositories/DeliveryRepository'
import { supplierRepository } from '../../../repositories/SupplierRepository'
import M24Notification from '../../../utils/M24Notification'
import { getCleanFormFilter } from '../../../utils/StringUtils'
import Filter from './Filter'
import List from './List'

const InventoryVoucher = () => {
	const [showProgressBar, setShowProgressBar] = useState(true)
	const [searchParams, setSearchParams] = useSearchParams()
	const [accounts, setAccounts] = useState([])
	const [deliveryNotes, setDeliveryNotes] = useState([])
	const [size, setSize] = useState(Number(searchParams.get('size')))
	const [page, setPage] = useState(Number(searchParams.get('page')))
	const [total, setTotal] = useState(0)
	const [idAcc, setIdAcc] = useState<string | undefined>()
	const [filter, setFilter] = useState<any>({})
	const [hasError, setHasError] = useState<boolean | null>(null)

	const { t } = useTranslation()

	const getData = useCallback(() => {
		setShowProgressBar(true)
		const customer = searchParams.get('customer')
		const provider = searchParams.get('provider')
		const statuses = searchParams.get('statuses')
		const query = searchParams.get('query')
		const exportedAtFrom = searchParams.get('exportedAtFrom')
		const exportedAtTo = searchParams.get('exportedAtTo')
		const page = searchParams.get('page')
		const size = searchParams.get('size')

		const params = {
			provider,
			providerUsername: customer,
			code:query,
			exportedAtFrom,
			exportedAtTo,
			page,
			size,
			statuses,
		}
		setFilter((prevState:any) => ({...prevState,...getCleanFormFilter(params)}))
		const controller = new AbortController();
		const {signal} = controller;
		if (customer && provider) {
			deliveryRepository
				.getInventoryVoucher({...getCleanFormFilter(params)}, signal)
				.then((response: any) => {
					const currentPage = lodash.get(response, 'headers.x-page-number')
					const total = lodash.get(response, 'headers.x-total-count')
					setDeliveryNotes(response.data)
					setPage(parseInt(currentPage) + 1)
					setTotal(parseInt(total))
					setSize(parseInt(lodash.get(response, 'headers.x-page-size')))
				})
				.catch((err: any) => {
					if(err.response.data.title === 'error_from_m6') {
						setHasError(true)
						setDeliveryNotes([])
					}else{
						M24Notification.notifyError(t('message.titleFailed'),t(`message.${lodash.get(err, 'response.data.title')}`), '_notify-error', 5)
					}
				})
				.finally(() => setShowProgressBar(false))
		}
		return () => {
			setHasError(null)
			controller.abort();
		}
	
	
	}, [searchParams, t])

	const getAccounts = useCallback(() => {
		supplierRepository
			.getListAccount()
			.then((res) => {
				const accounts = res.filter(({ status }: any) => status === 'CONNECTED')
				if (accounts && accounts.length > 0) {
					const indexOfAccount = accounts.findIndex((acc: any) => acc.defaultConnection)
					const account = indexOfAccount > -1 ? accounts[indexOfAccount] : accounts[0]
					setIdAcc(lodash.get(account, 'id'))
				}
				setAccounts(accounts)
			})
			.catch((err) => {})
	}, [])

	useEffect(() => {
		getAccounts()
	}, [getAccounts])
	useEffect(() => {
		getData()
		return () => {
			setHasError(null)
		}
	}, [getData])

	const handleFilter = (params: any) => {
		setFilter({...params})
		setSearchParams({ ...params })
	}

	const handleProgressBar = (val: boolean) => {
		setShowProgressBar(val)
	}

	const handleAcc = (id: string | undefined) => setIdAcc(id)

	return (
		<MainLayout title={t('deliveryNote.list')} showProgressBar={showProgressBar}>
			<Filter
				accounts={accounts}
				idAcc={idAcc}
				handleAcc={handleAcc}
				handleFilter={handleFilter}
				handleProgressBar={handleProgressBar}
			/>

			<List
				filter={filter}
				idAcc={idAcc}
				items={deliveryNotes}
				accounts={accounts}
				handleFilter={handleFilter}
				total={total}
				page={page}
				size={size}
				showProgressBar={showProgressBar}
				hasError={hasError}
			/>
		</MainLayout>
	)
}

export default InventoryVoucher