import { Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import lodash from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { orderRepository } from '../../repositories/OrderRepository'
import M24ErrorUtils from '../../utils/M24ErrorUtils'
import M24Notification from '../../utils/M24Notification'
interface TruncateProps {
	defaultValue?: any
	className?: string
	editable?: boolean
	isOrder?: boolean
	id?: string
}

const TruncateText: React.FC<TruncateProps> = (props) => {
	const { defaultValue, editable, className, isOrder, id } = props
	const [editing, setEditing] = useState(false)
	const [value, setValue] = useState(defaultValue)

	useEffect(() => {
		setValue(defaultValue)
	}, [defaultValue])
	const { t } = useTranslation()
	let { code } = useParams()

	const handleEditInline = (val: string) => {
		if (isOrder) {
			orderRepository
				.updatePersonalNoteOfOrder(code, val)
				.then((res) => {
					setValue(lodash.get(res, 'personalNote'))
					M24Notification.notifySuccess(t('orderDetails.updatedPersonalNoteOrder'), '', '', 5)
				})
				.catch((err) => {M24ErrorUtils.showError(t, err)})
				.finally(() => setEditing(false))
		} else {
			orderRepository
				.updatePersonalNoteOfOrderItem(code, id!, val)
				.then((res) => {
					setValue(lodash.get(res, 'personalNote'))
					M24Notification.notifySuccess(t('orderDetails.updatedPersonalNoteItem'), '', '', 5)
				})
				.catch((err) => {M24ErrorUtils.showError(t, err)})
				.finally(() => setEditing(false))
		}
	}

	return (
		<>
			<Paragraph
				id='my-edit'
				className={`mgbt-ipt-0 dpl-flex align-items-center robotoregular`}
				editable={
					editable
						? {
								icon: (
									<span className={'mg-0 _note-icon-custom'} onClick={() => setEditing(true)}>
										<i className='far fa-edit txt-color-orange txt-size-h8'></i>
									</span>
								),
								tooltip: false,
								editing: editing,
								onChange: handleEditInline,
								onCancel: () => setEditing(false),
								autoSize: { minRows: 1, maxRows: 3 },
						  }
						: false
				}>
				{!editing ? (
					<>
						<span
							className={`truncate-multiline-1  ${
								className ? className : 'txt-color-black fsz-14'
							}`}>
							{value}
						</span>
						{defaultValue.length > 80 && (
							<Tooltip
								placement='topRight'
								title={<span className='fsz-12 line-h-20 txt-color-black'>{value}</span>}
								color='#FBB739'>
								<i className='txt-size-h7 far fa-info-circle txt-color-orange mgl5 cursor-pointer'></i>
							</Tooltip>
						)}
					</>
				) : value === '---' ? (
					''
				) : (
					value
				)}
			</Paragraph>
		</>
	)
}

export default TruncateText
