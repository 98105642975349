import React from "react";
import {ProviderInterface} from "../../Interface/ProviderInterface";

interface Props {
    provider: ProviderInterface
    customerUsername: string
}
export const Provider = (props: Props) => {
    const {provider, customerUsername} = props
    return (
        <div className="dpl-flex align-items-center supplier-component">
            <img
                className={'supplier-icon'}
                src={provider.logo}
                alt={provider.name}
            />
            <span className={'mgl5 txt-size-h8 robotoregular txt-color-black supplier-name'}>{provider.name}</span>
            <span className={'txt-color-black mgl5 mgr5'}>|</span>
            <span className={'txt-size-h8 robotoregular txt-color-black supplier-customer'}>{customerUsername}</span>
        </div>
    )
}
