import React from "react";
import {useTranslation} from "react-i18next";
import {Avatar, ConfigProvider, Empty, Row, Table} from "antd";
import {Link} from "react-router-dom";
import Clipboard from "../Clipboard";
import {formatDateTime} from "../../core/helpers/date-time";
import {formatMoneyByUnit,} from "../../core/helpers/string";
import lodash from "lodash";
import {getConnectionByNameAndProviderCode, getConnectionFromConnections} from "../../utils/util";
import {Provider} from "../Provider";


type ClaimListProps = {
    loading: boolean
    data: any[],
    ticketStatuses:any[],
    providerAccounts:any[],
}

function ClaimList(props: ClaimListProps) {
    const {t} = useTranslation();
    const {data,ticketStatuses,providerAccounts, loading} = props;

    const column = [
        {
            title: <span className={'txt-size-h8 robotomedium txt-color-black dpl-block width80px'}>{t('tickets.ticket-code')}</span>,
            dataIndex: 'code',
            key: 'code',
            render: (code:any, row: any) => {
                const connection = getConnectionFromConnections(providerAccounts, lodash.get(row, 'owner.username'), row.tenant)
                return <span className={'align-items-center txt-color-primary'}>
                    <Link to={`/claims/${lodash.get(connection, 'provider.code')}/${lodash.get(connection, 'name')}/${lodash.get(row, 'relatedOrder')}/${lodash.get(row, 'code')}`}>
                        <span className={'txt-size-h7 robotomedium txt-color-primary'}>#{code}</span>
                    </Link>
                    {code && <Clipboard value={code}/>}
                </span>
            }
        },
        {
            title: <span className={'txt-size-h8 robotomedium txt-color-black'}>{t('tickets.ticket-name')}</span>,
            dataIndex: 'name',
            key: 'name',
            render:((name:any,record:any )=> {
                return <div className={'align-items-center'}>
                    <Avatar src={record.thumbnail} size={36} shape={'square'} className={'bd-none-impt border-radius4'}/>
                    <span className={'txt-size-h7 robotomedium txt-color-black mgl5'}>{name}</span>
                </div>
            })
        },
        {
            title: <span className={'txt-size-h8 robotomedium txt-color-black dpl-block width120px'}>{t('tickets.provider-account')}</span>,
            dataIndex: 'owner',
            key: 'owner',
            render:(owner:any, row: any) => {
                let connection = getConnectionByNameAndProviderCode(providerAccounts, lodash.get(owner, 'username'), lodash.get(row, 'tenant'))
                return <Provider provider={connection.provider} customerUsername={connection.name} />
            }
        },
        {
            title: <span className={'txt-size-h8 robotomedium txt-color-black'}>{t('tickets.ticket-created-at')}</span>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render : (createdAt:any) => <span className={'robotoregular txt-size-h7 txt-color-black'}>{formatDateTime(createdAt)}</span>
        },
        {
            title: <span className={'txt-size-h8 robotomedium txt-color-black dpl-block width120px'}>{t('tickets.ticket-status')}</span>,
            key: 'state',
            render: (row: any) => {
                return (
                    <>
                        <span
                            className={`ticket-status txt-size-h8 robotoregular`}
                            style={{backgroundColor:`${lodash.get(row, 'publicState.color')}`}}
                        >{lodash.get(row, 'publicState.name')}<span>{lodash.get(row, 'archived') === true ? ` (${t('tickets.archived')})` : ''}</span>
                        </span>
                    </>
                )
            }
        },
        {
            title: <span className={'txt-size-h8 robotomedium txt-color-black flr'}>{t('tickets.refund-amount')}</span>,
            dataIndex: 'estimatedRefundValue',
            key: 'estimatedRefundValue',
            width: '10%',
            render: (estimatedRefundValue:any) => <span className={`${estimatedRefundValue? 'ticket-refund flr txt-size-h7 robotoregular' : 'flr txt-size-h7 robotoregular'}`}>
                {formatMoneyByUnit(estimatedRefundValue)}
            </span>
        },
        {
            title: <span className={'txt-size-h8 robotomedium txt-color-black'}>{t('tickets.resolve-methods')}</span>,
            dataIndex: 'solution',
            key: 'solution',
            width: '15%',
            render : (solution:any) => <span className={'txt-size-h7 txt-color-black-robotoregular fll'}>{solution.name}</span>
        }
    ];

    return (
        <div className={'ticket-list mgt15'}>
            <ConfigProvider renderEmpty={() => <Empty
                image={Empty.PRESENTED_IMAGE_DEFAULT}
                imageStyle={{height: 40, color: '#DCE0E6'}}
                description={<span className={'txt-size-h7 robotoregular'}>{t('tickets.empty-list')}</span>}/>}>
                <Table
                    loading={loading}
                    className={'ticket-list'}
                    columns={column}
                    dataSource={data}
                    pagination={false}
                    rowKey={'id'}
                    rowClassName={"custom-row-hover"}
                />
            </ConfigProvider>
        </div>
    )
}

export default ClaimList;
