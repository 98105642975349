import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Button, Col, Divider, Input, Row, Select} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import lodash from "lodash";
import {useSearchParams} from "react-router-dom";

type ClaimFilterProps = {
    providerAccounts: any[];
    selectedAccount: any;
    ticketStatuses: any[];
    ticketSolutions: any[];
}

const defaultFilter = {
    publicState: "",
    solutionCode: "",
    code: "",
    providerCode: "",
    accountCode: "",
    accountId: ""
}

function ClaimFilter(props: ClaimFilterProps) {
    const {t} = useTranslation();
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [filter, setFilter] = useState(defaultFilter)
    const [searchParams, setSearchParams] = useSearchParams()
    const {
        providerAccounts,
        selectedAccount,
        ticketStatuses,
        ticketSolutions,
    } = props;

    useEffect(() => {
        setFilter({
            ...defaultFilter,
            publicState: searchParams.get('publicState') || "",
            solutionCode: searchParams.get('solutionCode') || "",
            code: searchParams.get('code') || "",
            providerCode: searchParams.get('providerCode') || "",
            accountCode: searchParams.get('accountCode') || "",
            accountId: searchParams.get('accountId') || ""
        })
    }, [searchParams])

    const handleChangeFilter = (param: string, value: any) => {
        const newFilter = {
            ...filter,
            [param]: value
        }
        setFilter(newFilter)
    }

    const handleChangeProviderAccount = (accountId: any) => {
        const acc = lodash.find(providerAccounts, item => item.id === accountId)
        filter.accountId = accountId
        filter.accountCode = acc.name
        filter.providerCode = lodash.get(acc, 'provider.code')

        setFilter(filter)

        setSearchParams({
            ...filter,
            accountCode: acc.name,
            providerCode: lodash.get(acc, 'provider.code')
        })
    }

    const handleSubmit = () => {
        setSearchParams({
            ...lodash.omitBy(filter, value => !value)
        })
    }

    const handleChangeFilterAndSubmit = (param: string, value: any) => {
        const newFilter = {
            ...filter,
            [param]: value
        }
        setFilter(newFilter)
        setSearchParams({
            ...lodash.omitBy(newFilter, value => !value)
        })
    }

    const handleResetFilter = () => {
        setFilter(defaultFilter)
        setSearchParams({})
    }

    return (
        <div className={'border-card-header pd12 mgbt18 ticket-filter-container border-radius4'}>
            <div className={'dpl-flex'}>
                <span
                    className={'claim-filter-title fsz-16 robotomedium txt-color-black txt-capitalize flex-grow-2'}>{t('tickets.tickets-filter-tittle')}</span>
                <span
                    onClick={() => {
                        setShowFilter(!showFilter);
                    }}
                    className={'cursor-pointer pdl5 align-self-center'}
                >
                    {showFilter ? <i className="fas fa-chevron-up txt-size-h7"/> :
                        <i className="fas fa-chevron-down txt-size-h7"/>}
                </span>
            </div>
            {showFilter &&
            <div className={'mgt10'}>
                <Row gutter={20}>
                    <Col xs={24} md={12} lg={12} xl={10} xxl={8}>
                        <div className={'dpl-flex claim-filter-form-group'}>
                            <span
                                className={'claim-filter-form-label txt-size-h7 robotoregular txt-color-black mgr5'}>{t('tickets.ticket-statuses')}:</span>
                            <div className={'claim-filter-form-control'}>
                                {ticketStatuses.map((status: any) => {
                                    let isSelect = false;
                                    if (filter.publicState) {
                                        let temp = filter.publicState;
                                        if (temp && temp.includes(status.code)) {
                                            isSelect = !isSelect;
                                        }
                                    }
                                    return (
                                        <span
                                            onClick={() => {
                                                handleChangeFilterAndSubmit('publicState', status.code)
                                            }}
                                            className={`cursor-pointer item ${isSelect ? 'item-selected' : ''}`}
                                            key={status.code}>
                                            <span className={'txt-size-h8 robotomedium'}>{status.name}</span>
                                    </span>)
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                        <div className={'claim-filter-form-group'}>
                            <span
                                className={'claim-filter-form-label txt-size-h7 robotoregular txt-color-black mgr5'}>{t('tickets.resolve-methods')}:</span>
                            <div className="claim-filter-form-control">
                                {ticketSolutions.map((solution: any) => {
                                    let isSelect = false;
                                    if (filter.solutionCode) {
                                        let temp = filter.solutionCode.split(',');
                                        if (temp && temp.includes(solution.code)) {
                                            isSelect = !isSelect;
                                        }
                                    }
                                    return <span
                                        onClick={() => {
                                            handleChangeFilterAndSubmit('solutionCode', solution.code)
                                        }}
                                        className={`cursor-pointer item ${isSelect ? 'item-selected' : ''}`}
                                        key={solution.code}>
                                        <span className={'txt-size-h8 robotomedium'}>{solution.name}</span>
                                    </span>
                                })}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col xs={24} lg={8}>
                        <div className={'claim-filter-form-group'}>
                            <div className={'claim-filter-form-label'}>
                                <span
                                    className={'txt-size-h7 robotoregular txt-color-black wordbreakword'}>{t('tickets.searchByCode')}:</span>
                            </div>
                            <div className={'claim-filter-form-control'}>
                                <Input className={'input-layout width100pc'}
                                       placeholder={t('tickets.searchByCode-placeholders')}
                                       onChange={(event: any) => {
                                           handleChangeFilter('code', event.target.value)
                                       }}
                                       value={lodash.get(filter, 'code', '')}
                                       onPressEnter={(event:any)=> handleChangeFilterAndSubmit('code',event.target.value)}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} lg={7}>
                        <div className={'claim-filter-form-group'}>
                            <div className={'claim-filter-form-label'}>
                                <span
                                    className={'txt-size-h7 robotoregular txt-color-black wordbreakall'}>{t('tickets.provider-account')}:</span>
                            </div>
                            <div className={'claim-filter-form-control'}>
                                <Select
                                    className={'select-layout width100pc'}
                                    placeholder={t('tickets.provider-account')}
                                    value={filter.accountId || lodash.get(selectedAccount, 'id')}
                                    onChange={value => {
                                        handleChangeProviderAccount(value)
                                    }}
                                >
                                    {providerAccounts.map((account: any) => {
                                        return <Select.Option key={account.id} value={account.id} className={''}>
                                            <div className={'dpl-flex align-items-center '}>
                                                <Avatar size={12} src={lodash.get(account, 'provider.logo', '')}
                                                        shape={'circle'}/>
                                                <span
                                                    className={'mgl5 robotoregular txt-size-h7 txt-capitalize'}>{lodash.get(account, 'provider.code', '')}</span>
                                                <Divider type={"vertical"} className={'bd-color-black'}/>
                                                <span
                                                    className={'robotoregular txt-size-h7'}>{lodash.get(account, 'name', '')}</span>
                                            </div>
                                        </Select.Option>
                                    })}
                                </Select>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className={'mgt5 dpl-flex align-items-center justify-content-end'}>
                    <span
                        onClick={handleResetFilter}
                        className={'mgr15 cursor-pointer txt-size-h8 robotoregular txt-color-gray refresh-filter-btn'}>
                        <ReloadOutlined className={''}/> &nbsp;{t('filter.refresh')}
                    </span>
                    <Button className={'search-btn'} onClick={() => handleSubmit()}>
                        {t('tickets.search-btn')}
                    </Button>
                </div>
            </div>}
        </div>
    );
}

export default ClaimFilter;
