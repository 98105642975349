import {ClaimInterface} from "../Interface/ClaimInterface";
import {ClaimStateModel} from "./ClaimStateModel";
import {ClaimReasonModel} from "./ClaimReasonModel";

export const ClaimModel: ClaimInterface = {
    publicState: ClaimStateModel,
    rating: 0,
    ratingComment: "",
    code: "",
    createdAt: "",
    description: "",
    estimatedRefundValue: 0,
    id: 0,
    modifiedAt: "",
    name: "",
    relatedOrder: "",
    suggest: 0,
    tenant: "",
    thumbnail: "",
    state: "",
    archived: false,
    attachments: [],
    relatedProduct:"",
    reason: ClaimReasonModel,
    reasonData: {
        notReceived: ""
    }
}
