import {message} from 'antd'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import MainLayout from '../../components/Layout'
import {useNavigate, useParams} from "react-router-dom";
import OrderInfo from "../../components/OrderDetail";
import {orderRepository} from "../../repositories/OrderRepository";
import M24ErrorUtils from "../../utils/M24ErrorUtils";
import {categoryRepository} from "../../repositories/CategoryRepository";
import M24Notification from "../../utils/M24Notification";
import lodash from 'lodash'
import {DEFAULT_PAGE_SIZE, X_PAGE_NUMBER, X_PAGE_SIZE, X_TOTAL_COUNT} from '../../core/config/index'
import {claimRepository} from "../../repositories/ClaimRepository";
import {ClaimInterface} from '../../Interface/ClaimInterface'
import BoxChat from "../../components/BoxChat";
import {BoxChatContext} from "../../context/BoxChatContext";
import {threadRepository} from "../../repositories/ThreadRepository";

interface IClaimState {
	isLoading: boolean,
	claimList: ClaimInterface[],
	total: null | number,
	pageSize: null | number,
	currentPage: number
}

function OrderDetail() {
	const { t } = useTranslation()
	let { code } = useParams()
	const navigate = useNavigate()
	const [showProgressBar, setShowProgressBar] = useState<boolean>(true)
	const [orderDetail, setOrderDetail] = useState<any>()
	const [statuses, setStatuses] = useState<any>([])
	const [isReOrdering, setReOrdering] = useState<boolean>(false)
	const [isCancelOrdering, setCancelOrdering] = useState<boolean>(false)
	const [finalcials, setFinancials] = useState<any>([])
	const [loadingConfirm, setLoadingConfirm] = useState<{ [id: string]: boolean } | undefined>()
	const [loadingNotConfirm, setLoadingNotConfirm] = useState<{ [id: string]: boolean } | undefined>()

    const providerCode = orderDetail?.provider?.code
    const accountCode = orderDetail?.providerUsername

	const [loadingCreateThread, setLoadingCreateThread] = useState(false)

	const boxChatContext = useContext(BoxChatContext)

    const [claim, setClaim] = useState<IClaimState>({
        isLoading: false,
        claimList: [],
        total: null,
        pageSize: null,
        currentPage: 1
    })

    useEffect(() => {
        if(providerCode && accountCode) {
            getClaims({
                page: claim.currentPage,
                size: DEFAULT_PAGE_SIZE
            })
        }
    }, [providerCode, code, accountCode])

	useEffect(() => {
		getStatuses()
		getData()
		getFinancials()
	}, [])
	const getData = () => {
		orderRepository
			.getDetail(code)
			.then((res) => {
				setOrderDetail(res)
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => setShowProgressBar(false))
	}
	const getFinancials = () => {
		orderRepository
			.getFinancials(code)
			.then((res) => {
				setFinancials(res)
			})
			.catch((err) => {})
	}
	const getStatuses = () => {
		categoryRepository
			.getStatuses()
			.then((res) => {
				setStatuses(res)
			})
			.catch((err) => {})
	}
	const reOrder = () => {
		setReOrdering(true)
		orderRepository
			.reOrder(code)
			.then((res) => {
				M24Notification.notifySuccess(
					t('message.reorder_success'),
					t('message.reorder_note'),
					'',
					10
				)
				navigate('/shopping-cart')
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => setReOrdering(false))
	}
	const cancelOrder = (callback: () => void) => {
		setCancelOrdering(true)
		orderRepository
			.cancelOrder(code)
			.then((res) => {
				M24Notification.messageSuccess(t('message.cancel_order_success'))
				if (callback) callback()
				getData()
				getFinancials()
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => setCancelOrdering(false))
	}

	const confirmProductChange = (
		code: string,
		id: string,
		confirm: boolean,
		successInfo: string
	) => {
		confirm
			? setLoadingConfirm({ ...loadingConfirm, [id]: true })
			: setLoadingNotConfirm({ ...loadingNotConfirm, [id]: true })
		orderRepository
			.confirmProductChange(code, id, confirm)
			.then((res) => {
				message.success({
					content: successInfo,
					className: 'confirm-order-item',
					duration: 10,
					icon: <i className='fas fa-check-circle txt-color-blue5 mg-r-12'></i>,
				})
				getData()
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => {
				confirm
					? setLoadingConfirm({ ...loadingConfirm, [id]: false })
					: setLoadingNotConfirm({ ...loadingNotConfirm, [id]: false })
			})
	}

    const getClaims = (filter: any): void => {
        setClaim(prevClaim => ({ ...prevClaim, isLoading: true }))

        claimRepository
            .getClaimsByOrder(providerCode, accountCode, code, filter)
            .then(res => {
                setClaim(prevClaim => ({
                    ...prevClaim,
                    isLoading: false,
                    claimList: res?.data && Array.isArray(res.data) ? res.data : [],
                    total: parseInt(lodash.get(res, X_TOTAL_COUNT)),
                    pageSize: parseInt(lodash.get(res, X_PAGE_SIZE)),
                    currentPage: parseInt(lodash.get(res, X_PAGE_NUMBER)) + 1
                }))
            })
			.catch(err => {
				setClaim(prevClaim => ({ ...prevClaim, isLoading: false }))
			})
    }

    const handleConfirm = (val: boolean) => {
        if(val) {
            getData()
        }
    }

    const onClaimPageChange = (filter: any) => getClaims(filter)

	const showBoxChat = () => {
		setLoadingCreateThread(true)
		threadRepository.createThread({
			referenceCode: orderDetail.code,
			type: 'ORDER_COMMENT'
		})
			.then((response) => {
				if (!boxChatContext.visible) {
					boxChatContext.toggle()
				}

				boxChatContext.onFilterThreads({})
					.then(() => {
						if (boxChatContext.thread.id !== response.data.id) {
							boxChatContext.onSelectThread(response.data)
						}
					})
			})
			.finally(() => setLoadingCreateThread(false))
	}

	return (
		<MainLayout
			title={t('orderDetail.title', { value: code ? `#${code}` : '' })}
			showProgressBar={showProgressBar}>
			<OrderInfo
				loadingConfirm={loadingConfirm}
				loadingNotConfirm={loadingNotConfirm}
				confirmProductChange={confirmProductChange}
				isCancelOrdering={isCancelOrdering}
				cancelOrder={cancelOrder}
				finalcials={finalcials}
				isReOrdering={isReOrdering}
				reOrder={reOrder}
				orderDetail={orderDetail}
				statuses={statuses}
				showProgressBar={showProgressBar}
                handleConfirm={handleConfirm}
                claim={ claim }
                onClaimPageChange={ onClaimPageChange }
                providerCode={ providerCode }
                accountCode={ accountCode }
				showBoxChat={showBoxChat}
				loadingCreateThread={loadingCreateThread}
            />
			<BoxChat
				referenceCode={code!}
				type={'ORDER_COMMENT'}
			/>
		</MainLayout>
	)
}
export default OrderDetail
