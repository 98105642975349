import {Link} from "react-router-dom";
import MainLayout from "../Layout";
import {useTranslation} from "react-i18next";
import {Result} from "antd";

export const Notfound = () => {
    const {t} = useTranslation()
    return (
        <MainLayout title={t('errorPage.404')}>
            <Result
                status="404"
                title="404"
                subTitle="Trang của bạn yêu cầu hiện không tồn tại"
                extra={<Link to={'/'}>Quay lại trang chủ</Link>}
            />,
        </MainLayout>
    )
}
