import React from "react";
import {useTranslation} from "react-i18next";
import lodash from 'lodash';
import {Row} from "antd";
import TabLayout from "./TabLayout";
import Information from "./Information";
import Heading from "./Heading/index";
import Finance from "./Finance";
import TrackOrder from "./TrackOrder";

export interface OrderInfoProps{
    orderDetail?: any
    statuses: any
    showProgressBar: any
    finalcials: any
    reOrder: () => void
    isReOrdering: boolean
    cancelOrder: (callback: () => void) => void
    isCancelOrdering: boolean
    confirmProductChange: (code: string, id: string, confirm: boolean, successInfo: string) => void
    loadingNotConfirm: { [id: string]: boolean } | undefined
    loadingConfirm: { [id: string]: boolean } | undefined
    claim: any,
    onClaimPageChange: (filter: any) => void,
    providerCode: string | undefined,
    accountCode: string | undefined,
    handleConfirm: (val: boolean) => void
	showBoxChat: () => void
	loadingCreateThread: boolean
}

function OrderInfo(props: OrderInfoProps){
    const {
        orderDetail,
        statuses,
        showProgressBar,
        finalcials,
        reOrder,
        isReOrdering,
        cancelOrder,
        isCancelOrdering,
        confirmProductChange,
        loadingConfirm,
        loadingNotConfirm,
		loadingCreateThread
    } = props
    const {handleConfirm, claim, onClaimPageChange, providerCode, accountCode } = props;
    const {t} = useTranslation();

	const orderItems = lodash.get(props.orderDetail, 'orderItems', [])
	const exchangeRate = lodash.get(props.orderDetail, 'exchangeRate', [])
	const currencyProvider = lodash.get(props, 'provider.currency', '')

	return (
		<>
			<Heading
				statuses={statuses}
				isCancelOrdering={isCancelOrdering}
				cancelOrder={cancelOrder}
				isReOrdering={isReOrdering}
				reOrder={reOrder}
				orderDetail={orderDetail}
				showBoxChat={props.showBoxChat}
				loadingCreateThread={loadingCreateThread}
			/>
			<Row className='mg-t-12 gap-12'>
				<div className='dpl-flex flex-col flex-basis-80pc flex-wrap justify-content-start'>
					<div className='dpl-flex justify-content-between'>
						<Information orderDetail={orderDetail} />
						<Finance orderDetail={orderDetail} />
					</div>
					<div>
						<TabLayout
							loadingConfirm={loadingConfirm}
							loadingNotConfirm={loadingNotConfirm}
							confirmProductChange={confirmProductChange}
							finalcials={finalcials}
							orderDetail={orderDetail}
							items={orderItems}
							exchangeRate={exchangeRate}
							currency={currencyProvider}
							loading={showProgressBar}
                            claim={ claim }
                            onClaimPageChange={ onClaimPageChange }
                            providerCode={ providerCode }
                            accountCode={ accountCode }
                            handleConfirm={handleConfirm}
						/>
					</div>
				</div>
				<div className='flex-basis-20pc pd-t-12 px-12 bg-color-white box-order gap-8 track-order h-fit-content'>
					<h5 className='robotomedium txt-size-h5 mg-0 line-h-24 mg-bt-8 txt-color-black'>
						{t('orderDetail.history')}
					</h5>
					<TrackOrder statuses={statuses} orderDetail={orderDetail} />
				</div>
			</Row>
		</>
	)
}
export default OrderInfo
