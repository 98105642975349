import { Repository } from '../core/repositories/Repository'
import { AxiosResponse } from 'axios'
import { url } from '../core/helpers/string'
import { httpConfig } from '../core/config/http'
import { API_BASE_URL, DEFAULT_PAGE_SIZE } from '../core/config'

export class DeliveryRepository extends Repository {
	constructor() {
		super(httpConfig)
		this.setBaseURL(url(API_BASE_URL, ''))
	}

	public getRequestsDelivery = (filter: any, signal: AbortSignal): Promise<any> => {
		let limit = filter && filter.size ? filter.size : DEFAULT_PAGE_SIZE
		let offset = filter && filter.page ? filter.page * limit : 0
		return this.http
			.get(`delivery-requests`, {
				params: { ...filter, offset, limit, sort: 'createdAt:desc' },
				signal: signal,
			})
			.then((response: AxiosResponse<any>) => {
				return response
			})
	}

  
    public getInventoryVoucher = (filter: any, signal: AbortSignal) : Promise<any> => {
      let limit = filter && filter.size? filter.size:DEFAULT_PAGE_SIZE;
      let offset=filter && filter.page? filter.page*limit:0;
      return this.http
          .get(`/delivery-requests/delivery-notes`, {params: {...filter, offset, limit, sort:'exported_at:desc'}, signal: signal}).then((response: AxiosResponse<any>) => {
              return response
          });
        }

    public getPackages = (code: string, customer: string | null, provider: string | null) : Promise<any> => {
        return this.http
            .get(`/delivery-requests/${code}/packages`, {params: {providerUsername: customer, provider}}).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }

    public getAvailableOrders = (filter: any) : Promise<any> => {
        let limit = filter && filter.size? filter.size:DEFAULT_PAGE_SIZE;
        let offset=filter && filter.page? filter.page*limit:0;
        return this.http
            .get(`delivery-requests/available-orders`, {params: {...filter, offset, limit, sort:'createdAt:desc'}}).then((response: AxiosResponse<any>) => {
                return response.data
            });
    }
    public createDeliveryRequest = (body: any) : Promise<any> => {
        return this.http
            .post(`delivery-requests`,body).then((response: AxiosResponse<any>) => {
                return response.data
            });
    }
}
export const deliveryRepository: DeliveryRepository = new DeliveryRepository();
