import React, {useContext, useEffect} from 'react';
import {ConfigProvider} from 'antd';

import Dashboard from "./containers/Dashboard";
import ConnectSupplier from "./containers/ConnectSupplier";
import Transaction from "./containers/Transaction";
import ShoppingCart from "./containers/ShoppingCart";
import Orders from "./containers/Orders";
import OrderDetail from "./containers/OrderDetail";
import Packages from "./containers/Packages"

import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import {LANGUAGE_STORAGE_KEY, TENANT_KEY} from "./core/config";
import numeral from "numeral";
import Success from './containers/Delivery/Create/Success';
import Claims from "./containers/Claim";
import ClaimCreate from "./containers/ClaimCreate";
import {ClaimDetail} from './containers/ClaimDetail';
import moment from 'moment'
import 'moment/locale/vi'
import InventoryVoucher from './containers/Delivery/InventoryVoucher';
import RequestDelivery from './containers/Delivery/Request';
import CreateRequestDelivery from './containers/Delivery/Create';
import {Login} from "./containers/Login";
import {Authentication} from "./containers/Authentication";
import {AuthenticateRoute} from "./components/AuthenticateRoute";
import {tenantRepository} from "./repositories/TenantRepository";
import {localStorageSave} from "./utils/LocalStorageUtils";
import {AppContext} from "./context/AppContext";
import PageNotFound from "./components/ErrorPage/404";
import Profile from "./containers/Profile";

switch (localStorage.getItem(LANGUAGE_STORAGE_KEY)) {
    case 'vi':
    default:
        moment.locale('vi', {
            relativeTime: {
                s: '%d giây',
                m: '%d phút',
                h: '%d giờ',
                d: '%d ngày',
                w: '%d tuần',
                M: '%d tháng',
                y: '%d năm'
            }
        })

        numeral.register('locale', 'vi', {
            delimiters: {
                thousands: '.',
                decimal: ','
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal : function (number) {
                return '';
            },
            currency: {
                symbol: '₫'
            }
        });
        numeral.locale('vi');
        break;
}



function App() {
    const appContext = useContext(AppContext)

    useEffect(() => {
        tenantRepository.getCurrent()
            .then(response => {
                localStorageSave(TENANT_KEY, response)
                appContext.tenant = response
            })
    }, [])

    return <div id={"app-main"} className={""}>
        <ConfigProvider>
            <BrowserRouter>
                <Routes>
                    <Route path={'/authentication'} element={<Authentication />} />
                </Routes>
            </BrowserRouter>
            <HashRouter>
                <Routes>
                    <Route path='*' element={<PageNotFound />} />
                    <Route path={'/login'} element={<Login />}/>
                    <Route path={'/'} element={<AuthenticateRoute><Dashboard/></AuthenticateRoute>}/>
                    <Route path={'/connect-supplier'} element={<AuthenticateRoute><ConnectSupplier/></AuthenticateRoute>}/>
                    <Route path={'/transactions'} element={<AuthenticateRoute><Transaction/></AuthenticateRoute>}/>
                    <Route path={'/shopping-cart'} element={<AuthenticateRoute><ShoppingCart/></AuthenticateRoute>}/>
                    <Route path={'/orders/:code'} element={<AuthenticateRoute><OrderDetail/></AuthenticateRoute>}/>
                    <Route path={'/orders'} element={<AuthenticateRoute><Orders/></AuthenticateRoute>}/>
                    <Route path={'/packages'} element={<AuthenticateRoute><Packages /></AuthenticateRoute>}/>
                    <Route path={'/delivery'} element={<AuthenticateRoute><RequestDelivery /></AuthenticateRoute>}/>
                    <Route path={'/delivery/create'} element={<AuthenticateRoute><CreateRequestDelivery /></AuthenticateRoute>}/>
                    <Route path={'/delivery/create-success'} element={<AuthenticateRoute><Success /></AuthenticateRoute>}/>
                    <Route path={'/claims'} element={<AuthenticateRoute><Claims/></AuthenticateRoute>}/>
                    <Route path={'/claims/create'} element={<AuthenticateRoute><ClaimCreate /></AuthenticateRoute>}/>
                    <Route path={'/claims/:providerCode/:customerCode/:orderCode/:claimCode'} element={<AuthenticateRoute><ClaimDetail /></AuthenticateRoute>}/>
                    <Route path='/delivery-notes' element={<AuthenticateRoute><InventoryVoucher /></AuthenticateRoute>}/>
                    <Route path='/profile' element={<AuthenticateRoute><Profile /></AuthenticateRoute>}/>
                </Routes>
            </HashRouter>
        </ConfigProvider>
    </div>
}

export default App;
