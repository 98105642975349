import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import lodash from "lodash";
import {Col, Row, Table} from "antd";
import {formatDateTime} from "../../../core/helpers/date-time";
import {formatMoneyBySuffix, formatMoneyByUnit} from "../../../core/helpers/string";
interface TransactionProps{
    dataSource: any;
}
function Transactions(props: TransactionProps){
    const {t} = useTranslation();
    const {dataSource} = props;
    const formatAmount = (amount: any, currency: string) => {
        let color = amount < 0 ? 'txt-color-red2' : 'txt-color-green';
        return <span
            className={color}>{`${amount > 0 ? '+' : ''}${formatMoneyByUnit(amount, currency)}`}</span>
    };
    const columns = [
        {
            title: <span className={'txt-color-black robotomedium'}>{t('orderDetail.transactionTime')}</span>,
            dataIndex: 'timestamp',
            key: 'timestamp',
            className:'txt-color-black txt-size-h7',
            render:(text: string,record:any)=> <span className={''}>{formatDateTime(record.timestamp)}</span>
        },

        {
            title: t('orderDetail.transactionAmount'),
            dataIndex: 'amount',
            key: 'amount',
            className: 'txt-size-h7',
            render:(text: string, record:any)=> formatAmount(record.amount, record.currency)

        },
        {
            title: t('orderDetail.transactionType'),
            dataIndex: 'type',
            key: 'type',
            className:'txt-color-black txt-size-h7',
            render:(text: string, record:any)=> <span>{lodash.get(record,'type.name','--')}</span>

        },
        {
            title: t('orderDetail.transactionMemo'),
            dataIndex: 'memo',
            key: 'memo',
            className:'txt-color-black txt-size-h7',
            render:(text: string,record:any)=> <Col>
                <Row><span className={'txt-color-gray2'}>{t('orderDetail.transactionId',{value: record.txid})}</span></Row>
                    <span>{lodash.get(record,'memo','--')}</span>
            </Col>
        },
    ];
    return <div>
        <Table  className='order-transaction-list' dataSource={dataSource} columns={columns} pagination={false} />
    </div>
}
export default Transactions;
