import {Modal, Button, Row, Input, Tooltip} from 'antd';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import './styles.scss';
interface InlineEditProps{
    defaultValue?: any;
    type?: 'input'|'textarea'|'textarea-modal';
    className?:string;
    title:string;
    loading?:boolean;
    isInTab?:boolean;
    editable?:boolean
    onSubmit: (value: string, callback:()=>void)=>void
}
const InlineEdit=(props: InlineEditProps)=>{
    const {defaultValue, type, title, onSubmit, isInTab, editable} = props;
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState<any>(defaultValue);

    const {t} = useTranslation();
    const handleCancel=()=>{
        setShowModal(false);
        setValue(defaultValue);
    }
    const handleOk=()=>{
        setLoading(true);
        onSubmit(value, ()=>{
            setValue('');
            setShowModal(false);
            setLoading(false);
        })
    }
    const clickEdit=()=>{
        if(type==='textarea-modal'){
            setShowModal(true);
            if(defaultValue)
                setValue(defaultValue)
        }
    }

   
 
    return <div>
            <Row className={'dpl-flex align-items-center'}>
                {defaultValue&&defaultValue.length>30?
                        <Row className={'dpl-flex align-items-center'} align={'middle'}>
                            <span className={`personal-note txt-size-h7 txt-color-black`}>{defaultValue?defaultValue:'---'}</span>
                            <Tooltip title={defaultValue}>
                                <span><i className="txt-size-h7 far fa-info-circle txt-color-orange mgl5"></i></span>
                            </Tooltip>
                        </Row>
                        :
                        <span className={`personal-note txt-size-h7 txt-color-black`}>{defaultValue?defaultValue:'---'}</span>}
                        <span onClick={clickEdit} className={'mgl5 _note-icon-custom'}>
                            <i className="far fa-edit txt-color-orange txt-size-h8"></i>
                        </span>
            </Row> 
        {showModal&&type==='textarea-modal'?<Modal
            visible={showModal}
            title={title}
            onCancel={handleCancel}
            footer={[
                <Button icon={<CloseOutlined />} key="back" onClick={handleCancel}>
                    {t('button.cancel')}
                </Button>,
                <Button   icon={<CheckOutlined />} key="submit" type="primary" loading={loading} onClick={handleOk}>
                    {t('button.submit')}
                </Button>,
            ]}>
            <Input.TextArea value={value} onChange={(e)=> setValue(e.target.value)} rows={5} autoFocus={true}>

            </Input.TextArea>

        </Modal>:null}
    </div>
}
InlineEdit.defaultProps={
    type:'input'
}
export default InlineEdit;