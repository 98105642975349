import React, {useEffect, useState} from 'react';
import {Avatar, Image, Layout, Menu, Popover, Row, Tooltip} from 'antd';
import './styles.scss';
import {DashOutlined, DownOutlined, LogoutOutlined,} from '@ant-design/icons';
import lodash from 'lodash';
import DocumentTitle from 'react-document-title';
import {useTranslation} from 'react-i18next';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {PROFILE_KEY, TENANT_KEY, TOKEN_KEY} from '../../core/config';
import useWindowDimensions from '../../hooks';
import {localStorageRead, localStorageSave,} from '../../utils/LocalStorageUtils';
import ConfirmModal from '../Modal/ConfirmModal';
import Logo from '../../resources/images/UboxLogo.png';
import ProgressBar from '../Spinner/ProgressBar';
import {onMessage} from 'firebase/messaging';
import TabNotification from './Notices';
import {firebaseMessaging} from "../../utils/Firebase";

const { ItemGroup, Item } = Menu;

const {Header, Sider, Content} = Layout;
const PERCENT_COMPLETE = 100;

export interface MainLayoutProps {
    children: any;
    showProgressBar?: boolean;
    title?: any;
}

function MainLayout(props: MainLayoutProps) {
	const { children, showProgressBar, title } = props;
	const [collapsed, setCollapsed] = useState(true);
	const [percent, setPercent] = useState<number>(-1);
	const [autoIncrement, setAutoIncrement] = useState<boolean>(false);
	const [isShowMenuDropDown, setShowMenuDropDown] = useState<boolean>(false);
	const [intervalTime, setIntervalTime] = useState<number>(200);
	const [activeKey, setActiveKey] = useState<string>('');
	const profile = localStorageRead(PROFILE_KEY);
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { height } = useWindowDimensions();
	const [currentConfigShowBalance, setCurrentConfigShowBalance] = useState();
	const [menu, setMenu] = useState<any>([]);
	const MenuConfigs = [
		{
			title: t('menu.overview'),
			key: 'overview',
			items: [
				{
					key: '',
					icon: <i className='fas fa-house' />,
					toolTip: t('menu.dashboard'),
					children: t('menu.dashboard'),
				},
                {
                    key: 'profile',
                    icon: <i className="fa-solid fa-address-card"/>,
                    toolTip: t('menu.profile'),
                    children: t('menu.profile'),
                },
			],
		},
		{
			title: t('menu.products'),
			key: 'products',
			items: [
				{
					key: 'connect-supplier',
					icon: <i className='fas fa-house-signal' />,
					toolTip: t('menu.connectSupplier'),
					children: t('menu.connectSupplier'),
				},
				{
					key: 'shopping-cart',
					icon: <i className='fas fa-shopping-cart' />,
					children: t('menu.shopping-cart'),
					toolTip: t('menu.shopping-cart'),
				},
			],
		},
		{
			title: t('menu.finance'),
			key: 'transactions',
			items: [
				{
					key: 'transactions',
					icon: <i className='far fa-exchange' />,
					toolTip: t('menu.transactions'),
					children: t('menu.transactions'),
				},
			],
		},
		{
			title: t('menu.ordersGroup'),
			key: 'orders',
			items: [
				{
					key: 'orders',
					icon: <i className='fas fa-paste' />,
					toolTip: t('menu.orders'),
					children: t('menu.orders'),
				},
			],
		},
		{
			title: t('menu.packagesGroup'),
			key: 'packages',
			items: [
				{
					key: 'packages',
					icon: <i className='fas fa-truck-loading' />,
					toolTip: t('menu.packages'),
					children: t('menu.packages'),
				},
			],
		},
        {
            title: t('menu.ticketsGroup'),
            key: 'claims',
            items: [
                {
                    key: 'claims',
                    icon: <i className="fa-solid fa-message-exclamation"/>,
                    toolTip: t('menu.tickets'),
                    children: t('menu.tickets'),
                },
            ],
        },
		{
			title: t('menu.deliveryGroup'),
			key: 'delivery',
			items: [
				{
					key: 'delivery',
					icon: <i className='fas fa-hand-holding-box' />,
					children: t('menu.requestDelivery'),
					toolTip: t('menu.requestDelivery'),
				},
			],
		},
		{
			title: t('menu.deliveryGroup'),
			key: 'delivery-notes',
			items: [
				{
					key: 'delivery-notes',
					icon: <i className='fas fa-list-alt' />,
					children: t('menu.delivery-notes'),
					toolTip: t('menu.delivery-notes'),
				},
			],
		},
	];
	useEffect(() => {
		if (showProgressBar) {
			startWithAutoIncrement();
		} else {
			setPercentValue(PERCENT_COMPLETE);
		}
	}, [showProgressBar]);

	useEffect(() => {
		const showBalance = lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', '')
		setCurrentConfigShowBalance(showBalance);
		const menu = !showBalance ? MenuConfigs.filter(({key}) => key !== 'transactions') : MenuConfigs
		setMenu(menu)
	}, []);

	useEffect(() => {
		if (location.pathname && location.pathname !== '/') {
			let pathname: string[] = location.pathname.split('/');
			setActiveKey(pathname[pathname.length - 1]);
		}
		// this.setState({activeKey: activeKey})
	}, []);

	useEffect(() => {
		onMessage(firebaseMessaging, (payload) => {
			console.log('Message received. ', payload);
		});
	}, [])

	const toggle = () => {
		setCollapsed(!collapsed);
	};
	const [confirmLogOut, setConfirmLogOut] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);

    const onConfirmLogOut = () =>{
        // console.log('logout');
        setLoading(true)
        localStorageSave(PROFILE_KEY,null);
        localStorageSave(TOKEN_KEY,null);
        localStorageSave(TENANT_KEY,null);
        window.location.href = process.env.REACT_APP_LOGOUT_URL!;
        setLoading(false)
        // console.log(process.env.REACT_APP_LOGOUT_URL)
    }

    const onCancelLogOut = () => {
        setConfirmLogOut(false);
        setLoading(false)
    }


    /**
     * hiển thị progressbar
     **/
    const startWithAutoIncrement = () => {
        setPercent(0);
        setAutoIncrement(true);
        setIntervalTime(Math.random() * 150);
    };

	/**
	 * Dừng progressbar
	 **/
	const stopWithAutoIncrement = () => {
		setPercent(-1);
		setAutoIncrement(false);
		setIntervalTime(150);
	};

	const setPercentValue = (percent: number) => {
		setPercent(percent);
		if (percent === PERCENT_COMPLETE) {
			setTimeout(() => {
				stopWithAutoIncrement();
			}, 500);
		}
	};
	const handleClickMenu = (e: any) => {
		navigate(`/${e.key}`);
		if (activeKey === e.key) {
			window.location.reload();
		}
	};

	const handleVisibleChange = (visible: boolean) => {
		setShowMenuDropDown(visible);
	};
	const renderDropDownAvatar = () => {
		return (
			<div style={{ width: 200 }}>
				<Row
					className={'dpl-flex align-items-center pdt5 pdbt10'}
					key={'logout'}>
					<span
						onClick={() => setConfirmLogOut(true)}
						className='_logout-btn txt-color-black cursor-pointer'>
						<i className='far fa-sign-out-alt mgr5'></i>
						<span>{t('menu.logout')}</span>
					</span>
				</Row>
			</div>
		);
	};



	return (
		<DocumentTitle title={`${title ? title : 'M24'}`}>
			<Layout className={'main-layout'}>
				<Sider
					className={'sidebar-left'}
					theme='light'
					trigger={<LogoutOutlined style={{ color: '#F59F32' }} />}
					collapsedWidth={56}
					onCollapse={() => setConfirmLogOut(true)}
					collapsible
					collapsed={collapsed}>
					{!collapsed ? (
						<Row
							style={{ alignItems: 'center', justifyContent: 'space-between' }}
							className='pd-16'>
							<Image preview={false} src={Logo} alt='ubox-logo' />
							<i
								className='fas fa-angle-double-left cursor-pointer'
								style={{ color: '#CED1F3' }}
								onClick={toggle}
							/>
						</Row>
					) : (
						<div className={'logo-collapse'}>
							<i
								className='fas fa-angle-double-right cursor-pointer'
								style={{ color: '#CED1F3' }}
								onClick={toggle}
							/>
						</div>
					)}
					<Menu
						selectedKeys={[activeKey]}
						mode='inline'
						defaultSelectedKeys={['1']}>
						{menu.map(({ title, key, items }: any) => {

							return (
								<ItemGroup
									key={key}
									title={
										<div
											className={`${
												collapsed ? 'menu-group-collapse' : 'menu-group'
											}`}>
											{collapsed ? (
												<DashOutlined />
											) : (
												<span className='txt-color-gray2 robotoregular'>
													{title}
												</span>
											)}
										</div>
									}>
									{items.map(({ key, icon, children, toolTip }: any) => (
										<Item key={key} icon={icon} title={null}>
											{collapsed ? (
												<Tooltip
													placement='right'
													title={toolTip}
													overlayClassName={'tooltip-custom'}
													color={'#FAA200'}>
													<Link
														to={`/${key}`}
														className='wrapper-icon robotoregular txt-color-black'>
														{children}
													</Link>
												</Tooltip>
											) : (
												<Link
													to={`/${key}`}
													className='robotoregular txt-color-black'>
													{children}
												</Link>
											)}
										</Item>
									))}
								</ItemGroup>
							);
						})}
					</Menu>
				</Sider>
				<Layout>
					<Header className='header'>
						<div
							className={'dpl-flex justify-content-between align-items-center'}>
							<span className={'txt-size-h5 robotomedium'}>
								{title ? title : ''}
							</span>
							<Popover
								visible={isShowMenuDropDown}
								onVisibleChange={handleVisibleChange}
								placement='bottomRight'
								content={renderDropDownAvatar()}
								trigger='click'>
								<div
									style={{ height: 64 }}
									className={'dpl-flex justify-content-end align-items-center'}>
									<p
										className={
											'_user-name mgt15 txt-size-h7 robotoregular txt-color-black mgr10'
										}>
										{lodash.get(profile, 'name', null)}
										<DownOutlined
											className={'txt-size-h9 txt-color-gray mgl5'}
										/>
									</p>
									<Avatar
										src={lodash.get(profile, 'avatar', null)}
										className={'_avatar-1 mgl10'}
									/>
								</div>
							</Popover>
						</div>
					</Header>
					<ProgressBar
						percent={percent}
						autoIncrement={autoIncrement}
						intervalTime={intervalTime}
						spinner={false}
					/>
					<Content style={{ minHeight: height - 64 }} className='content'>
						{children}
					</Content>
					{confirmLogOut && (
						<ConfirmModal
							title={t('dashboard.confirmLogoutTitle')}
							content={t('dashboard.confirmLogoutContent')}
							visible={confirmLogOut}
							loading={isLoading}
							onCancel={onCancelLogOut}
							onSubmit={onConfirmLogOut}
						/>
					)}
				</Layout>
			</Layout>
		</DocumentTitle>
	);
}

export default MainLayout;

